<!-- eslint-disable -->
<template>
  <div>
    <b-media vertical-align="top">
      <template #aside>
        <b-avatar
          rounded
          variant="light-secondary"
          size="50"
          style="background-color: white; box-shadow: 1px 1px 1px #aaaaaa"
        >
          <img
            src="@/assets/images/icons/assessment-care/preferredact-icon-default.svg"
            width="28px"
            height="28px"
          >
        </b-avatar>
      </template>
      <h4 class="media-heading">
        {{ pageTitle }}
      </h4>
      <b-card-text class="mb-0">
        Client's preferred leisure activities or hobbies
      </b-card-text>
    </b-media>
    <!-- form -->
    <b-card class="mt-2">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Favorite activities currently able to do"
                label-for=""
              >
                <validation-provider
                  #default="{ errors }"
                  name="currently able to do"
                  rules="required"
                >
                  <b-form-textarea
                    v-model="preferredAct.fav_act_can_do"
                    :state="errors.length > 0 ? false:null"
                    :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                    rows="3s"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Favorite activities currently not able to do"
                label-for=""
              >
                <validation-provider
                  #default="{ errors }"
                  name="currently not able to do"
                  rules="required"
                >
                  <b-form-textarea
                    v-model="preferredAct.fav_act_cant_do"
                    :state="errors.length > 0 ? false:null"
                    :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                    rows="3"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Interest/ Hobbies"
                label-for=""
              >
                <validation-provider
                  #default="{ errors }"
                  name="Interest/Hobbies"
                  rules="required"
                >
                  <b-form-textarea
                    v-model="preferredAct.interest_hob"
                    :state="errors.length > 0 ? false:null"
                    :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                    rows="3"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Entertainment preferences (symphony, theater, movies)"
                label-for=""
              >
                <validation-provider
                  #default="{ errors }"
                  name="Entertainment preferences"
                  rules="required"
                >
                  <b-form-textarea
                    v-model="preferredAct.entertainment_pref"
                    :state="errors.length > 0 ? false:null"
                    :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                    rows="3"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-row class="m-0 p-1 mb-1 bg-light">
                <b-col
                  md="12"
                  class="p-0"
                >
                  <b-form-group
                    label="Clubs and Organization"
                    label-for=""
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Clubs and Organization"
                    >
                      <b-form-checkbox-group
                        v-model="preferredAct.club_org"
                        :state="errors.length > 0 ? false : null"
                        :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                        name="church options"
                        class=""
                      >
                        <b-form-checkbox
                          v-for="option in famOptions"
                          :key="option.id"
                          :value="option.value"
                          @change="isChecked1(preferredAct.club_org)"
                        >
                          {{ option.label }}
                        </b-form-checkbox>
                      </b-form-checkbox-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  md="12"
                  class="p-0"
                >
                  <b-form-group
                    v-if="preferredAct.club_org.length > 0 ? true : false"
                    label="Specify meeting day and time"
                    label-for=""
                    class="mt-2"
                  >
                    <b-row>
                      <b-col lg="7">
                        <b-form-tags
                          v-model="preferredAct.club_org_day_set"
                          separator=" "
                          placeholder=""
                          add-on-change
                          remove-on-delete
                          tag-remove-label="Remove tag"
                          :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                          style="padding-top: 8px; padding-bottom: 8px"
                        />
                      </b-col>
                      <b-col lg="5">
                        <b-form-timepicker
                          v-model="preferredAct.club_org_time_set"
                          now-button
                          reset-button
                          locale="en"
                          placeholder=""
                          class=""
                          :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                        />
                      </b-col>
                    </b-row>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="6">
              <b-row class="m-0 p-1 mb-1 bg-light">
                <b-col
                  md="12"
                  class="p-0"
                >
                  <b-form-group
                    label="Church"
                    label-for=""
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Church"
                    >
                      <b-form-checkbox-group
                        v-model="preferredAct.church"
                        :state="errors.length > 0 ? false : null"
                        name="church options"
                        class=""
                        :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                      >
                        <b-form-checkbox
                          v-for="option in churchOptions"
                          :key="option.id"
                          :value="option.value"
                          @change="isChecked2(preferredAct.church)"
                        >
                          {{ option.label }}
                        </b-form-checkbox>
                      </b-form-checkbox-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  md="12"
                  class="p-0"
                >
                  <b-form-group
                    v-if="preferredAct.church.length > 0 ? true : false"
                    label="Specify meeting day and time"
                    label-for=""
                    class="mt-2"
                  >
                    <b-row>
                      <b-col
                        lg="7"
                        md="12"
                      >
                        <b-form-tags
                          v-model="preferredAct.church_day_set"
                          separator=" "
                          placeholder=""
                          add-on-change
                          remove-on-delete
                          tag-remove-label="Remove tag"
                          :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                          style="padding-top: 8px; padding-bottom: 8px"
                        />
                      </b-col>
                      <b-col
                        lg="5"
                        md="12"
                      >
                        <b-form-timepicker
                          v-model="preferredAct.church_time_set"
                          now-button
                          reset-button
                          locale="en"
                          placeholder=""
                          class=""
                          :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                        />
                      </b-col>
                    </b-row>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Regular appointment/social engagements (specify)"
                label-for=""
              >
                <validation-provider
                  #default="{ errors }"
                  name="Regular appointment/social engagements"
                  rules="required"
                >
                  <b-form-textarea
                    v-model="preferredAct.reg_app_social_eng"
                    :state="errors.length > 0 ? false:null"
                    :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                    rows="3"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Other social activities"
                label-for=""
              >
                <validation-provider
                  #default="{ errors }"
                  name="Other social activities"
                  rules="required"
                >
                  <b-form-textarea
                    v-model="preferredAct.other_social_act"
                    :state="errors.length > 0 ? false:null"
                    :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                    rows="3"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Family in the area?:"
                label-for=""
                label-cols-md="6"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Family in the area"
                  rules="required"
                >
                  <b-form-radio-group
                    v-model="preferredAct.fam_in_area_Yn"
                    name="prefActRadio1"
                    class="customMargin"
                    :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                  >
                    <b-form-radio
                      v-for="option in radioOptions"
                      :key="option.text"
                      :value="option.text"
                      class="mr-3"
                      @change="getClickID1(option.id)"
                    >
                      {{ option.text }}
                    </b-form-radio>
                  </b-form-radio-group>
                  <small
                    v-if="localUserRole !== 'client' && localUserRole !== 'agent' "
                    class="text-danger"
                  >{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                v-if="preferredAct.fam_in_area_Yn === radioOptions[0].text ? true : false"
                label="Visit how often?"
                label-for=""
              >
                <validation-provider
                  #default="{ errors }"
                  name="Visit how often"
                  rules="required"
                >
                  <b-form-input
                    v-model="preferredAct.fam_in_area_visit_how_often"
                    name="Visit how often"
                    :state="errors.length > 0 ? false : null"
                    :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Has regular friends and visitors?"
                label-for=""
                label-cols-md="8"
              >
                <validation-provider
                  #default="{ errors }"
                  name="has regular friends and visitors"
                  rules="required"
                >
                  <b-form-radio-group
                    v-model="preferredAct.reg_friends_and_visitors_Yn"
                    name="prefActRadio2"
                    class="customMargin"
                    :state="errors.length > 0 ? false : null"
                    :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                  >
                    <b-form-radio
                      v-for="option in radioOptions"
                      :key="option.text"
                      :value="option.text"
                      class="mr-1"
                      @change="getClickID2(option.id)"
                    >
                      {{ option.text }}
                    </b-form-radio>
                  </b-form-radio-group>
                  <small
                    v-if="localUserRole !== 'client' && localUserRole !== 'agent' "
                    class="text-danger"
                  >{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                v-if="preferredAct.reg_friends_and_visitors_Yn === radioOptions[0].text ? true : false"
                label="Visit how often?"
                label-for=""
              >
                <validation-provider
                  #default="{ errors }"
                  name="Visit how often"
                  rules="required"
                >
                  <b-form-input
                    v-model="preferredAct.reg_friends_and_visitor_visit_how_often"
                    name="Visit how often"
                    :state="errors.length > 0 ? false : null"
                    :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <hr class="my-3">
          <b-row>
            <b-col md="12">
              <b-media vertical-align="top">
                <template #aside>
                  <img
                    src="@/assets/images/icons/assessment-care/pets_form_icon.svg"
                    style="margin-top: -3px"
                  >
                </template>
                <h4
                  class="media-heading"
                  style="margin-left: -5px"
                >
                  Pets
                </h4>
              </b-media>
            </b-col>
            <b-col
              md="12"
              class="p-0"
            >
              <!-- Items Section -->
              <b-card-body class="invoice-padding form-item-section">
                <div
                  ref="form"
                  class="repeater-form h-auto"
                  :style="{height: trHeight}"
                >
                  <b-row
                    v-for="(item, index) in preferredAct.pets"
                    :key="index"
                    ref="row"
                    class="pb-2"
                  >

                    <!-- Item Form -->
                    <!-- ? This will be in loop => So consider below markup for single item -->
                    <b-col cols="12">
                      <!-- Form Input Fields OR content inside bordered area  -->
                      <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                      <div class="d-flex border rounded">
                        <b-row class="flex-grow-1 p-2">
                          <!-- Single Item Form Headers -->
                          <b-col
                            cols="12"
                            lg="8"
                          >
                            <b-row>
                              <b-col
                                cols="12"
                                lg="6"
                              >
                                <label for="">Type</label>
                                <v-select
                                  v-model="item.type"
                                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                  :options="itemsOptions"
                                  :clearable="false"
                                  :value="itemsOptions.value"
                                  class="mb-2 item-selector-title"
                                  placeholder="Select Item"
                                  taggable
                                  push-tags
                                  :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                                />
                              </b-col>
                              <b-col
                                cols="12"
                                lg="6"
                              >
                                <label for="">Name</label>
                                <b-form-input
                                  v-model="item.name"
                                  type="text"
                                  class="mb-2"
                                  :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                                />
                              </b-col>
                              <b-col
                                cols="12"
                                lg="6"
                              >
                                <label for="">Veterinarian's Name</label>
                                <b-form-input
                                  v-model="item.veterinarian_name"
                                  type="text"
                                  :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                                  class="mb-2"
                                />
                              </b-col>
                              <b-col
                                cols="12"
                                lg="6"
                              >
                              <b-form-group
                                label="Veterinarian's Contact No."
                                label-for="Vet-Contact-No-field"
                              >
                                <b-form-input
                                  v-model="item.veterinarian_number"
                                  v-mask="['(###) ###-####']"
                                  :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                                  class="mb-2"
                                />
                              </b-form-group>
                              </b-col>
                            </b-row>
                          </b-col>
                          <b-col
                            cols="12"
                            lg="4"
                          >
                            <label for="">Required for pets</label>
                            <b-form-textarea
                              v-model="item.req_for_pets"
                              :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                              rows="3"
                              class="mb-2 mb-lg-0"
                            />
                          </b-col>
                        </b-row>
                        <div
                          v-if="localUserRole !== 'client' && localUserRole !== 'agent' ? true : false"
                          class="d-flex flex-column justify-content-between border-left py-50 px-25"
                        >
                          <feather-icon
                            size="16"
                            icon="XIcon"
                            class="cursor-pointer"
                            @click="removeItem(index)"
                          />
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </div>
                <!-- <div>{{ preferredAct.pets }}</div> -->
                <b-button
                  v-if="localUserRole !== 'client' && localUserRole !== 'agent' ? true : false"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="secondary"
                  @click="addNewItemInItemForm"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-25"
                  />
                  <span>Add New</span>
                </b-button>
              </b-card-body>
            </b-col>
          </b-row>
          <!-- Action Buttons -->
          <b-row class="mt-2">
            <b-col
              v-if="localUserRole !== 'client' && localUserRole !== 'agent' "
              md="10"
            >
              <b-button
                variant="secondary"
                class="col-sm-3 mr-1"
                type="submit"
                @click.prevent="validationForm"
              >
                Save Changes
              </b-button>
              <b-button
                variant="outline-dark"
                class="col-sm-2 btn-reset"
                @click="onReset()"
              >
                Reset
              </b-button>
            </b-col>
            <b-col
              v-if="localUserRole === 'client' || localUserRole === 'agent'"
              class="text-right"
            >
              <b-button
                class=""
                variant="secondary"
                @click="onClickNextTab()"
              >
                <span class="align-middle">Next</span>
                <feather-icon
                  icon="ChevronRightIcon"
                  class="ml-50"
                />
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton,
  BFormTextarea, BFormCheckbox, BFormTimepicker, BFormRadioGroup, BFormRadio, BFormCheckboxGroup, BFormTags,
  BMedia, BCard, BCardBody, BCardText, BAvatar, VBToggle,
} from 'bootstrap-vue'
import {
  computed,
  // watch,
  ref,
} from '@vue/composition-api'
import store from '@/store'
import Swal from 'sweetalert2'
import router from '@/router'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { mask } from 'vue-the-mask'
import vSelect from 'vue-select'
import { required } from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormTimepicker,
    BFormRadioGroup,
    BFormRadio,
    BFormTags,
    BForm,
    BRow,
    BCol,
    BButton,
    BMedia,
    BCard,
    BCardBody,
    BCardText,
    BAvatar,
    vSelect,
  },
  directives: {
    mask,
    Ripple,
    'b-toggle': VBToggle,
  },
  mixins: [heightTransition],
  props: {
    userRole: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    addNewItemInItemForm() {
      this.form.style.overflow = 'hidden'
      this.preferredAct.pets.push(JSON.parse(JSON.stringify(this.petFormBlankItem)))

      this.$nextTick(() => {
        this.trAddHeight(this.row[0].offsetHeight)
        setTimeout(() => {
          this.form.style.overflow = null
        }, 350)
      })
    },
    removeItem(index) {
      this.preferredAct.pets.splice(index, 1)
      this.trTrimHeight(this.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.form.scrollHeight)
      })
    },
    isChecked1(val) {
      if (val.length > 0) {
        this.preferredAct.club_org_time_set = ''
        this.preferredAct.club_org_day_set = []
      }
    },
    isChecked2(val) {
      if (val.length < 1) {
        this.preferredAct.church_time_set = ''
        this.preferredAct.church_day_set = []
      }
    },
    onReset() {
      const bckUpData = this.preferredActBckUp
      if (bckUpData.length !== 0) {
        store.dispatch('client_assessmentcare/filterTab6', bckUpData)
        // console.log('bckUpData', bckUpData)
      } else {
        store.state.client_assessmentcare.preferredAct = {
          club_org: [],
          club_org_time_set: '',
          church: [],
          church_time_set: '',
          pets: [{
            type: '',
            name: '',
            req_for_pets: '',
            veterinarian_name: '',
            veterinarian_number: '',
          }],
        }
      }
    },
  },
  setup(props, { emit }) {
    const pageTitle = 'Preferred Activities'
    const localUserRole = ref(props.userRole)
    const clientId = router.currentRoute.query.id ? router.currentRoute.query.id : ''
    const preferredAct = ref(computed(() => store.state.client_assessmentcare.preferredAct))
    const preferredActBckUp = ref(computed(() => store.state.client_assessmentcare.preferredActBckUp))
    const showInput1 = ref(0)
    const showInput2 = ref(0)
    const inputTime = ref(false)
    const inputTime2 = ref(false)
    const inputTime3 = ref(false)
    const simpleRules = ref(null)
    const form = ref(null)
    const row = ref(null)
    const radioOptions = [
      { id: 1, text: 'Yes', value: 'Yes' },
      {
        id: 2, text: 'No', value: 'No', selected: true,
      },
    ]
    const petFormBlankItem = {
      type: '',
      name: '',
      req_for_pets: '',
      veterinarian_name: '',
      veterinarian_number: '',
    }
    const itemsOptions = [
      'Dog',
      'Cat',
      'Fish',
      'Bird',
      'Rabbit',
    ]
    const churchOptions = [
      { label: 'Member', value: 'Member' },
      { label: 'Church leader (pastor, priest, rabbit, etc)?', value: 'Church leader (pastor, priest, rabbit, etc)?' },
    ]
    const famOptions = [
      { label: 'Member', value: 'Member' },
    ]

    const onClickNextTab = () => {
      emit('next-tab')
    }
    const getClickID1 = radioOptID1 => {
      if (radioOptID1 === 2) {
        showInput1.value = 0
        preferredAct.value.fam_in_area_visit_how_often = ''
      } else {
        showInput1.value = 1
      }
    }
    const getClickID2 = radioOptID2 => {
      if (radioOptID2 === 2) {
        showInput2.value = 0
        preferredAct.value.reg_friends_and_visitor_visit_how_often = ''
      } else {
        showInput2.value = 1
      }
    }

    // *===============================================---*
    // *--------- SAVE DATA ------------------------------*
    // *===============================================---*
    const onSaveData = () => {
      // const uData = localStorage.getItem('userData')
      // const uID = JSON.parse(uData)
      const formData = new FormData()
      const data = JSON.stringify({
        // client_id: uID.clientId,
        client_id: clientId,
        tab_name: pageTitle,
        fav_act_can_do: preferredAct.value.fav_act_can_do,
        fav_act_cant_do: preferredAct.value.fav_act_cant_do,
        interest_hob: preferredAct.value.interest_hob,
        entertainment_pref: preferredAct.value.entertainment_pref,
        club_org: preferredAct.value.club_org,
        club_org_day_set: preferredAct.value.club_org_day_set,
        club_org_time_set: preferredAct.value.club_org_time_set,
        church: preferredAct.value.church,
        church_day_set: preferredAct.value.church_day_set,
        church_time_set: preferredAct.value.church_time_set,
        reg_app_social_eng: preferredAct.value.reg_app_social_eng,
        other_social_act: preferredAct.value.other_social_act,
        fam_in_area_Yn: preferredAct.value.fam_in_area_Yn,
        fam_in_area_visit_how_often: preferredAct.value.fam_in_area_visit_how_often,
        reg_friends_and_visitors_Yn: preferredAct.value.reg_friends_and_visitors_Yn,
        reg_friends_and_visitor_visit_how_often: preferredAct.value.reg_friends_and_visitor_visit_how_often,
        // pets: [{
        //   type: preferredAct.value.pets.type,
        //   name: preferredAct.value.pets.name,
        //   req_for_pets: preferredAct.value.pets.req_for_pets,
        //   veterinarian_name: preferredAct.value.pets.veterinarian_name,
        //   veterinarian_number: preferredAct.value.pets.veterinarian_number,
        // }],
        pets: preferredAct.value.pets,
      })
      formData.append('data', data)
      store.dispatch('client_assessmentcare/insertData', formData)
        .then(() => {
          Swal.fire({
            icon: 'success',
            title: 'Updated Succesfully',
            showConfirmButton: false,
            timer: 1500,
            customClass: {
              confirmButton: 'btn btn-secondary',
            },
            buttonsStyling: false,
          })
          emit('fetch-my-data')
        })
        .catch(error => {
          console.error(error)
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
            showConfirmButton: false,
            timer: 1500,
          })
          // console.log('error', error)
        })
    }
    const validationForm = () => {
      simpleRules.value.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          // alert('form submitted!')
          onSaveData()
          // console.log('ref.simpleRules', simpleRules.value)
        } else {
          Swal.fire({
            icon: 'warning',
            title: 'Oops...',
            text: 'Make sure to fill up  the required fields',
            showConfirmButton: false,
            timer: 1500,
          })
        }
      })
    }

    // const onClear = () => {
    //   store.state.client_assessmentcare.preferredAct = {
    //     club_org: [],
    //     club_org_time_set: '',
    //     church: [],
    //     church_time_set: '',
    //     pets: [{
    //       type: '',
    //       name: '',
    //       req_for_pets: '',
    //       veterinarian_name: '',
    //       veterinarian_number: '',
    //     }],
    //   }
    // }

    return {
      pageTitle,
      preferredAct,
      preferredActBckUp,
      simpleRules,
      localUserRole,
      onClickNextTab,
      validationForm,
      famOptions,
      churchOptions,
      showInput1,
      showInput2,
      inputTime,
      inputTime2,
      inputTime3,
      radioOptions,
      required,
      // temp items
      petFormBlankItem,
      itemsOptions,
      // funcions
      getClickID1,
      getClickID2,
      // onClear,
      // test
      form,
      row,
      // addNewItemInItemForm,
    }
  },
}
</script>
<style scoped>
  .customMargin{
    margin-top: 6px !important;
  }
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>

<style lang="scss" scoped>

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

// .v-select {
//   &.item-selector-title,
//   &.payment-selector {
//     background-color: #fff;

//     .dark-layout & {
//       background-color: unset;
//     }
//   }
// }

</style>

<style>
.btn-reset:focus  {
  border: 1px solid #82868b !important;
  background-color:transparent !important;
  box-shadow: none !important;
}
.btn-reset:hover  {
  box-shadow: 0 8px 25px -8px #9c9fa0 !important;
}
</style>
