<template>
  <div>
    <b-media vertical-align="top">
      <template #aside>
        <b-avatar
          rounded
          variant="light-secondary"
          size="50"
          style="background-color: white; box-shadow: 1px 1px 1px #aaaaaa"
        >
          <img
            src="@/assets/images/icons/assessment-care/legal-icon-default.svg"
            width="28px"
            height="28px"
          >
        </b-avatar>
      </template>
      <h4 class="media-heading">
        {{ pageTitle }}
      </h4>
      <b-card-text class="mb-0">
        Notable client's legal documentation.
      </b-card-text>
    </b-media>
    <!-- form -->
    <b-card class="mt-2">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Legal Documentation"
                label-for="name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Legal Documentation"
                  rules="required"
                >
                  <b-form-checkbox-group
                    v-model="legalDoc.legal_doc"
                    name="legalDoc-options"
                    :state="errors.length > 0 ? false:null"
                    :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                  >
                    <b-form-checkbox
                      v-for="option in legalDocOptions"
                      :key="option.id"
                      :value="option.value"
                      class="mr-4"
                    >
                      {{ option.text }}
                    </b-form-checkbox>
                  </b-form-checkbox-group>
                  <small
                    v-if="localUserRole !== 'client' && localUserRole !== 'agent' "
                    class="text-danger"
                  >{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Do Not Resuscitate"
                label-for="do-not-resuscitate-field"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Do Not Resuscitate"
                  rules="required"
                >
                  <b-form-checkbox-group
                    v-model="legalDoc.do_not_resuscitate"
                    name="do-not-resuscitate-options"
                    :state="errors.length > 0 ? false:null"
                  >
                    <b-form-checkbox
                      v-for="option in doNotResuscitateOptions"
                      :key="option.id"
                      :value="option.value"
                      name="do-not-resuscitate-field"
                      :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                      class="mr-4"
                    >
                      {{ option.text }}
                    </b-form-checkbox>
                  </b-form-checkbox-group>
                  <small
                    v-if="localUserRole !== 'client' && localUserRole !== 'agent' "
                    class="text-danger"
                  >{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Instructions"
                label-for="instructions-field"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Instructions"
                  rules="required"
                >
                  <b-form-textarea
                    v-model="legalDoc.instructions"
                    :state="errors.length > 0 ? false:null"
                    name="instructions-field"
                    :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                    rows="3"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col
              v-if="localUserRole !== 'client' && localUserRole !== 'agent' "
              md="10"
            >
              <!-- Action Buttons -->
              <b-button
                variant="secondary"
                class="col-sm-3 mr-1"
                type="submit"
                @click.prevent="validationForm"
              >
                Save Changes
              </b-button>
              <b-button
                variant="outline-dark"
                class="col-sm-2 btn-reset"
                @click="onReset()"
              >
                Reset
              </b-button>
            </b-col>
            <b-col
              v-if="localUserRole === 'client' || localUserRole === 'agent'"
              class="text-right"
            >
              <b-button
                class=""
                variant="secondary"
                @click="onClickNextTab()"
              >
                <span class="align-middle">Next</span>
                <feather-icon
                  icon="ChevronRightIcon"
                  class="ml-50"
                />
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BFormTextarea, BFormGroup, BForm, BFormCheckboxGroup, BFormCheckbox, BRow, BCol, BButton,
  BMedia, BCard, BCardText, BAvatar,
} from 'bootstrap-vue'
import {
  computed,
  // watch,
  ref,
} from '@vue/composition-api'
import store from '@/store'
import Swal from 'sweetalert2'
import router from '@/router'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BMedia,
    BCard,
    BCardText,
    BAvatar,
  },
  props: {
    userRole: {
      type: String,
      required: true,
    },
  },
  methods: {
    onReset() {
      const bckUpData = this.legalDocBckUp
      if (bckUpData.length !== 0) {
        store.dispatch('client_assessmentcare/filterTab28', bckUpData)
        // console.log('bckUpData', bckUpData)
      } else {
        store.state.client_assessmentcare.legalDoc = {}
      }
    },
  },
  setup(props, { emit }) {
    const pageTitle = 'Legal Documentation'
    const simpleRules = ref(null)
    const localUserRole = ref(props.userRole)
    const clientId = router.currentRoute.query.id ? router.currentRoute.query.id : ''
    const legalDoc = ref(computed(() => store.state.client_assessmentcare.legalDoc))
    const legalDocBckUp = ref(computed(() => store.state.client_assessmentcare.legalDocBckUp))
    const onClickNextTab = () => {
      emit('next-tab')
    }
    // options declaration with values
    const legalDocOptions = [
      { id: 1, text: 'Living Will', value: 'Living Will' },
      { id: 2, text: 'Durable Medical Power of Attorney', value: 'Durable Medical Power of Attorney' },
    ]
    const doNotResuscitateOptions = [
      { id: 1, text: 'Chemical', value: 'Chemical' },
      { id: 2, text: 'Compression', value: 'Compression' },
      { id: 3, text: 'None', value: 'None' },
    ]

    // *===============================================---*
    // *--------- SAVE DATA FUNCTION ---------------------*
    // *===============================================---*
    const onSaveData = () => {
      // const uData = localStorage.getItem('userData')
      // const uID = JSON.parse(uData)
      const formData = new FormData()
      const data = JSON.stringify({
        // client_id: uID.clientId,
        client_id: clientId,
        tab_name: pageTitle,
        legal_doc: legalDoc.value.legal_doc,
        do_not_resuscitate: legalDoc.value.do_not_resuscitate,
        instructions: legalDoc.value.instructions,
      })
      formData.append('data', data)
      // console.log('formData', data)
      store.dispatch('client_assessmentcare/insertData', formData)
        .then(() => {
          Swal.fire({
            icon: 'success',
            title: 'Updated Succesfully',
            showConfirmButton: false,
            timer: 1500,
            customClass: {
              confirmButton: 'btn btn-secondary',
            },
            buttonsStyling: false,
          })
          emit('fetch-my-data')
        })
        .catch(error => {
          console.error(error)
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
            showConfirmButton: false,
            timer: 1500,
          })
          // console.log('error', error)
        })
    }

    // *===============================================---*
    // *--------- VALIDATE THEN SAVE DATA ----------------*
    // *===============================================---*
    const validationForm = () => {
      simpleRules.value.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          // alert('form submitted!')
          onSaveData()
          // console.log('ref.simpleRules', simpleRules.value)
        } else {
          Swal.fire({
            icon: 'warning',
            title: 'Oops...',
            text: 'Make sure to fill up  the required fields',
            showConfirmButton: false,
            timer: 1500,
          })
        }
      })
    }
    return {
      pageTitle,
      simpleRules,
      localUserRole,
      legalDoc,
      legalDocBckUp,
      legalDocOptions,
      doNotResuscitateOptions,
      validationForm,
      onClickNextTab,
      required,
    }
  },
}
</script>

<style>
.btn-reset:focus  {
  border: 1px solid #82868b !important;
  background-color:transparent !important;
  box-shadow: none !important;
}
.btn-reset:hover  {
  box-shadow: 0 8px 25px -8px #9c9fa0 !important;
}
</style>
