var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-media',{attrs:{"vertical-align":"top"},scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{staticStyle:{"background-color":"white","box-shadow":"1px 1px 1px #aaaaaa"},attrs:{"rounded":"","variant":"light-secondary","size":"50"}},[_c('img',{attrs:{"src":require("@/assets/images/icons/assessment-care/elimination-icon-default.svg"),"width":"28px","height":"28px"}})])]},proxy:true}])},[_c('h4',{staticClass:"media-heading"},[_vm._v(" "+_vm._s(_vm.pageTitle)+" ")]),_c('b-card-text',{staticClass:"mb-0"},[_vm._v(" Client's regulation, control, and removal of by-products and wastes in the body. ")])],1),_c('b-card',{staticClass:"mt-2"},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Incontinence","label-for":"incontinence"}},[_c('validation-provider',{attrs:{"name":"Incontinence","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox-group',{attrs:{"state":errors.length > 0 ? false:null,"name":"incontinence-options","disabled":_vm.localUserRole === 'client' || _vm.localUserRole === 'agent' ? true : false},model:{value:(_vm.elimination.incontinence),callback:function ($$v) {_vm.$set(_vm.elimination, "incontinence", $$v)},expression:"elimination.incontinence"}},_vm._l((_vm.incontinenceOptions),function(option){return _c('b-form-checkbox',{key:option.id,staticClass:"mr-4",attrs:{"value":option.value}},[_vm._v(" "+_vm._s(option.text)+" ")])}),1),(_vm.localUserRole !== 'client' && _vm.localUserRole !== 'agent' )?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Issues","label-for":"issues"}},[_c('validation-provider',{attrs:{"name":"Issues","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox-group',{attrs:{"state":errors.length > 0 ? false:null,"name":"issues-options","disabled":_vm.localUserRole === 'client' || _vm.localUserRole === 'agent' ? true : false},model:{value:(_vm.elimination.issues),callback:function ($$v) {_vm.$set(_vm.elimination, "issues", $$v)},expression:"elimination.issues"}},_vm._l((_vm.issuesOptions),function(option){return _c('b-form-checkbox',{key:option.id,staticClass:"mr-4",attrs:{"value":option.value}},[_vm._v(" "+_vm._s(option.text)+" ")])}),1),(_vm.localUserRole !== 'client' && _vm.localUserRole !== 'agent' )?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Wear briefs?","label-for":"wear-briefs","label-cols-md":"3"}},[_c('validation-provider',{attrs:{"name":"Wear briefs?","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio-group',{staticStyle:{"margin-top":"8px"},attrs:{"state":errors.length > 0 ? false:null,"name":"Wear briefs?","disabled":_vm.localUserRole === 'client' || _vm.localUserRole === 'agent' ? true : false},model:{value:(_vm.elimination.wear_briefs),callback:function ($$v) {_vm.$set(_vm.elimination, "wear_briefs", $$v)},expression:"elimination.wear_briefs"}},_vm._l((_vm.radioYN),function(option){return _c('b-form-radio',{key:option.text,staticClass:"mr-4",attrs:{"value":option.text}},[_vm._v(" "+_vm._s(option.text)+" ")])}),1),(_vm.localUserRole !== 'client' && _vm.localUserRole !== 'agent')?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Underpads","label-for":"underpads","label-cols-md":"3"}},[_c('validation-provider',{attrs:{"name":"Underpads","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio-group',{staticStyle:{"margin-top":"8px"},attrs:{"state":errors.length > 0 ? false:null,"name":"underpads-radio-group","disabled":_vm.localUserRole === 'client' || _vm.localUserRole === 'agent' ? true : false},model:{value:(_vm.elimination.underpads),callback:function ($$v) {_vm.$set(_vm.elimination, "underpads", $$v)},expression:"elimination.underpads"}},_vm._l((_vm.radioYN),function(option){return _c('b-form-radio',{key:option.text,staticClass:"mr-4",attrs:{"value":option.text}},[_vm._v(" "+_vm._s(option.text)+" ")])}),1),(_vm.localUserRole !== 'client' && _vm.localUserRole !== 'agent' )?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Notes","label-for":"notes"}},[_c('validation-provider',{attrs:{"name":"Notes","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"state":errors.length > 0 ? false:null,"name":"notes-underpads","disabled":_vm.localUserRole === 'client' || _vm.localUserRole === 'agent' ? true : false,"rows":"3"},model:{value:(_vm.elimination.notes),callback:function ($$v) {_vm.$set(_vm.elimination, "notes", $$v)},expression:"elimination.notes"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',{staticClass:"mt-2"},[(_vm.localUserRole !== 'client' && _vm.localUserRole !== 'agent' )?_c('b-col',{attrs:{"md":"10"}},[_c('b-button',{staticClass:"col-sm-3 mr-1",attrs:{"variant":"secondary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" Save Changes ")]),_c('b-button',{staticClass:"col-sm-2 btn-reset",attrs:{"variant":"outline-dark"},on:{"click":function($event){return _vm.onReset()}}},[_vm._v(" Reset ")])],1):_vm._e(),(_vm.localUserRole === 'client' || _vm.localUserRole === 'agent')?_c('b-col',{staticClass:"text-right"},[_c('b-button',{attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.onClickNextTab()}}},[_c('span',{staticClass:"align-middle"},[_vm._v("Next")]),_c('feather-icon',{staticClass:"ml-50",attrs:{"icon":"ChevronRightIcon"}})],1)],1):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }