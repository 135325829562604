var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-media',{attrs:{"vertical-align":"top"},scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{staticStyle:{"background-color":"white","box-shadow":"1px 1px 1px #aaaaaa"},attrs:{"rounded":"","variant":"light-secondary","size":"50"}},[_c('img',{attrs:{"src":require("@/assets/images/icons/assessment-care/coverage-icon-default.svg"),"width":"28px","height":"28px"}})])]},proxy:true}])},[_c('h4',{staticClass:"media-heading"},[_vm._v(" "+_vm._s(_vm.pageTitle)+" ")]),_c('b-card-text',{staticClass:"mb-0"})],1),_c('b-card',{staticClass:"mt-2"},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Long-Term Care Coverage","label-for":"Long-Term Care Coverage"}},[_c('validation-provider',{attrs:{"name":"Long-Term Care Coverage","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio-group',{attrs:{"name":"long-term-care-coverage-opt","disabled":_vm.localUserRole === 'client' || _vm.localUserRole === 'agent' ? true : false},model:{value:(_vm.longTermCareCoverage.long_term_coverage),callback:function ($$v) {_vm.$set(_vm.longTermCareCoverage, "long_term_coverage", $$v)},expression:"longTermCareCoverage.long_term_coverage"}},_vm._l((_vm.radioOptions),function(option){return _c('b-form-radio',{key:option.text,staticClass:"mr-3",attrs:{"value":option.text}},[_vm._v(" "+_vm._s(option.text)+" ")])}),1),(_vm.localUserRole !== 'client' && _vm.localUserRole !== 'agent' && (_vm.isFormSubmitted && !_vm.longTermCareCoverage.long_term_coverage))?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Company","label-for":"company-field"}},[_c('validation-provider',{attrs:{"name":"Company","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"name":"company-field","disabled":_vm.localUserRole === 'client' || _vm.localUserRole === 'agent' ? true : false},model:{value:(_vm.longTermCareCoverage.company),callback:function ($$v) {_vm.$set(_vm.longTermCareCoverage, "company", $$v)},expression:"longTermCareCoverage.company"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Phone","label-for":"phone-field"}},[_c('validation-provider',{attrs:{"name":"Phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:(['(###) ###-####']),expression:"['(###) ###-####']"}],attrs:{"state":errors.length > 0 ? false:null,"name":"phone-field","disabled":_vm.localUserRole === 'client' || _vm.localUserRole === 'agent' ? true : false},model:{value:(_vm.longTermCareCoverage.phone),callback:function ($$v) {_vm.$set(_vm.longTermCareCoverage, "phone", $$v)},expression:"longTermCareCoverage.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Policy#","label-for":"policy-field"}},[_c('validation-provider',{attrs:{"name":"Policy#","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"name":"policy-field","disabled":_vm.localUserRole === 'client' || _vm.localUserRole === 'agent' ? true : false},model:{value:(_vm.longTermCareCoverage.policy),callback:function ($$v) {_vm.$set(_vm.longTermCareCoverage, "policy", $$v)},expression:"longTermCareCoverage.policy"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"% covered","label-for":"covered-field"}},[_c('validation-provider',{attrs:{"name":"covered","rules":"required|between:0,100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"name":"covered-field","disabled":_vm.localUserRole === 'client' || _vm.localUserRole === 'agent' ? true : false},model:{value:(_vm.longTermCareCoverage.covered),callback:function ($$v) {_vm.$set(_vm.longTermCareCoverage, "covered", $$v)},expression:"longTermCareCoverage.covered"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"% not covered","label-for":"not-covered-field"}},[_c('validation-provider',{attrs:{"name":"not covered","rules":"required|between:0,100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"name":"not-covered-field","disabled":_vm.localUserRole === 'client' || _vm.localUserRole === 'agent' ? true : false},model:{value:(_vm.longTermCareCoverage.not_covered),callback:function ($$v) {_vm.$set(_vm.longTermCareCoverage, "not_covered", $$v)},expression:"longTermCareCoverage.not_covered"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',{staticClass:"mt-2"},[(_vm.localUserRole !== 'client' && _vm.localUserRole !== 'agent' )?_c('b-col',{attrs:{"md":"10"}},[_c('b-button',{staticClass:"col-sm-3 mr-1",attrs:{"variant":"secondary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" Save Changes ")]),_c('b-button',{staticClass:"col-sm-2 btn-reset",attrs:{"variant":"outline-dark"},on:{"click":function($event){return _vm.onReset()}}},[_vm._v(" Reset ")])],1):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }