<template>
  <div>
    <b-media vertical-align="top">
      <template #aside>
        <b-avatar
          rounded
          variant="light-secondary"
          size="50"
          style="background-color: white; box-shadow: 1px 1px 1px #aaaaaa"
        >
          <img
            src="@/assets/images/icons/assessment-care/activities-icon-default.svg"
            width="28px"
            height="28px"
          >
        </b-avatar>
      </template>
      <h4 class="media-heading">
        {{ pageTitle }}
      </h4>
      <b-card-text class="mb-0">
        Activities and associated caregiver tasks
      </b-card-text>
    </b-media>
    <!-- form -->
    <b-card class="mt-2">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col
              cols="12"
              class=""
            >
              <b-media vertical-align="top">
                <template #aside>
                  <feather-icon
                    icon="CalendarIcon"
                    size="24"
                  />
                </template>
                <h4 class="media-heading">
                  Ambulation & Transfer
                </h4>
              </b-media>
              <span>Walking or getting around, getting in and out of the bed or chair.</span>
            </b-col>
            <b-col cols="12 row m-0 mt-2">
              <label
                for=""
                class="h6 col-sm-7 p-0"
              >
                Assist with transfers
              </label>
              <!-- <b-form-checkbox
                v-model="actOfDailyLiving.AT_assist_with_transfers"
                value="All shifts"
                class="col-sm-2"
                :disabled="localUserRole === 'admin' ? true : false"
                @change="isSelected(actOfDailyLiving.AT_assist_with_transfers)"
              >
                <span>All Shifts</span>
              </b-form-checkbox> -->
              <label
                for=""
                class="col-sm-2 p-0"
              >
                Requires Assistance?:
              </label>
              <validation-provider
                #default="{ errors }"
                name="Assist with transfers"
                rules="required"
              >
                <b-form-radio-group
                  v-model="actOfDailyLiving.AT_assist_with_transfers_Yn"
                  name="adl1"
                  :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                  class=""
                >
                  <b-form-radio
                    v-for="option in radioYN"
                    :key="option.text"
                    :value="option.text"
                    class="mr-4"
                  >
                    {{ option.text }}
                  </b-form-radio>
                </b-form-radio-group>
                <small
                  v-if="localUserRole !== 'client' && localUserRole !== 'agent'"
                  class="text-danger"
                >{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col cols="12 row m-0 mt-1">
              <label
                for=""
                class="h6 col-sm-7 p-0"
              >
                Walk with client
              </label>
              <!-- <b-form-checkbox
                v-model="actOfDailyLiving.AT_walk_with_client"
                value="All shifts"
                class="col-sm-2"
                :disabled="localUserRole === 'admin' ? true : false"
                @change="isSelected2(actOfDailyLiving.AT_walk_with_client)"
              >
                <span>All Shifts</span>
              </b-form-checkbox> -->
              <label
                for=""
                class="col-sm-2 p-0"
              >
                Requires Assistance?:
              </label>
              <b-form-radio-group
                v-model="actOfDailyLiving.AT_walk_with_client_Yn"
                :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                name="adl2"
                class=""
              >
                <b-form-radio
                  v-for="option in radioYN"
                  :key="option.text"
                  :value="option.text"
                  class="mr-4"
                >
                  {{ option.text }}
                </b-form-radio>
              </b-form-radio-group>
            </b-col>
          </b-row>
          <hr class="my-3">
          <b-row>
            <b-col
              cols="12"
              class=""
            >
              <b-media vertical-align="top">
                <template #aside>
                  <feather-icon
                    icon="CalendarIcon"
                    size="24"
                  />
                </template>
                <h4 class="media-heading">
                  Bathing
                </h4>
              </b-media>
              <span>Bathing in shower or tub.</span>
            </b-col>
            <b-col cols="12 row m-0 mt-2">
              <label
                for=""
                class="h6 col-sm-7 p-0"
              >
                Assist into and out of shower
              </label>
              <!-- <b-form-checkbox
                v-model="actOfDailyLiving.B_assist_in_out_shower"
                value="All shifts"
                class="col-sm-2"
                :disabled="localUserRole === 'admin' ? true : false"
                @change="isSelected3(actOfDailyLiving.B_assist_in_out_shower)"
              >
                <span>All Shifts</span>
              </b-form-checkbox> -->
              <label
                for=""
                class="col-sm-2 p-0"
              >
                Requires Assistance?:
              </label>
              <validation-provider
                #default="{ errors }"
                name="Assist into and out of shower"
                rules="required"
              >
                <b-form-radio-group
                  v-model="actOfDailyLiving.B_assist_in_out_shower_Yn"
                  name="Bathing1"
                  class=""
                  :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                  :state="errors.length > 0 ? false:null"
                >
                  <b-form-radio
                    v-for="option in radioYN"
                    :key="option.text"
                    :value="option.text"
                    class="mr-4"
                  >
                    {{ option.text }}
                  </b-form-radio>
                </b-form-radio-group>
                <small
                  v-if="localUserRole !== 'client' && localUserRole !== 'agent'"
                  class="text-danger"
                >{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col cols="12 row m-0 mt-1">
              <label
                for=""
                class="h6 col-sm-7 p-0"
              >
                Bathe the client
              </label>
              <!-- <b-form-checkbox
                v-model="actOfDailyLiving.B_bathe_client"
                value="All shifts"
                class="col-sm-2"
                :disabled="localUserRole === 'admin' ? true : false"
                @change="isSelected4(actOfDailyLiving.B_bathe_client)"
              >
                <span>All Shifts</span>
              </b-form-checkbox> -->
              <label
                for=""
                class="col-sm-2 p-0"
              >
                Requires Assistance?:
              </label>
              <b-form-radio-group
                v-model="actOfDailyLiving.B_bathe_client_Yn"
                name="adl4"
                class=""
                :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
              >
                <b-form-radio
                  v-for="option in radioYN"
                  :key="option.text"
                  :value="option.text"
                  class="mr-4"
                >
                  {{ option.text }}
                </b-form-radio>
              </b-form-radio-group>
            </b-col>
            <b-col cols="12 row m-0 mt-1">
              <label
                for=""
                class="h6 col-sm-7 p-0"
              >
                Monitor in bath
              </label>
              <!-- <b-form-checkbox
                v-model="actOfDailyLiving.B_minor_bath"
                value="All shifts"
                class="col-sm-2"
                :disabled="localUserRole === 'admin' ? true : false"
                @change="isSelected5(actOfDailyLiving.B_minor_bath)"
              >
                <span>All Shifts</span>
              </b-form-checkbox> -->
              <label
                for=""
                class="col-sm-2 p-0"
              >
                Requires Assistance?:
              </label>
              <b-form-radio-group
                v-model="actOfDailyLiving.B_minor_bath_Yn"
                :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                name="adl5"
                class=""
              >
                <b-form-radio
                  v-for="option in radioYN"
                  :key="option.text"
                  :value="option.text"
                  class="mr-4"
                >
                  {{ option.text }}
                </b-form-radio>
              </b-form-radio-group>
            </b-col>
            <b-col cols="12 row m-0 mt-1">
              <label
                for=""
                class="h6 col-sm-7 p-0"
              >
                Shampoo hair
              </label>
              <!-- <b-form-checkbox
                v-model="actOfDailyLiving.B_shampoo_hair"
                value="All shifts"
                class="col-sm-2"
                :disabled="localUserRole === 'admin' ? true : false"
                @change="isSelected6(actOfDailyLiving.B_shampoo_hair)"
              >
                <span>All Shifts</span>
              </b-form-checkbox> -->
              <label
                for=""
                class="col-sm-2 p-0"
              >
                Requires Assistance?:
              </label>
              <b-form-radio-group
                v-model="actOfDailyLiving.B_shampoo_hair_Yn"
                :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                name="adl6"
                class=""
              >
                <b-form-radio
                  v-for="option in radioYN"
                  :key="option.text"
                  :value="option.text"
                  class="mr-4"
                >
                  {{ option.text }}
                </b-form-radio>
              </b-form-radio-group>
            </b-col>
          </b-row>
          <hr class="my-3">
          <b-row>
            <b-col
              cols="12"
              class=""
            >
              <b-media vertical-align="top">
                <template #aside>
                  <feather-icon
                    icon="CalendarIcon"
                    size="24"
                  />
                </template>
                <h4 class="media-heading">
                  Continence
                </h4>
              </b-media>
              <span>Self control of urination and defecation.</span>
            </b-col>
            <b-col cols="12 row m-0 mt-2">
              <label
                for=""
                class="h6 col-sm-7 p-0"
              >
                Change Diapers
              </label>
              <!-- <b-form-checkbox
                v-model="actOfDailyLiving.C_change_diapers"
                value="All shifts"
                class="col-sm-2"
                :disabled="localUserRole === 'admin' ? true : false"
                @change="isSelected7(actOfDailyLiving.C_change_diapers)"
              >
                <span>All Shifts</span>
              </b-form-checkbox> -->
              <label
                for=""
                class="col-sm-2 p-0"
              >
                Requires Assistance?:
              </label>
              <b-form-radio-group
                v-model="actOfDailyLiving.C_change_diapers_Yn"
                :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                name="adl7"
                class=""
              >
                <b-form-radio
                  v-for="option in radioYN"
                  :key="option.text"
                  :value="option.text"
                  class="mr-4"
                >
                  {{ option.text }}
                </b-form-radio>
              </b-form-radio-group>
            </b-col>
          </b-row>
          <hr class="my-3">
          <b-row>
            <b-col
              cols="12"
              class=""
            >
              <b-media vertical-align="top">
                <template #aside>
                  <feather-icon
                    icon="CalendarIcon"
                    size="24"
                  />
                </template>
                <h4 class="media-heading">
                  Dressing & Grooming
                </h4>
              </b-media>
              <span>Picking clothes, managing fasteners, combing hair shaving, toothbrushing.</span>
            </b-col>
            <b-col cols="12 row m-0 mt-2">
              <label
                for=""
                class="h6 col-sm-7 p-0"
              >
                Assist with dressing
              </label>
              <!-- <b-form-checkbox
                v-model="actOfDailyLiving.DG_assist_dressing"
                value="All shifts"
                class="col-sm-2"
                :disabled="localUserRole === 'admin' ? true : false"
                @change="isSelected8(actOfDailyLiving.DG_assist_dressing)"
              >
                <span>All Shifts</span>
              </b-form-checkbox> -->
              <label
                for=""
                class="col-sm-2 p-0"
              >
                Requires Assistance?:
              </label>
              <b-form-radio-group
                v-model="actOfDailyLiving.DG_assist_dressing_Yn"
                :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                name="adl8"
                class=""
              >
                <b-form-radio
                  v-for="option in radioYN"
                  :key="option.text"
                  :value="option.text"
                  class="mr-4"
                >
                  {{ option.text }}
                </b-form-radio>
              </b-form-radio-group>
            </b-col>
            <b-col cols="12 row m-0 mt-1">
              <label
                for=""
                class="h6 col-sm-7 p-0"
              >
                Brush teeth
              </label>
              <!-- <b-form-checkbox
                v-model="actOfDailyLiving.DG_brush_teeth"
                value="All shifts"
                class="col-sm-2"
                :disabled="localUserRole === 'admin' ? true : false"
                @change="isSelected9(actOfDailyLiving.DG_brush_teeth)"
              >
                <span>All Shifts</span>
              </b-form-checkbox> -->
              <label
                for=""
                class="col-sm-2 p-0"
              >
                Requires Assistance?:
              </label>
              <b-form-radio-group
                v-model="actOfDailyLiving.DG_brush_teeth_Yn"
                :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                name="adl9"
                class=""
              >
                <b-form-radio
                  v-for="option in radioYN"
                  :key="option.text"
                  :value="option.text"
                  class="mr-4"
                >
                  {{ option.text }}
                </b-form-radio>
              </b-form-radio-group>
            </b-col>
            <b-col cols="12 row m-0 mt-1">
              <label
                for=""
                class="h6 col-sm-7 p-0"
              >
                Comb hair, dress the client
              </label>
              <!-- <b-form-checkbox
                v-model="actOfDailyLiving.DG_comb_dress"
                value="All shifts"
                class="col-sm-2"
                :disabled="localUserRole === 'admin' ? true : false"
                @change="isSelected10(actOfDailyLiving.DG_comb_dress)"
              >
                <span>All Shifts</span>
              </b-form-checkbox> -->
              <label
                for=""
                class="col-sm-2 p-0"
              >
                Requires Assistance?:
              </label>
              <b-form-radio-group
                v-model="actOfDailyLiving.DG_comb_dress_Yn"
                :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                name="adl10"
                class=""
              >
                <b-form-radio
                  v-for="option in radioYN"
                  :key="option.text"
                  :value="option.text"
                  class="mr-4"
                >
                  {{ option.text }}
                </b-form-radio>
              </b-form-radio-group>
            </b-col>
          </b-row>
          <hr class="my-3">
          <b-row>
            <b-col
              cols="12"
              class=""
            >
              <b-media vertical-align="top">
                <template #aside>
                  <feather-icon
                    icon="CalendarIcon"
                    size="24"
                  />
                </template>
                <h4 class="media-heading">
                  Toileting
                </h4>
              </b-media>
              <span>Getting on and toilet, arranging clothes, cleaning self.</span>
            </b-col>
            <b-col cols="12 row m-0 mt-2">
              <label
                for=""
                class="h6 col-sm-7 p-0"
              >
                Assist with toileting
              </label>
              <!-- <b-form-checkbox
                v-model="actOfDailyLiving.T_assist_toileting"
                value="All shifts"
                class="col-sm-2"
                :disabled="localUserRole === 'admin' ? true : false"
                @change="isSelected11(actOfDailyLiving.T_assist_toileting)"
              >
                <span>All Shifts</span>
              </b-form-checkbox> -->
              <label
                for=""
                class="col-sm-2 p-0"
              >
                Requires Assistance?:
              </label>
              <b-form-radio-group
                v-model="actOfDailyLiving.T_assist_toileting_Yn"
                :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                name="adl11"
                class=""
              >
                <b-form-radio
                  v-for="option in radioYN"
                  :key="option.text"
                  :value="option.text"
                  class="mr-4"
                >
                  {{ option.text }}
                </b-form-radio>
              </b-form-radio-group>
            </b-col>
            <b-col cols="12 row m-0 mt-1">
              <label
                for=""
                class="h6 col-sm-7 p-0"
              >
                Clean bathroom
              </label>
              <!-- <b-form-checkbox
                v-model="actOfDailyLiving.T_clean_bathroom"
                value="All shifts"
                class="col-sm-2"
                :disabled="localUserRole === 'admin' ? true : false"
                @change="isSelected12(actOfDailyLiving.T_clean_bathroom)"
              >
                <span>All Shifts</span>
              </b-form-checkbox> -->
              <label
                for=""
                class="col-sm-2 p-0"
              >
                Requires Assistance?:
              </label>
              <b-form-radio-group
                v-model="actOfDailyLiving.T_clean_bathroom_Yn"
                :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                name="adl12"
                class=""
              >
                <b-form-radio
                  v-for="option in radioYN"
                  :key="option.text"
                  :value="option.text"
                  class="mr-4"
                >
                  {{ option.text }}
                </b-form-radio>
              </b-form-radio-group>
            </b-col>
            <b-col cols="12 row m-0 mt-1">
              <label
                for=""
                class="h6 col-sm-7 p-0"
              >
                Take to toilet
              </label>
              <!-- <b-form-checkbox
                v-model="actOfDailyLiving.T_take_to_toilet"
                value="All shifts"
                class="col-sm-2"
                :disabled="localUserRole === 'admin' ? true : false"
                @change="isSelected13(actOfDailyLiving.T_take_to_toilet)"
              >
                <span>All Shifts</span>
              </b-form-checkbox> -->
              <label
                for=""
                class="col-sm-2 p-0"
              >
                Requires Assistance?:
              </label>
              <b-form-radio-group
                v-model="actOfDailyLiving.T_take_to_toilet_Yn"
                :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                name="adl13"
                class=""
              >
                <b-form-radio
                  v-for="option in radioYN"
                  :key="option.text"
                  :value="option.text"
                  class="mr-4"
                >
                  {{ option.text }}
                </b-form-radio>
              </b-form-radio-group>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col
              v-if="localUserRole !== 'client' && localUserRole !== 'agent'"
              md="10"
            >
              <!-- Action Buttons -->
              <b-button
                variant="secondary"
                class="col-sm-3 mr-1"
                type="submit"
                @click.prevent="validationForm"
              >
                Save Changes
              </b-button>
              <b-button
                variant="outline-dark"
                class="col-sm-2 btn-reset"
                @click="onReset()"
              >
                Reset
              </b-button>
            </b-col>
            <b-col
              v-if="localUserRole === 'client' || localUserRole === 'agent'"
              class="text-right"
            >
              <b-button
                class=""
                variant="secondary"
                @click="onClickNextTab()"
              >
                <span class="align-middle">Next</span>
                <feather-icon
                  icon="ChevronRightIcon"
                  class="ml-50"
                />
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BFormGroup, BForm, BRow, BCol, BButton, BFormCheckbox, BFormRadioGroup, BFormRadio,
  BMedia, BCard, BCardText, BAvatar,
} from 'bootstrap-vue'
import {
  computed,
  ref,
} from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import Swal from 'sweetalert2'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    BFormGroup,
    BForm,
    // eslint-disable-next-line vue/no-unused-components
    BFormCheckbox,
    BFormRadioGroup,
    BFormRadio,
    BRow,
    BCol,
    BButton,
    BMedia,
    BCard,
    BCardText,
    BAvatar,
  },
  props: {
    userRole: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const pageTitle = 'Activities of Daily Living'
    const simpleRules = ref(null)
    const actOfDailyLiving = ref(computed(() => store.state.client_assessmentcare.actOfDailyLiving))
    const actOfDailyLivingBckUp = ref(computed(() => store.state.client_assessmentcare.actOfDailyLivingBckUp))
    const localUserRole = ref(props.userRole)
    const clientId = router.currentRoute.query.id ? router.currentRoute.query.id : ''
    const onClickNextTab = () => {
      emit('next-tab')
    }
    const radioYN = [
      { id: 1, text: 'Yes', value: 'Yes' },
      { id: 2, text: 'No', value: 'No' },
    ]

    // *===============================================---*
    // *--------- SAVE DATA ------------------------------*
    // *===============================================---*
    const onSaveData = () => {
      // const uData = localStorage.getItem('userData')
      // const uID = JSON.parse(uData)
      // console.log('user data', clientId)
      const formData = new FormData()
      const data = JSON.stringify({
        // client_id: uID.clientId,
        client_id: clientId,
        tab_name: pageTitle,
        AT_assist_with_transfers_Yn: actOfDailyLiving.value.AT_assist_with_transfers_Yn,
        AT_walk_with_client_Yn: actOfDailyLiving.value.AT_walk_with_client_Yn,
        B_assist_in_out_shower_Yn: actOfDailyLiving.value.B_assist_in_out_shower_Yn,
        B_bathe_client_Yn: actOfDailyLiving.value.B_bathe_client_Yn,
        B_minor_bath_Yn: actOfDailyLiving.value.B_minor_bath_Yn,
        B_shampoo_hair_Yn: actOfDailyLiving.value.B_shampoo_hair_Yn,
        C_change_diapers_Yn: actOfDailyLiving.value.C_change_diapers_Yn,
        DG_assist_dressing_Yn: actOfDailyLiving.value.DG_assist_dressing_Yn,
        DG_brush_teeth_Yn: actOfDailyLiving.value.DG_brush_teeth_Yn,
        DG_comb_dress_Yn: actOfDailyLiving.value.DG_comb_dress_Yn,
        T_assist_toileting_Yn: actOfDailyLiving.value.T_assist_toileting_Yn,
        T_clean_bathroom_Yn: actOfDailyLiving.value.T_clean_bathroom_Yn,
        T_take_to_toilet_Yn: actOfDailyLiving.value.T_take_to_toilet_Yn,
      })
      formData.append('data', data)
      // console.log('uData.id', formData)
      store.dispatch('client_assessmentcare/insertData', formData)
        .then(() => {
          Swal.fire({
            icon: 'success',
            title: 'Updated Succesfully',
            showConfirmButton: false,
            timer: 1500,
            customClass: {
              confirmButton: 'btn btn-secondary',
            },
            buttonsStyling: false,
          })
          emit('fetch-my-data')
        })
        .catch(error => {
          console.error(error)
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
            showConfirmButton: false,
            timer: 1500,
          })
          // console.log('error', error)
        })
    }
    const validationForm = () => {
      simpleRules.value.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          // alert('form submitted!')
          onSaveData()
          // console.log('ref.simpleRules', simpleRules.value)
        } else {
          Swal.fire({
            icon: 'warning',
            title: 'Oops...',
            text: 'Make sure to fill up  the required fields',
            showConfirmButton: false,
            timer: 1500,
          })
        }
      })
    }

    // const onClear = () => {
    //   store.state.client_assessmentcare.actOfDailyLiving = {
    //     AT_assist_with_transfers_Yn: 'No',
    //     AT_walk_with_client_Yn: 'No',
    //     B_assist_in_out_shower_Yn: 'No',
    //     B_bathe_client_Yn: 'No',
    //     B_minor_bath_Yn: 'No',
    //     B_shampoo_hair_Yn: 'No',
    //     C_change_diapers_Yn: 'No',
    //     DG_assist_dressing_Yn: 'No',
    //     DG_brush_teeth_Yn: 'No',
    //     DG_comb_dress_Yn: 'No',
    //     T_assist_toileting_Yn: 'No',
    //     T_clean_bathroom_Yn: 'No',
    //     T_take_to_toilet_Yn: 'No',
    //   }
    // }

    return {
      pageTitle,
      simpleRules,
      localUserRole,
      actOfDailyLiving,
      actOfDailyLivingBckUp,
      validationForm,
      onClickNextTab,
      radioYN,
      required,
      // onClear,
    }
  },
  methods: {
    isSelected(val) {
      if (val === false) {
        this.actOfDailyLiving.AT_assist_with_transfers_Yn = ''
      }
      if (val === 'All shifts') {
        this.actOfDailyLiving.AT_assist_with_transfers_Yn = this.radioYN[1].value
      }
    },
    isSelected2(val) {
      if (val === false) {
        this.actOfDailyLiving.AT_walk_with_client_Yn = ''
      }
      if (val === 'All shifts') {
        this.actOfDailyLiving.AT_walk_with_client_Yn = this.radioYN[1].value
      }
    },
    isSelected3(val) {
      if (val === false) {
        this.actOfDailyLiving.B_assist_in_out_shower_Yn = ''
      }
      if (val === 'All shifts') {
        this.actOfDailyLiving.B_assist_in_out_shower_Yn = this.radioYN[1].value
      }
    },
    isSelected4(val) {
      if (val === false) {
        this.actOfDailyLiving.B_bathe_client_Yn = ''
      }
      if (val === 'All shifts') {
        this.actOfDailyLiving.B_bathe_client_Yn = this.radioYN[1].value
      }
    },
    isSelected5(val) {
      if (val === false) {
        this.actOfDailyLiving.B_minor_bath_Yn = ''
      }
      if (val === 'All shifts') {
        this.actOfDailyLiving.B_minor_bath_Yn = this.radioYN[1].value
      }
    },
    isSelected6(val) {
      if (val === false) {
        this.actOfDailyLiving.B_shampoo_hair_Yn = ''
      }
      if (val === 'All shifts') {
        this.actOfDailyLiving.B_shampoo_hair_Yn = this.radioYN[1].value
      }
    },
    isSelected7(val) {
      if (val === false) {
        this.actOfDailyLiving.C_change_diapers_Yn = ''
      }
      if (val === 'All shifts') {
        this.actOfDailyLiving.C_change_diapers_Yn = this.radioYN[1].value
      }
    },
    isSelected8(val) {
      if (val === false) {
        this.actOfDailyLiving.DG_assist_dressing_Yn = ''
      }
      if (val === 'All shifts') {
        this.actOfDailyLiving.DG_assist_dressing_Yn = this.radioYN[1].value
      }
    },
    isSelected9(val) {
      if (val === false) {
        this.actOfDailyLiving.DG_brush_teeth_Yn = ''
      }
      if (val === 'All shifts') {
        this.actOfDailyLiving.DG_brush_teeth_Yn = this.radioYN[1].value
      }
    },
    isSelected10(val) {
      if (val === false) {
        this.actOfDailyLiving.DG_comb_dress_Yn = ''
      }
      if (val === 'All shifts') {
        this.actOfDailyLiving.DG_comb_dress_Yn = this.radioYN[1].value
      }
    },
    isSelected11(val) {
      if (val === false) {
        this.actOfDailyLiving.T_assist_toileting_Yn = ''
      }
      if (val === 'All shifts') {
        this.actOfDailyLiving.T_assist_toileting_Yn = this.radioYN[1].value
      }
    },
    isSelected12(val) {
      if (val === false) {
        this.actOfDailyLiving.T_clean_bathroom_Yn = ''
      }
      if (val === 'All shifts') {
        this.actOfDailyLiving.T_clean_bathroom_Yn = this.radioYN[1].value
      }
    },
    isSelected13(val) {
      if (val === false) {
        this.actOfDailyLiving.T_take_to_toilet_Yn = ''
      }
      if (val === 'All shifts') {
        this.actOfDailyLiving.T_take_to_toilet_Yn = this.radioYN[1].value
      }
    },
    onReset() {
      const bckUpData = this.actOfDailyLivingBckUp
      if (bckUpData.length !== 0) {
        store.dispatch('client_assessmentcare/filterTab4', bckUpData)
      } else {
        store.state.client_assessmentcare.actOfDailyLiving = {
          AT_assist_with_transfers_Yn: 'No',
          AT_walk_with_client_Yn: 'No',
          B_assist_in_out_shower_Yn: 'No',
          B_bathe_client_Yn: 'No',
          B_minor_bath_Yn: 'No',
          B_shampoo_hair_Yn: 'No',
          C_change_diapers_Yn: 'No',
          DG_assist_dressing_Yn: 'No',
          DG_brush_teeth_Yn: 'No',
          DG_comb_dress_Yn: 'No',
          T_assist_toileting_Yn: 'No',
          T_clean_bathroom_Yn: 'No',
          T_take_to_toilet_Yn: 'No',
        }
      }
    },
  },
}
</script>

<style>
.btn-reset:focus  {
  border: 1px solid #82868b !important;
  background-color:transparent !important;
  box-shadow: none !important;
}
.btn-reset:hover  {
  box-shadow: 0 8px 25px -8px #9c9fa0 !important;
}
</style>
