<template>
  <div>
    <b-media vertical-align="top">
      <template #aside>
        <b-avatar
          rounded
          variant="light-secondary"
          size="50"
          style="background-color: white; box-shadow: 1px 1px 1px #aaaaaa"
        >
          <img
            src="@/assets/images/icons/assessment-care/instrumental-icon-default.svg"
            width="28px"
            height="28px"
          >
        </b-avatar>
      </template>
      <h4 class="media-heading">
        {{ pageTitle }}
      </h4>
      <b-card-text class="mb-0">
        Activities and associated caregiver tasks
      </b-card-text>
    </b-media>
    <!-- form -->
    <b-card class="mt-2">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col
              cols="12"
              class=""
            >
              <b-media vertical-align="top">
                <template #aside>
                  <feather-icon
                    icon="CalendarIcon"
                    size="24"
                  />
                </template>
                <h4 class="media-heading">
                  Housework
                </h4>
              </b-media>
              <span>Housekeeping or light (dusting, doing dishes).</span>
            </b-col>
            <b-col cols="12 row m-0 mt-2">
              <label
                for=""
                class="h6 col-sm-7 p-0"
              >
                Change bed linens, Cleaning bathroom
              </label>
              <!-- <b-form-checkbox
                v-model="inActOfDailyLiving.HW_bed_lines_bathroom"
                value="All shifts"
                class="col-sm-2"
                :disabled="localUserRole === 'admin' ? true : false"
                @change="isSelectedB(inActOfDailyLiving.HW_bed_lines_bathroom)"
              >
                <span>All Shifts</span>
              </b-form-checkbox> -->
              <label
                for=""
                class="col-sm-2 p-0"
              >
                Requires Assistance?:
              </label>
              <b-form-radio-group
                v-model="inActOfDailyLiving.HW_bed_lines_bathroom_Yn"
                :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                name="iadls1"
                class=""
              >
                <b-form-radio
                  v-for="option in radioYN"
                  :key="option.text"
                  :value="option.text"
                  class="mr-4"
                >
                  {{ option.text }}
                </b-form-radio>
              </b-form-radio-group>
            </b-col>
            <b-col cols="12 row m-0 mt-1">
              <label
                for=""
                class="h6 col-sm-7 p-0"
              >
                Clean living room, Make bed
              </label>
              <!-- <b-form-checkbox
                v-model="inActOfDailyLiving.HW_living_room_bed"
                value="All shifts"
                class="col-sm-2"
                :disabled="localUserRole === 'admin' ? true : false"
                @change="isSelectedB2(inActOfDailyLiving.HW_living_room_bed)"
              >
                <span>All Shifts</span>
              </b-form-checkbox> -->
              <label
                for=""
                class="col-sm-2 p-0"
              >
                Requires Assistance?:
              </label>
              <b-form-radio-group
                v-model="inActOfDailyLiving.HW_living_room_bed_Yn"
                :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                name="iadls2"
                class=""
              >
                <b-form-radio
                  v-for="option in radioYN"
                  :key="option.text"
                  :value="option.text"
                  class="mr-4"
                >
                  {{ option.text }}
                </b-form-radio>
              </b-form-radio-group>
            </b-col>
            <b-col cols="12 row m-0 mt-1">
              <label
                for=""
                class="h6 col-sm-7 p-0"
              >
                Sweeping, mopping, vacuuming the floor
              </label>
              <!-- <b-form-checkbox
                v-model="inActOfDailyLiving.HW_sweeping_mopping_vacuuming_floor"
                value="All shifts"
                class="col-sm-2"
                :disabled="localUserRole === 'admin' ? true : false"
                @change="isSelectedB3(inActOfDailyLiving.HW_sweeping_mopping_vacuuming_floor)"
              >
                <span>All Shifts</span>
              </b-form-checkbox> -->
              <label
                for=""
                class="col-sm-2 p-0"
              >
                Requires Assistance?:
              </label>
              <b-form-radio-group
                v-model="inActOfDailyLiving.HW_sweeping_mopping_vacuuming_floor_Yn"
                :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                name="iadls3"
                class=""
              >
                <b-form-radio
                  v-for="option in radioYN"
                  :key="option.text"
                  :value="option.text"
                  class="mr-4"
                >
                  {{ option.text }}
                </b-form-radio>
              </b-form-radio-group>
            </b-col>
          </b-row>
          <hr class="my-3">
          <b-row>
            <b-col
              cols="12"
              class=""
            >
              <b-media vertical-align="top">
                <template #aside>
                  <feather-icon
                    icon="CalendarIcon"
                    size="24"
                  />
                </template>
                <h4 class="media-heading">
                  Laundry
                </h4>
              </b-media>
              <span>Cleaning, ironing, folding, and putting away clothes.</span>
            </b-col>
            <b-col cols="12 row m-0 mt-2">
              <label
                for=""
                class="h6 col-sm-7 p-0"
              >
                Do laundy
              </label>
              <!-- <b-form-checkbox
                v-model="inActOfDailyLiving.L_Laundry"
                value="All shifts"
                class="col-sm-2"
                :disabled="localUserRole === 'admin' ? true : false"
                @change="isSelectedB4(inActOfDailyLiving.L_Laundry)"
              >
                <span>All Shifts</span>
              </b-form-checkbox> -->
              <label
                for=""
                class="col-sm-2 p-0"
              >
                Requires Assistance?:
              </label>
              <b-form-radio-group
                v-model="inActOfDailyLiving.L_Laundry_Yn"
                :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                name="iadls4"
                class=""
              >
                <b-form-radio
                  v-for="option in radioYN"
                  :key="option.text"
                  :value="option.text"
                  class="mr-4"
                >
                  {{ option.text }}
                </b-form-radio>
              </b-form-radio-group>
            </b-col>
          </b-row>
          <hr class="my-3">
          <b-row>
            <b-col
              cols="12"
              class=""
            >
              <b-media vertical-align="top">
                <template #aside>
                  <feather-icon
                    icon="CalendarIcon"
                    size="24"
                  />
                </template>
                <h4 class="media-heading">
                  Preparing Meals
                </h4>
              </b-media>
              <span>opening containers, cooking and seving.</span>
            </b-col>
            <b-col cols="12 row m-0 mt-2">
              <label
                for=""
                class="h6 col-sm-7 p-0"
              >
                Clear dishes
              </label>
              <!-- <b-form-checkbox
                v-model="inActOfDailyLiving.PM_clear_dishes"
                value="All shifts"
                class="col-sm-2"
                :disabled="localUserRole === 'admin' ? true : false"
                @change="isSelectedB5(inActOfDailyLiving.PM_clear_dishes)"
              >
                <span>All Shifts</span>
              </b-form-checkbox> -->
              <label
                for=""
                class="col-sm-2 p-0"
              >
                Requires Assistance?:
              </label>
              <b-form-radio-group
                v-model="inActOfDailyLiving.PM_clear_dishes_Yn"
                :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                name="iadls5"
                class=""
              >
                <b-form-radio
                  v-for="option in radioYN"
                  :key="option.text"
                  :value="option.text"
                  class="mr-4"
                >
                  {{ option.text }}
                </b-form-radio>
              </b-form-radio-group>
            </b-col>
            <b-col cols="12 row m-0 mt-1">
              <label
                for=""
                class="h6 col-sm-7 p-0"
              >
                Prepare meals
              </label>
              <!-- <b-form-checkbox
                v-model="inActOfDailyLiving.PM_prep_meals"
                value="All shifts"
                class="col-sm-2"
                :disabled="localUserRole === 'admin' ? true : false"
                @change="isSelectedB6(inActOfDailyLiving.PM_prep_meals)"
              >
                <span>All Shifts</span>
              </b-form-checkbox> -->
              <label
                for=""
                class="col-sm-2 p-0"
              >
                Requires Assistance?:
              </label>
              <b-form-radio-group
                v-model="inActOfDailyLiving.PM_prep_meals_Yn"
                :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                name="iadls6"
                class=""
              >
                <b-form-radio
                  v-for="option in radioYN"
                  :key="option.text"
                  :value="option.text"
                  class="mr-4"
                >
                  {{ option.text }}
                </b-form-radio>
              </b-form-radio-group>
            </b-col>
          </b-row>
          <hr class="my-3">
          <b-row>
            <b-col
              cols="12"
              class=""
            >
              <b-media vertical-align="top">
                <template #aside>
                  <feather-icon
                    icon="CalendarIcon"
                    size="24"
                  />
                </template>
                <h4 class="media-heading">
                  Shopping
                </h4>
              </b-media>
              <span>navigation a store, making selections and paying.</span>
            </b-col>
            <b-col cols="12 row m-0 mt-2">
              <label
                for=""
                class="h6 col-sm-7 p-0"
              >
                Grocery shopping
              </label>
              <!-- <b-form-checkbox
                v-model="inActOfDailyLiving.SHP_grocery_shop"
                value="All shifts"
                class="col-sm-2"
                :disabled="localUserRole === 'admin' ? true : false"
                @change="isSelectedB7(inActOfDailyLiving.SHP_grocery_shop)"
              >
                <span>All Shifts</span>
              </b-form-checkbox> -->
              <label
                for=""
                class="col-sm-2 p-0"
              >
                Requires Assistance?:
              </label>
              <b-form-radio-group
                v-model="inActOfDailyLiving.SHP_grocery_shop_Yn"
                :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                name="iadls7"
                class=""
              >
                <b-form-radio
                  v-for="option in radioYN"
                  :key="option.text"
                  :value="option.text"
                  class="mr-4"
                >
                  {{ option.text }}
                </b-form-radio>
              </b-form-radio-group>
            </b-col>
            <b-col cols="12 row m-0 mt-1">
              <label
                for=""
                class="h6 col-sm-7 p-0"
              >
                Pick up medications
              </label>
              <!-- <b-form-checkbox
                v-model="inActOfDailyLiving.SHP_pick_up_med"
                value="All shifts"
                class="col-sm-2"
                :disabled="localUserRole === 'admin' ? true : false"
                @change="isSelectedB8(inActOfDailyLiving.SHP_pick_up_med)"
              >
                <span>All Shifts</span>
              </b-form-checkbox> -->
              <label
                for=""
                class="col-sm-2 p-0"
              >
                Requires Assistance?:
              </label>
              <b-form-radio-group
                v-model="inActOfDailyLiving.SHP_pick_up_med_Yn"
                :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                name="iadls8"
                class=""
              >
                <b-form-radio
                  v-for="option in radioYN"
                  :key="option.text"
                  :value="option.text"
                  class="mr-4"
                >
                  {{ option.text }}
                </b-form-radio>
              </b-form-radio-group>
            </b-col>
          </b-row>
          <hr class="my-3">
          <b-row>
            <b-col
              cols="12"
              class=""
            >
              <b-media vertical-align="top">
                <template #aside>
                  <feather-icon
                    icon="CalendarIcon"
                    size="24"
                  />
                </template>
                <h4 class="media-heading">
                  Travelling
                </h4>
              </b-media>
              <span>Driving or travelling on bus, taxi.</span>
            </b-col>
            <b-col cols="12 row m-0 mt-2">
              <label
                for=""
                class="h6 col-sm-7 p-0"
              >
                Drive to appointment
              </label>
              <!-- <b-form-checkbox
                v-model="inActOfDailyLiving.TR_dri_appointment"
                value="All shifts"
                class="col-sm-2"
                :disabled="localUserRole === 'admin' ? true : false"
                @change="isSelectedB9(inActOfDailyLiving.TR_dri_appointment)"
              >
                <span>All Shifts</span>
              </b-form-checkbox> -->
              <label
                for=""
                class="col-sm-2 p-0"
              >
                Requires Assistance?:
              </label>
              <b-form-radio-group
                v-model="inActOfDailyLiving.TR_dri_appointment_Yn"
                :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                name="iadls9"
                class=""
              >
                <b-form-radio
                  v-for="option in radioYN"
                  :key="option.text"
                  :value="option.text"
                  class="mr-4"
                >
                  {{ option.text }}
                </b-form-radio>
              </b-form-radio-group>
            </b-col>
            <b-col cols="12 row m-0 mt-1">
              <label
                for=""
                class="h6 col-sm-7 p-0"
              >
                Drive to errands
              </label>
              <!-- <b-form-checkbox
                v-model="inActOfDailyLiving.TR_dri_errands"
                value="All shifts"
                class="col-sm-2"
                :disabled="localUserRole === 'admin' ? true : false"
                @change="isSelectedB10(inActOfDailyLiving.TR_dri_errands)"
              >
                <span>All Shifts</span>
              </b-form-checkbox> -->
              <label
                for=""
                class="col-sm-2 p-0"
              >
                Requires Assistance?:
              </label>
              <b-form-radio-group
                v-model="inActOfDailyLiving.TR_dri_errands_Yn"
                :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                name="iadls10"
                class=""
              >
                <b-form-radio
                  v-for="option in radioYN"
                  :key="option.text"
                  :value="option.text"
                  class="mr-4"
                >
                  {{ option.text }}
                </b-form-radio>
              </b-form-radio-group>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col
              v-if="localUserRole !== 'client' && localUserRole !== 'agent' "
              md="10"
            >
              <!-- Action Buttons -->
              <b-button
                variant="secondary"
                class="col-sm-3 mr-1"
                type="submit"
                @click.prevent="validationForm"
              >
                Save Changes
              </b-button>
              <b-button
                variant="outline-dark"
                class="col-sm-2 btn-reset"
                @click="onReset()"
              >
                Reset
              </b-button>
            </b-col>
            <b-col
              v-if="localUserRole === 'client' || localUserRole === 'agent'"
              class="text-right"
            >
              <b-button
                class=""
                variant="secondary"
                @click="onClickNextTab()"
              >
                <span class="align-middle">Next</span>
                <feather-icon
                  icon="ChevronRightIcon"
                  class="ml-50"
                />
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BFormGroup, BForm, BRow, BCol, BButton, BFormCheckbox, BFormRadioGroup, BFormRadio,
  BMedia, BCard, BCardText, BAvatar,
} from 'bootstrap-vue'
import {
  computed,
  // watch,
  ref,
} from '@vue/composition-api'
import store from '@/store'
import Swal from 'sweetalert2'
import router from '@/router'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    BFormGroup,
    BForm,
    // eslint-disable-next-line vue/no-unused-components
    BFormCheckbox,
    BFormRadioGroup,
    BFormRadio,
    BRow,
    BCol,
    BButton,
    BMedia,
    BCard,
    BCardText,
    BAvatar,
  },
  props: {
    userRole: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const pageTitle = 'Instrumental Activities of Daily Living (IADLS)'
    const simpleRules = ref(null)
    const localUserRole = ref(props.userRole)
    const clientId = router.currentRoute.query.id ? router.currentRoute.query.id : ''
    const inActOfDailyLiving = ref(computed(() => store.state.client_assessmentcare.inActOfDailyLiving))
    const inActOfDailyLivingBckUp = ref(computed(() => store.state.client_assessmentcare.inActOfDailyLivingBckUp))
    const onClickNextTab = () => {
      emit('next-tab')
    }
    const radioYN = [
      { id: 1, text: 'Yes', value: 'Yes' },
      { id: 2, text: 'No', value: 'No' },
    ]

    // *===============================================---*
    // *--------- SAVE DATA ------------------------------*
    // *===============================================---*
    const onSaveData = () => {
      // const uData = localStorage.getItem('userData')
      // const uID = JSON.parse(uData)
      // console.log('user data', )
      const formData = new FormData()
      const data = JSON.stringify({
        // client_id: uID.clientId,
        client_id: clientId,
        tab_name: pageTitle,
        // HW_bed_lines_bathroom: inActOfDailyLiving.value.HW_bed_lines_bathroom,
        HW_bed_lines_bathroom_Yn: inActOfDailyLiving.value.HW_bed_lines_bathroom_Yn,
        // HW_living_room_bed: inActOfDailyLiving.value.HW_living_room_bed,
        HW_living_room_bed_Yn: inActOfDailyLiving.value.HW_living_room_bed_Yn,
        // HW_sweeping_mopping_vacuuming_floor: inActOfDailyLiving.value.HW_sweeping_mopping_vacuuming_floor,
        HW_sweeping_mopping_vacuuming_floor_Yn: inActOfDailyLiving.value.HW_sweeping_mopping_vacuuming_floor_Yn,
        // L_Laundry: inActOfDailyLiving.value.L_Laundry,
        L_Laundry_Yn: inActOfDailyLiving.value.L_Laundry_Yn,
        // PM_clear_dishes: inActOfDailyLiving.value.PM_clear_dishes,
        PM_clear_dishes_Yn: inActOfDailyLiving.value.PM_clear_dishes_Yn,
        // PM_prep_meals: inActOfDailyLiving.value.PM_prep_meals,
        PM_prep_meals_Yn: inActOfDailyLiving.value.PM_prep_meals_Yn,
        // SHP_grocery_shop: inActOfDailyLiving.value.SHP_grocery_shop,
        SHP_grocery_shop_Yn: inActOfDailyLiving.value.SHP_grocery_shop_Yn,
        // SHP_pick_up_med: inActOfDailyLiving.value.SHP_pick_up_med,
        SHP_pick_up_med_Yn: inActOfDailyLiving.value.SHP_pick_up_med_Yn,
        // TR_dri_appointment: inActOfDailyLiving.value.TR_dri_appointment,
        TR_dri_appointment_Yn: inActOfDailyLiving.value.TR_dri_appointment_Yn,
        // TR_dri_errands: inActOfDailyLiving.value.TR_dri_errands,
        TR_dri_errands_Yn: inActOfDailyLiving.value.TR_dri_errands_Yn,
      })
      formData.append('data', data)
      // console.log('uData.id', formData)
      store.dispatch('client_assessmentcare/insertData', formData)
        .then(() => {
          Swal.fire({
            icon: 'success',
            title: 'Updated Succesfully',
            showConfirmButton: false,
            timer: 1500,
            customClass: {
              confirmButton: 'btn btn-secondary',
            },
            buttonsStyling: false,
          })
          emit('fetch-my-data')
        })
        .catch(error => {
          console.error(error)
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
            showConfirmButton: false,
            timer: 1500,
          })
          // console.log('error', error)
        })
    }
    const validationForm = () => {
      simpleRules.value.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          // alert('form submitted!')
          onSaveData()
          // console.log('ref.simpleRules', simpleRules.value)
        } else {
          Swal.fire({
            icon: 'warning',
            title: 'Oops...',
            text: 'Make sure to fill up  the required fields',
            showConfirmButton: false,
            timer: 1500,
          })
        }
      })
    }

    // const onClear = () => {
    //   store.state.client_assessmentcare.inActOfDailyLiving = {
    //     HW_bed_lines_bathroom_Yn: 'No',
    //     HW_living_room_bed_Yn: 'No',
    //     HW_sweeping_mopping_vacuuming_floor_Yn: 'No',
    //     L_Laundry_Yn: 'No',
    //     PM_clear_dishes_Yn: 'No',
    //     PM_prep_meals_Yn: 'No',
    //     SHP_grocery_shop_Yn: 'No',
    //     SHP_pick_up_med_Yn: 'No',
    //     TR_dri_appointment_Yn: 'No',
    //     TR_dri_errands_Yn: 'No',
    //   }
    // }

    return {
      pageTitle,
      simpleRules,
      localUserRole,
      inActOfDailyLiving,
      inActOfDailyLivingBckUp,
      validationForm,
      onClickNextTab,
      radioYN,
      required,
      // onClear,
    }
  },
  methods: {
    isSelectedB(val) {
      if (val === false) {
        this.inActOfDailyLiving.HW_bed_lines_bathroom_Yn = ''
      }
      if (val === 'All shifts') {
        this.inActOfDailyLiving.HW_bed_lines_bathroom_Yn = this.radioYN[1].value
      }
    },
    isSelectedB2(val) {
      if (val === false) {
        this.inActOfDailyLiving.HW_living_room_bed_Yn = ''
      }
      if (val === 'All shifts') {
        this.inActOfDailyLiving.HW_living_room_bed_Yn = this.radioYN[1].value
      }
    },
    isSelectedB3(val) {
      if (val === false) {
        this.inActOfDailyLiving.HW_sweeping_mopping_vacuuming_floor_Yn = ''
      }
      if (val === 'All shifts') {
        this.inActOfDailyLiving.HW_sweeping_mopping_vacuuming_floor_Yn = this.radioYN[1].value
      }
    },
    isSelectedB4(val) {
      if (val === false) {
        this.inActOfDailyLiving.L_Laundry_Yn = ''
      }
      if (val === 'All shifts') {
        this.inActOfDailyLiving.L_Laundry_Yn = this.radioYN[1].value
      }
    },
    isSelectedB5(val) {
      if (val === false) {
        this.inActOfDailyLiving.PM_clear_dishes_Yn = ''
      }
      if (val === 'All shifts') {
        this.inActOfDailyLiving.PM_clear_dishes_Yn = this.radioYN[1].value
      }
    },
    isSelectedB6(val) {
      if (val === false) {
        this.inActOfDailyLiving.PM_prep_meals_Yn = ''
      }
      if (val === 'All shifts') {
        this.inActOfDailyLiving.PM_prep_meals_Yn = this.radioYN[1].value
      }
    },
    isSelectedB7(val) {
      if (val === false) {
        this.inActOfDailyLiving.SHP_grocery_shop_Yn = ''
      }
      if (val === 'All shifts') {
        this.inActOfDailyLiving.SHP_grocery_shop_Yn = this.radioYN[1].value
      }
    },
    isSelectedB8(val) {
      if (val === false) {
        this.inActOfDailyLiving.SHP_pick_up_med_Yn = ''
      }
      if (val === 'All shifts') {
        this.inActOfDailyLiving.SHP_pick_up_med_Yn = this.radioYN[1].value
      }
    },
    isSelectedB9(val) {
      if (val === false) {
        this.inActOfDailyLiving.TR_dri_appointment_Yn = ''
      }
      if (val === 'All shifts') {
        this.inActOfDailyLiving.TR_dri_appointment_Yn = this.radioYN[1].value
      }
    },
    isSelectedB10(val) {
      if (val === false) {
        this.inActOfDailyLiving.TR_dri_errands_Yn = ''
      }
      if (val === 'All shifts') {
        this.inActOfDailyLiving.TR_dri_errands_Yn = this.radioYN[1].value
      }
    },
    onReset() {
      const bckUpData = this.inActOfDailyLivingBckUp
      if (bckUpData.length !== 0) {
        store.dispatch('client_assessmentcare/filterTab5', bckUpData)
      } else {
        store.state.client_assessmentcare.inActOfDailyLiving = {
          HW_bed_lines_bathroom_Yn: 'No',
          HW_living_room_bed_Yn: 'No',
          HW_sweeping_mopping_vacuuming_floor_Yn: 'No',
          L_Laundry_Yn: 'No',
          PM_clear_dishes_Yn: 'No',
          PM_prep_meals_Yn: 'No',
          SHP_grocery_shop_Yn: 'No',
          SHP_pick_up_med_Yn: 'No',
          TR_dri_appointment_Yn: 'No',
          TR_dri_errands_Yn: 'No',
        }
      }
    },
  },
}
</script>

<style>
.btn-reset:focus  {
  border: 1px solid #82868b !important;
  background-color:transparent !important;
  box-shadow: none !important;
}
.btn-reset:hover  {
  box-shadow: 0 8px 25px -8px #9c9fa0 !important;
}
</style>
