<template>
  <b-overlay
    :show="isLoading"
    rounded="top"
  >
    <div
      v-if="userRole === 'admin' || userRole === 'agent'"
    >
      <client-tabs
        :user-role="userRole"
      />
    </div>
    <div v-else>
      <h1 class="mb-3">
        {{ pageTitle }}
      </h1>
      <h5 class="mb-2" />
    </div>
    <b-tabs
      v-model="tabIndex"
      vertical
      content-class="col-12 col-md-8 col-lg-9"
      pills
      nav-wrapper-class="faq-navigation col-md-4 col-lg-3 col-12"
      nav-class="nav-left"
      class="assessment-care-wrapper"
      @input="selectedTab()"
    >
      <!-- @input="$router.push({ params: { tabIndex: `tab-form-${tabIndex}` } }).catch(() => {})" -->
      <!-- <small>Current Tab: {{ tabIndex }}</small> -->
      <!-- <small>Current clientId: {{ clientId }}</small> -->
      <!-- Tab: Care Needs -->
      <b-tab
        title-link-class="d-flex justified-content-start align-content-center"
      >
        <template #title>
          <img
            src="@/assets/images/icons/assessment-care/careneed-icon-default.svg"
            size="20"
            class="mr-0 mr-sm-50 ac-icons"
          >
          <span class="d-none d-sm-inline">Care Needs</span>
        </template>
        <care-needs-tab
          :user-role="userRole"
          @fetch-my-data="fetchMyData"
          @next-tab="nextTab"
        />
      </b-tab>

      <!-- Tab: Demographics -->
      <b-tab
        title-link-class="d-flex justified-content-start align-content-center"
      >
        <template #title>
          <img
            src="@/assets/images/icons/assessment-care/demographics-icon-default.svg"
            size="20"
            class="mr-0 mr-sm-50 ac-icons"
          >
          <span class="d-none d-sm-inline">Demographics</span>
        </template>
        <demographics-tab
          :user-role="userRole"
          @fetch-my-data="fetchMyData"
          @next-tab="nextTab"
        />
      </b-tab>

      <!-- Tab: Brief History -->
      <b-tab
        title-link-class="d-flex justified-content-start align-content-center"
      >
        <template #title>
          <img
            src="@/assets/images/icons/assessment-care/history-icon-default.svg"
            size="20"
            class="mr-0 mr-sm-50 ac-icons"
          >
          <span class="d-none d-sm-inline">Brief History</span>
        </template>
        <brief-history-tab
          :user-role="userRole"
          @fetch-my-data="fetchMyData"
          @next-tab="nextTab"
        />
      </b-tab>

      <!-- Tab: Activities of Daily Living -->
      <b-tab
        title-link-class="d-flex justified-content-start align-content-center"
      >
        <template #title>
          <img
            src="@/assets/images/icons/assessment-care/activities-icon-default.svg"
            size="20"
            class="mr-0 mr-sm-50 ac-icons"
          >
          <span class="d-none d-sm-inline">Activities of Daily Living</span>
        </template>
        <activities-of-d-l-tab
          :user-role="userRole"
          @fetch-my-data="fetchMyData"
          @next-tab="nextTab"
        />
      </b-tab>

      <!-- Tab: Instrumental Activities of Daily Living (IADLS) -->
      <b-tab
        title-link-class="d-flex justified-content-start align-content-center"
      >
        <template #title>
          <img
            src="@/assets/images/icons/assessment-care/instrumental-icon-default.svg"
            size="20"
            class="mr-0 mr-sm-50 ac-icons"
          >
          <span class="d-none d-sm-inline">Instrumental Activities of Daily Living</span>
        </template>
        <instrumental-act-of-d-l-tab
          :user-role="userRole"
          @fetch-my-data="fetchMyData"
          @next-tab="nextTab"
        />
      </b-tab>

      <!-- Tab: Preferred Activities -->
      <b-tab
        title-link-class="d-flex justified-content-start align-content-center"
      >
        <template #title>
          <img
            src="@/assets/images/icons/assessment-care/preferredact-icon-default.svg"
            size="20"
            class="mr-0 mr-sm-50 ac-icons"
          >
          <span class="d-none d-sm-inline">Preferred Activities</span>
        </template>
        <preferred-act-tab
          :user-role="userRole"
          @fetch-my-data="fetchMyData"
          @next-tab="nextTab"
        />
      </b-tab>
      <!-- Tab: Meals -->
      <b-tab
        title-link-class="d-flex justified-content-start align-content-center"
      >
        <template #title>
          <img
            src="@/assets/images/icons/assessment-care/meals-icon-default.svg"
            size="20"
            class="mr-0 mr-sm-50 ac-icons"
          >
          <span class="d-none d-sm-inline">Meals</span>
        </template>
        <meals-tab
          :user-role="userRole"
          @fetch-my-data="fetchMyData"
          @next-tab="nextTab"
        />
      </b-tab>
      <!-- Tab: Dressings -->
      <b-tab
        title-link-class="d-flex justified-content-start align-content-center"
      >
        <template #title>
          <img
            src="@/assets/images/icons/assessment-care/dressings-icon-default.svg"
            class="ac-icons"
          >
          <span class="d-none d-sm-inline">Dressings</span>
        </template>
        <dressings-tab
          :user-role="userRole"
          @fetch-my-data="fetchMyData"
          @next-tab="nextTab"
        />
      </b-tab>
      <!-- Tab: Bathing -->
      <b-tab
        title-link-class="d-flex justified-content-start align-content-center"
      >
        <template #title>
          <img
            src="@/assets/images/icons/assessment-care/bathing-icon-default.svg"
            class="ac-icons"
          >
          <span class="d-none d-sm-inline">Bathing</span>
        </template>
        <bathing-tab
          :user-role="userRole"
          @fetch-my-data="fetchMyData"
          @next-tab="nextTab"
        />
      </b-tab>
      <!-- Tab: Laundry -->
      <b-tab
        title-link-class="d-flex justified-content-start align-content-center"
      >
        <template #title>
          <img
            src="@/assets/images/icons/assessment-care/laundry-icon-default.svg"
            class="ac-icons"
          >
          <span class="d-none d-sm-inline">Laundry</span>
        </template>
        <laundry-tab
          :user-role="userRole"
          @fetch-my-data="fetchMyData"
          @next-tab="nextTab"
        />
      </b-tab>
      <!-- Tab: Housekeeping -->
      <b-tab
        title-link-class="d-flex justified-content-start align-content-center"
      >
        <template #title>
          <img
            src="@/assets/images/icons/assessment-care/housekeeping-icon-default.svg"
            class="ac-icons"
          >
          <span class="d-none d-sm-inline">Housekeeping</span>
        </template>
        <housekeeping-tab
          :user-role="userRole"
          @fetch-my-data="fetchMyData"
          @next-tab="nextTab"
        />
      </b-tab>
      <!-- Tab: Sleep Habits -->
      <b-tab
        title-link-class="d-flex justified-content-start align-content-center"
      >
        <template #title>
          <img
            src="@/assets/images/icons/assessment-care/sleephabits-icon-default.svg"
            class="ac-icons"
          >
          <span class="d-none d-sm-inline">Sleep Habits</span>
        </template>
        <sleep-habits-tab
          :user-role="userRole"
          @fetch-my-data="fetchMyData"
          @next-tab="nextTab"
        />
      </b-tab>
      <!-- Tab: Future Plans to Goals -->
      <b-tab
        title-link-class="d-flex justified-content-start align-content-center"
      >
        <template #title>
          <img
            src="@/assets/images/icons/assessment-care/goals-icon-default.svg"
            class="ac-icons"
          >
          <span class="d-none d-sm-inline">Future Plans to Goals</span>
        </template>
        <future-plans-tab
          :user-role="userRole"
          @fetch-my-data="fetchMyData"
          @next-tab="nextTab"
        />
      </b-tab>
      <!-- Tab: Greatest Fears -->
      <b-tab
        title-link-class="d-flex justified-content-start align-content-center"
      >
        <template #title>
          <img
            src="@/assets/images/icons/assessment-care/fears-icon-default.svg"
            class="ac-icons"
          >
          <span class="d-none d-sm-inline">Greatest Fears</span>
        </template>
        <greatest-fears-tab
          :user-role="userRole"
          @fetch-my-data="fetchMyData"
          @next-tab="nextTab"
        />
      </b-tab>
      <!-- Tab: Medical Conditions -->
      <b-tab
        title-link-class="d-flex justified-content-start align-content-center"
      >
        <template #title>
          <img
            src="@/assets/images/icons/assessment-care/medical-icon-default.svg"
            class="ac-icons"
          >
          <span class="d-none d-sm-inline">Medical Conditions</span>
        </template>
        <med-conditions-tab
          :user-role="userRole"
          @fetch-my-data="fetchMyData"
          @next-tab="nextTab"
        />
      </b-tab>
      <!-- Tab: Mental/Behavior Conditions -->
      <b-tab
        title-link-class="d-flex justified-content-start align-content-center"
      >
        <template #title>
          <img
            src="@/assets/images/icons/assessment-care/mental-icon-default.svg"
            size="20"
            class="mr-0 mr-sm-50 ac-icons"
          >
          <span class="d-none d-sm-inline">Mental/Behavior Conditions</span>
        </template>
        <mental-behavior-conditions-tab
          :user-role="userRole"
          @fetch-my-data="fetchMyData"
          @next-tab="nextTab"
        />
      </b-tab>

      <!-- Tab: Allergies -->
      <b-tab
        title-link-class="d-flex justified-content-start align-content-center"
      >
        <template #title>
          <img
            src="@/assets/images/icons/assessment-care/allergies-icon-default.svg"
            size="20"
            class="mr-0 mr-sm-50 ac-icons"
          >
          <span class="d-none d-sm-inline">Allergies</span>
        </template>
        <allergies-tab
          :user-role="userRole"
          @fetch-my-data="fetchMyData"
          @next-tab="nextTab"
        />
      </b-tab>

      <!-- Tab: Elimination -->
      <b-tab
        title-link-class="d-flex justified-content-start align-content-center"
      >
        <template #title>
          <img
            src="@/assets/images/icons/assessment-care/elimination-icon-default.svg"
            size="20"
            class="mr-0 mr-sm-50 ac-icons"
          >
          <span class="d-none d-sm-inline">Elimination</span>
        </template>
        <elimination-tab
          :user-role="userRole"
          @fetch-my-data="fetchMyData"
          @next-tab="nextTab"
        />
      </b-tab>

      <!-- Tab: Ambulation -->
      <b-tab
        title-link-class="d-flex justified-content-start align-content-center"
      >
        <template #title>
          <img
            src="@/assets/images/icons/assessment-care/ambulation-icon-default.svg"
            size="20"
            class="mr-0 mr-sm-50 ac-icons"
          >
          <span class="d-none d-sm-inline">Ambulation</span>
        </template>
        <ambulation-tab
          :user-role="userRole"
          @fetch-my-data="fetchMyData"
          @next-tab="nextTab"
        />
      </b-tab>

      <!-- Tab: Prescription Medication -->
      <b-tab
        title-link-class="d-flex justified-content-start align-content-center"
      >
        <template #title>
          <img
            src="@/assets/images/icons/assessment-care/prescription-icon-default.svg"
            size="20"
            class="mr-0 mr-sm-50 ac-icons"
          >
          <span class="d-none d-sm-inline">Prescription Medication</span>
        </template>
        <prescript-med-tab
          :user-role="userRole"
          @fetch-my-data="fetchMyData"
          @next-tab="nextTab"
        />
      </b-tab>

      <!-- Tab: OTC Medications/Others -->
      <b-tab
        title-link-class="d-flex justified-content-start align-content-center"
      >
        <template #title>
          <img
            src="@/assets/images/icons/assessment-care/otc-icon-default.svg"
            size="20"
            class="mr-0 mr-sm-50 ac-icons"
          >
          <span class="d-none d-sm-inline">OTC Medications/Others</span>
        </template>
        <o-t-c-med-tab
          :user-role="userRole"
          @fetch-my-data="fetchMyData"
          @next-tab="nextTab"
        />
      </b-tab>

      <!-- Tab: Supplements -->
      <b-tab
        title-link-class="d-flex justified-content-start align-content-center"
      >
        <template #title>
          <img
            src="@/assets/images/icons/assessment-care/supplements-icon-default.svg"
            size="20"
            class="mr-0 mr-sm-50 ac-icons"
          >
          <span class="d-none d-sm-inline">Supplements</span>
        </template>
        <supplements-tab
          :user-role="userRole"
          @fetch-my-data="fetchMyData"
          @next-tab="nextTab"
        />
      </b-tab>

      <!-- Tab: Special Medication Instructions -->
      <b-tab
        title-link-class="d-flex justified-content-start align-content-center"
      >
        <template #title>
          <img
            src="@/assets/images/icons/assessment-care/specialmed-icon-default.svg"
            size="20"
            class="mr-0 mr-sm-50 ac-icons"
          >
          <span class="d-none d-sm-inline">Special Medication Instructions</span>
        </template>
        <special-med-instruct-tab
          :user-role="userRole"
          @fetch-my-data="fetchMyData"
          @next-tab="nextTab"
        />
      </b-tab>

      <!-- Tab: Pharmacy -->
      <b-tab
        title-link-class="d-flex justified-content-start align-content-center"
      >
        <template #title>
          <img
            src="@/assets/images/icons/assessment-care/pharmacy-icon-default.svg"
            size="20"
            class="mr-0 mr-sm-50 ac-icons"
          >
          <span class="d-none d-sm-inline">Pharmacy</span>
        </template>
        <pharmacy-tab
          :user-role="userRole"
          @fetch-my-data="fetchMyData"
          @next-tab="nextTab"
        />
      </b-tab>

      <!-- Tab: Physician -->
      <b-tab
        title-link-class="d-flex justified-content-start align-content-center"
      >
        <template #title>
          <img
            src="@/assets/images/icons/assessment-care/physician-icon-default.svg"
            size="20"
            class="mr-0 mr-sm-50 ac-icons"
          >
          <span class="d-none d-sm-inline">Physician</span>
        </template>
        <physician-tab
          :user-role="userRole"
          @fetch-my-data="fetchMyData"
          @next-tab="nextTab"
        />
      </b-tab>

      <!-- Tab: Eyeglasses -->
      <b-tab
        title-link-class="d-flex justified-content-start align-content-center"
      >
        <template #title>
          <img
            src="@/assets/images/icons/assessment-care/eyeglasses-icon-default.svg"
            size="20"
            class="mr-0 mr-sm-50 ac-icons"
          >
          <span class="d-none d-sm-inline">Eyeglasses</span>
        </template>
        <eyeglasses-tab
          :user-role="userRole"
          @fetch-my-data="fetchMyData"
          @next-tab="nextTab"
        />
      </b-tab>

      <!-- Tab: Dental -->
      <b-tab
        title-link-class="d-flex justified-content-start align-content-center"
      >
        <template #title>
          <img
            src="@/assets/images/icons/assessment-care/dental-icon-default.svg"
            size="20"
            class="mr-0 mr-sm-50 ac-icons"
          >
          <span class="d-none d-sm-inline">Dental</span>
        </template>
        <dental-tab
          :user-role="userRole"
          @fetch-my-data="fetchMyData"
          @next-tab="nextTab"
        />
      </b-tab>

      <!-- Tab: Legal Documentation -->
      <b-tab
        title-link-class="d-flex justified-content-start align-content-center"
      >
        <template #title>
          <img
            src="@/assets/images/icons/assessment-care/legal-icon-default.svg"
            size="20"
            class="mr-0 mr-sm-50 ac-icons"
          >
          <span class="d-none d-sm-inline">Legal Documentation</span>
        </template>
        <legal-doc-tab
          :user-role="userRole"
          @fetch-my-data="fetchMyData"
          @next-tab="nextTab"
        />
      </b-tab>

      <!-- Tab: Long-Term Care Coverage -->
      <b-tab
        title-link-class="d-flex justified-content-start align-content-center"
      >
        <template #title>
          <img
            src="@/assets/images/icons/assessment-care/coverage-icon-default.svg"
            size="20"
            class="mr-0 mr-sm-50 ac-icons"
          >
          <span class="d-none d-sm-inline">Long-Term Care Coverage</span>
        </template>
        <long-term-care-tab
          :user-role="userRole"
          @fetch-my-data="fetchMyData"
          @next-tab="nextTab"
        />
      </b-tab>

      <!-- Tab: Determination-->
      <b-tab
        v-if="userRole === 'admin'"
        title-link-class="d-flex justified-content-start align-content-center"
      >
        <template #title>
          <img
            src="@/assets/images/icons/assessment-care/determination-icon-default.svg"
            size="20"
            class="mr-0 mr-sm-50 ac-icons"
          >
          <span class="d-none d-sm-inline">Determination</span>
        </template>
        <determination-tab
          :user-role="userRole"
          @fetch-my-data="fetchMyData"
          @next-tab="nextTab"
        />
      </b-tab>
    </b-tabs>
  </b-overlay>
</template>

<script>
import {
  BTab, BTabs,
  BOverlay,
} from 'bootstrap-vue'
import {
  ref,
  onUnmounted,
} from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import assessmentCareStoreModule from './assessmentCareStoreModule.js'

import ClientTabs from '../clients/ClientTabs.vue'
// Tabs
import CareNeedsTab from './forms/CareNeedsTab'
import DemographicsTab from './forms/DemographicsTab'
import BriefHistoryTab from './forms/BriefHistoryTab'
import ActivitiesOfDLTab from './forms/ActivitiesOfDLTab'
import InstrumentalActOfDLTab from './forms/InstrumentalActOfDLTab'
import PreferredActTab from './forms/PreferredActTab'
import MealsTab from './forms/MealsTab'
import DressingsTab from './forms/DressingsTab'
import BathingTab from './forms/BathingTab'
import LaundryTab from './forms/LaundryTab'
import HousekeepingTab from './forms/HousekeepingTab'
import SleepHabitsTab from './forms/SleepHanitsTab'
import FuturePlansTab from './forms/FuturePlantsTab'
import GreatestFearsTab from './forms/GreatestFearsTab'
import MedConditionsTab from './forms/MedConditionsTab'
import MentalBehaviorConditionsTab from './forms/MentalBehaviorConditionsTab'
import AllergiesTab from './forms/AllergiesTab'
import EliminationTab from './forms/EliminationTab'
import AmbulationTab from './forms/AmbulationTab'
import PrescriptMedTab from './forms/PrescriptMedTab'
import OTCMedTab from './forms/OTCMedTab'
import SupplementsTab from './forms/SupplementsTab'
import SpecialMedInstructTab from './forms/SpecialMedInstructTab'
import PharmacyTab from './forms/PharmacyTab'
import PhysicianTab from './forms/PhysicianTab'
import EyeglassesTab from './forms/EyeglassesTab'
import DentalTab from './forms/DentalTab'
import LegalDocTab from './forms/LegalDocTab'
import LongTermCareTab from './forms/LongTermCareTab'
import DeterminationTab from './forms/Determination'

import '@/assets/scss/custom/assessment-care.scss'

export default {
  components: {
    BTab,
    BTabs,
    BOverlay,

    ClientTabs,
    CareNeedsTab,
    DemographicsTab,
    BriefHistoryTab,
    ActivitiesOfDLTab,
    InstrumentalActOfDLTab,
    PreferredActTab,
    MealsTab,
    DressingsTab,
    BathingTab,
    LaundryTab,
    HousekeepingTab,
    SleepHabitsTab,
    FuturePlansTab,
    GreatestFearsTab,
    MedConditionsTab,
    MentalBehaviorConditionsTab,
    AllergiesTab,
    EliminationTab,
    AmbulationTab,
    PrescriptMedTab,
    OTCMedTab,
    SupplementsTab,
    SpecialMedInstructTab,
    PharmacyTab,
    PhysicianTab,
    EyeglassesTab,
    DentalTab,
    LegalDocTab,
    LongTermCareTab,
    DeterminationTab,
  },
  created() {
    const user = JSON.parse(localStorage.getItem('userData'))
    this.userRole = user.role
    setTimeout(() => {
      const tempClientId = this.clientId.match(/(\d+)/)
      const tempTabNum = this.folderNum.match(/(\d+)/)
      // console.log('this.userRole', this.userRole)
      if (this.userRole === 'client' && tempTabNum) {
        if (tempTabNum[0] >= 0 && tempTabNum[0] < 28) {
          // eslint-disable-next-line prefer-destructuring
          this.tabIndex = tempTabNum[0]
          router.push({ params: { tabIndex: `tab-form-${tempTabNum[0]}` } }).catch(() => {})
        } else router.push({ params: { tabIndex: `tab-form-${0}` } }).catch(() => {})
      }
      if (this.userRole === 'client' && !tempTabNum) {
        router.push({ params: { tabIndex: `tab-form-${0}` } }).catch(() => {})
      }

      if (this.userRole === 'agent' && tempClientId) {
        if (tempTabNum) {
          if (tempTabNum[0] >= 0 && tempTabNum[0] < 28) {
            // eslint-disable-next-line prefer-destructuring
            this.tabIndex = tempTabNum[0]
            router.push({ params: { tabIndex: `tab-form-${tempTabNum[0]}` }, query: { id: this.clientId } }).catch(() => {})
          } else router.push({ params: { tabIndex: `tab-form-${0}` }, query: { id: this.clientId } }).catch(() => {})
        } else router.push({ params: { tabIndex: `tab-form-${0}` }, query: { id: this.clientId } }).catch(() => {})
      }
      if (this.userRole === 'agent' && !tempClientId) {
        router.push({ name: 'my-clients' }).catch(() => {})
      }

      if (this.userRole === 'admin' && tempClientId) {
        if (tempTabNum) {
          if (tempTabNum[0] >= 0 && tempTabNum[0] < 29) {
            // eslint-disable-next-line prefer-destructuring
            this.tabIndex = tempTabNum[0]
            router.push({ params: { tabIndex: `tab-form-${tempTabNum[0]}`, action: 'edit' }, query: { id: this.clientId } }).catch(() => {})
          } else router.push({ params: { tabIndex: `tab-form-${0}`, action: 'edit' }, query: { id: this.clientId } }).catch(() => {})
        } else router.push({ params: { tabIndex: `tab-form-${0}`, action: 'edit' }, query: { id: this.clientId } }).catch(() => {})
      }
      if (this.userRole === 'admin' && !tempClientId) {
        router.push({ name: 'clients' }).catch(() => {})
      }
    }, 100)
  },
  setup() {
    const CLIENT_ASSESSMENT_CARE_APP_STORE_MODULE_NAME = 'client_assessmentcare'

    // Register module
    if (!store.hasModule(CLIENT_ASSESSMENT_CARE_APP_STORE_MODULE_NAME)) {
      store.registerModule(CLIENT_ASSESSMENT_CARE_APP_STORE_MODULE_NAME, assessmentCareStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CLIENT_ASSESSMENT_CARE_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(CLIENT_ASSESSMENT_CARE_APP_STORE_MODULE_NAME)
      }
    })
    // const tabIndex = ref(1)
    const tabIndex = ref(store.state.client_assessmentcare.tabIndex)
    const folderNum = router.currentRoute.params.tabIndex
    const userAction = router.currentRoute.params.action

    const clientId = router.currentRoute.query.id ? router.currentRoute.query.id : ''
    const userRole = ''
    const isLoading = ref(true)

    const nextTab = () => {
      store.commit('client_assessmentcare/GO_TO_NEXT_TAB', tabIndex.value += 1)
    }

    // *===============================================---*
    // *--------- RETRIEVE DATA ---------------------------*
    // *===============================================---*
    const fetchMyData = () => {
      isLoading.value = true
      store.dispatch('client_assessmentcare/getTabDATA', clientId)
        .then(response => {
          store.dispatch('client_assessmentcare/fetchFilteredDataBckUp', response.data.response.data)
          store.dispatch('client_assessmentcare/fetchFilteredData', response.data.response.data)
          isLoading.value = false
        })
        .catch(() => {
          // console.log('some error message', er)
        })
    }
    fetchMyData()

    return {
      pageTitle: 'Care Assessment',
      tabIndex,
      folderNum,
      nextTab,
      userRole,
      clientId,
      fetchMyData,
      isLoading,
      userAction,
    }
  },
  methods: {
    selectedTab() {
      if (this.userRole === 'client') {
        return router.push({ params: { tabIndex: `tab-form-${this.tabIndex}` } }).catch(() => {})
      }
      if (this.userRole === 'agent' && this.clientId) {
        return router.push({ params: { tabIndex: `tab-form-${this.tabIndex}` }, query: { id: this.clientId } }).catch(() => {})
      }
      if (this.userRole === 'admin' && this.clientId) {
        return router.push({ params: { tabIndex: `tab-form-${this.tabIndex}`, action: 'edit' }, query: { id: this.clientId } }).catch(() => {})
      }
      return router.push({ params: { tabIndex: `tab-form-${this.tabIndex}` } }).catch(() => {})
    },
  },
}
</script>

<style scoped>

</style>
