<template>
  <div>
    <b-media vertical-align="top">
      <template #aside>
        <b-avatar
          rounded
          variant="light-secondary"
          size="50"
          style="background-color: white; box-shadow: 1px 1px 1px #aaaaaa"
        >
          <img
            src="@/assets/images/icons/assessment-care/mental-icon-default.svg"
            width="28px"
            height="28px"
          >
        </b-avatar>
      </template>
      <h4 class="media-heading">
        {{ pageTitle }}
      </h4>
      <b-card-text class="mb-0">
        List of client's mental and behavioral patterns
      </b-card-text>
    </b-media>
    <!-- form -->
    <b-card class="mt-2">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col md="12">
              <b-form-group
                label="Diagnosed Disorders/Medications"
                label-for="diagnosed-disorders-medications"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Diagnosed Disorders/Medications"
                  rules="required"
                >
                  <b-form-textarea
                    v-model="mentalbehaviorConditions.diag_disorders_medications"
                    :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                    :state="errors.length > 0 ? false:null"
                    rows="3"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Can client be left alone?"
                label-for=""
              >
                <validation-provider
                  #default="{ errors }"
                  name="Can client be left alone?"
                  rules=""
                >
                  <!-- <b-form-input
                    v-model="mentalbehaviorConditions.can_be_left_alone"
                    :state="errors.length > 0 ? false:null"
                    :disabled="localUserRole === 'client' ? true : false"
                    name="can-be-left-alone"
                  /> -->
                  <b-form-radio-group
                    v-model="mentalbehaviorConditions.can_be_left_alone"
                    name="mantConitionsRadio1"
                    class=""
                    :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                  >
                    <b-form-radio
                      v-for="option in radioOptions"
                      :key="option.text"
                      :value="option.text"
                      class="mr-3"
                      @change="getClickID1(option.value)"
                    >
                      {{ option.text }}
                    </b-form-radio>
                  </b-form-radio-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                v-if="mentalbehaviorConditions.can_be_left_alone === 'No'"
                label="Wanderer?"
                label-for=""
              >
                <validation-provider
                  #default="{ errors }"
                  name="Wanderer?"
                  rules="required"
                >
                  <!-- <b-form-input
                    v-model="mentalbehaviorConditions.wanderer"
                    :state="errors.length > 0 ? false:null"
                    :disabled="localUserRole === 'client' ? true : false"
                    name="wanderer"
                  /> -->
                  <b-form-radio-group
                    v-model="mentalbehaviorConditions.wanderer"
                    name="mantConitionsRadio2"
                    class=""
                    :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                  >
                    <b-form-radio
                      v-for="option in radioOptions"
                      :key="option.text"
                      :value="option.text"
                      class="mr-3"
                    >
                      {{ option.text }}
                    </b-form-radio>
                  </b-form-radio-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                v-if="mentalbehaviorConditions.can_be_left_alone === 'No'"
                label="Dementia?"
                label-for=""
              >
                <validation-provider
                  #default="{ errors }"
                  name="Dementia?"
                  rules="required"
                >
                  <!-- <b-form-input
                    v-model="mentalbehaviorConditions.dementia"
                    :state="errors.length > 0 ? false:null"
                    :disabled="localUserRole === 'client' ? true : false"
                    name="dementia"
                  /> -->
                  <b-form-radio-group
                    v-model="mentalbehaviorConditions.dementia"
                    name="mantConitionsRadio2"
                    class=""
                    :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                  >
                    <b-form-radio
                      v-for="option in radioOptions"
                      :key="option.text"
                      :value="option.text"
                      class="mr-3"
                    >
                      {{ option.text }}
                    </b-form-radio>
                  </b-form-radio-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Symptoms"
                label-for="symptoms"
              >
                <validation-provider
                  #default="{ errors }"
                  name="symptoms"
                  rules="required"
                >
                  <b-form-checkbox-group
                    v-model="mentalbehaviorConditions.symptoms"
                    :state="errors.length > 0 ? false:null"
                    :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                    name="symptoms-options"
                  >
                    <b-form-checkbox
                      v-for="option in symptomsOptions"
                      :key="option.id"
                      :value="option.value"
                      class="m-0 mb-1 col-sm-4"
                    >
                      {{ option.text }}
                    </b-form-checkbox>
                  </b-form-checkbox-group>
                  <small
                    v-if="localUserRole !== 'client' && localUserRole !== 'agent' "
                    class="text-danger"
                  >{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Triggers"
                label-for="triggers"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Triggers"
                  rules="required"
                >
                  <b-form-textarea
                    v-model="mentalbehaviorConditions.triggers"
                    :state="errors.length > 0 ? false:null"
                    :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                    name="triggers"
                    rows="3"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col
              v-if="localUserRole !== 'client' && localUserRole !== 'agent' "
              md="10"
            >
              <!-- Action Buttons -->
              <b-button
                variant="secondary"
                class="col-sm-3 mr-1"
                type="submit"
                @click.prevent="validationForm"
              >
                Save Changes
              </b-button>
              <b-button
                variant="outline-dark"
                class="col-sm-2 btn-reset"
                @click="onReset()"
              >
                Reset
              </b-button>
            </b-col>
            <b-col
              v-if="localUserRole === 'client' || localUserRole === 'agent'"
              class="text-right"
            >
              <b-button
                class=""
                variant="secondary"
                @click="onClickNextTab()"
              >
                <span class="align-middle">Next</span>
                <feather-icon
                  icon="ChevronRightIcon"
                  class="ml-50"
                />
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BFormRadioGroup, BFormRadio, BFormGroup, BForm, BFormTextarea, BFormCheckbox, BFormCheckboxGroup,
  BRow, BCol, BButton,
  BMedia, BCard, BCardText, BAvatar,
} from 'bootstrap-vue'
import {
  computed,
  // watch,
  ref,
} from '@vue/composition-api'
import store from '@/store'
import Swal from 'sweetalert2'
import router from '@/router'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormGroup,
    BFormRadioGroup,
    BFormRadio,
    BForm,
    BRow,
    BCol,
    BButton,
    BMedia,
    BCard,
    BCardText,
    BAvatar,
  },
  props: {
    userRole: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const pageTitle = 'Mental/Behavior Conditions'
    const localUserRole = ref(props.userRole)
    const clientId = router.currentRoute.query.id ? router.currentRoute.query.id : ''
    const mentalbehaviorConditions = ref(computed(() => store.state.client_assessmentcare.mentalbehaviorConditions))
    const mentalbehaviorConditionsBckUp = ref(computed(() => store.state.client_assessmentcare.mentalbehaviorConditionsBckUp))
    const simpleRules = ref(null)
    // options declaration with values
    const symptomsOptions = [
      { id: 1, text: 'Frequent mood changes', value: 'Frequent mood changes' },
      { id: 2, text: 'Hallucinations', value: 'Hallucinations' },
      { id: 3, text: 'Problem solving', value: 'Problem solving' },
      { id: 4, text: 'Short term memory loss', value: 'Short term memory loss' },
      { id: 5, text: 'Completing tasks', value: 'Completing tasks' },
      { id: 6, text: 'Sundowning', value: 'Sundowning' },
      { id: 7, text: 'Spatial/visual relationships', value: 'Spatial/visual relationships' },
      { id: 8, text: 'Misplacing items', value: 'Misplacing items' },
      { id: 9, text: 'Poor eating', value: 'Poor eating' },
      { id: 10, text: 'Speaking/conversing', value: 'Speaking/conversing' },
      { id: 11, text: 'Poor judgment', value: 'Poor judgment' },
      { id: 12, text: 'Sleeping problems', value: 'Sleeping problems' },
      { id: 13, text: 'Anxiety', value: 'Anxiety' },
      { id: 14, text: 'Agitation', value: 'Agitation' },
      { id: 15, text: 'Fear', value: 'Fear' },
      { id: 16, text: 'Paranoia', value: 'Paranoia' },
      { id: 17, text: 'Suspicion', value: 'Suspicion' },
      { id: 18, text: 'Aggression', value: 'Aggression' },
      { id: 19, text: 'Confusion of time/place', value: 'Confusion of time/place' },
      { id: 20, text: 'Withdrawal', value: 'Withdrawal' },
      { id: 21, text: 'Depression', value: 'Depression' },
      { id: 22, text: 'Repetition', value: 'Repetition' },
      { id: 23, text: 'Wandering', value: 'Wandering' },
    ]
    const radioOptions = [
      { id: 1, text: 'Yes', value: 'Yes' },
      { id: 2, text: 'No', value: 'No' },
    ]
    const onClickNextTab = () => {
      emit('next-tab')
    }

    // *===============================================---*
    // *--------- SAVE DATA ------------------------------*
    // *===============================================---*
    const onSaveData = () => {
      // const uData = localStorage.getItem('userData')
      // const uID = JSON.parse(uData)
      const formData = new FormData()
      const data = JSON.stringify({
        // client_id: uID.clientId,
        client_id: clientId,
        tab_name: pageTitle,
        diag_disorders_medications: mentalbehaviorConditions.value.diag_disorders_medications,
        can_be_left_alone: mentalbehaviorConditions.value.can_be_left_alone,
        wanderer: mentalbehaviorConditions.value.wanderer,
        dementia: mentalbehaviorConditions.value.dementia,
        symptoms: mentalbehaviorConditions.value.symptoms,
        triggers: mentalbehaviorConditions.value.triggers,
      })
      formData.append('data', data)
      store.dispatch('client_assessmentcare/insertData', formData)
        .then(() => {
          Swal.fire({
            icon: 'success',
            title: 'Updated Succesfully',
            showConfirmButton: false,
            timer: 1500,
            customClass: {
              confirmButton: 'btn btn-secondary',
            },
            buttonsStyling: false,
          })
          emit('fetch-my-data')
        })
        .catch(error => {
          console.error(error)
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
            showConfirmButton: false,
            timer: 1500,
          })
          // console.log('error', error)
        })
    }
    const validationForm = () => {
      simpleRules.value.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          // alert('form submitted!')
          onSaveData()
          // console.log('ref.simpleRules', simpleRules.value)
        } else {
          Swal.fire({
            icon: 'warning',
            title: 'Oops...',
            text: 'Make sure to fill up  the required fields',
            showConfirmButton: false,
            timer: 1500,
          })
        }
      })
    }
    return {
      pageTitle,
      simpleRules,
      localUserRole,
      mentalbehaviorConditions,
      mentalbehaviorConditionsBckUp,
      symptomsOptions,
      validationForm,
      onClickNextTab,
      required,
      radioOptions,
    }
  },
  methods: {
    getClickID1(val) {
      if (val === 'Yes') {
        this.mentalbehaviorConditions.dementia = ''
        this.mentalbehaviorConditions.wanderer = ''
      }
    },
    onReset() {
      const bckUpData = this.mentalbehaviorConditionsBckUp
      if (bckUpData.length !== 0) {
        store.dispatch('client_assessmentcare/filterTab16', bckUpData)
      } else {
        store.state.client_assessmentcare.mentalbehaviorConditions = {}
      }
    },
  },
}
</script>

<style>
.btn-reset:focus  {
  border: 1px solid #82868b !important;
  background-color:transparent !important;
  box-shadow: none !important;
}
.btn-reset:hover  {
  box-shadow: 0 8px 25px -8px #9c9fa0 !important;
}
</style>
