<template>
  <div>
    <b-media vertical-align="top">
      <template #aside>
        <b-avatar
          rounded
          variant="light-secondary"
          size="50"
          style="background-color: white; box-shadow: 1px 1px 1px #aaaaaa"
        >
          <img
            src="@/assets/images/icons/assessment-care/sleephabits-icon-default.svg"
            width="28px"
            height="28px"
          >
        </b-avatar>
      </template>
      <h4 class="media-heading">
        {{ pageTitle }}
      </h4>
      <b-card-text class="mb-0" />
    </b-media>
    <!-- form -->
    <b-card class="mt-2">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col md="2">
              <b-form-group
                label="Bedtime"
                label-for=""
              >
                <b-form-timepicker
                  v-model="sleepHabits.bedtime"
                  now-button
                  reset-button
                  locale="en"
                  placeholder=""
                  name="bedtime"
                  :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                />
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group
                label="Arises"
                label-for=""
              >
                <b-form-timepicker
                  v-model="sleepHabits.arises"
                  now-button
                  reset-button
                  locale="en"
                  placeholder=""
                  name="arises"
                  :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <b-form-group>
                <b-form-checkbox-group
                  v-model="sleepHabits.noc_day_opts"
                  name="nocday-options"
                  class="mt-1"
                  @change="isNocDaySelected(sleepHabits.noc_day_opts)"
                >
                  <span
                    class="row col p-0 m-0 mt-1 "
                  >
                    <b-form-checkbox
                      :value="sleepingHabitOptions[0].value"
                      :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                      class="col-sm-3 align-self-center"
                    >
                      <span>{{ sleepingHabitOptions[0].text }}</span>
                    </b-form-checkbox>
                    <validation-provider
                      #default="{ errors }"
                      name="Nocturnal"
                      :rules="sleepHabits.noc_day_opts.find(f => f === sleepingHabitOptions[0].value && f !== '' && f !== null) ? 'required' : ''"
                      class="col m-0 p-0"
                    >
                      <b-form-input
                        v-model="sleepHabits.nocturnal"
                        name="nocturnal"
                        :disabled="checkVal(0) || (localUserRole === 'client' || localUserRole === 'agent') ? true : false"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </span>
                  <span
                    class="row col p-0 m-0 mt-1 "
                  >
                    <b-form-checkbox
                      :value="sleepingHabitOptions[1].value"
                      :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                      class="col-sm-3 align-self-center"
                    >
                      <span>{{ sleepingHabitOptions[1].text }}</span>
                    </b-form-checkbox>
                    <validation-provider
                      #default="{ errors }"
                      name="Daytime"
                      :rules="sleepHabits.noc_day_opts.find(f => f === sleepingHabitOptions[1].value && f !== '' && f !== null) ? 'required' : ''"
                      class="col m-0 p-0"
                    >
                      <b-form-input
                        v-model="sleepHabits.daytime"
                        name="daytime"
                        :disabled="checkVal(1) || (localUserRole === 'client' || localUserRole === 'agent') ? true : false"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </span>
                </b-form-checkbox-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col
              v-if="localUserRole !== 'client' && localUserRole !== 'agent' "
              md="10"
            >
              <!-- Action Buttons -->
              <b-button
                variant="secondary"
                class="col-sm-2 mr-1"
                type="submit"
                @click.prevent="validationForm"
              >
                Save
              </b-button>
              <b-button
                variant="outline-dark"
                class="col-sm-2 btn-reset"
                @click="onReset()"
              >
                Reset
              </b-button>
            </b-col>
            <b-col
              v-if="localUserRole === 'client' || localUserRole === 'agent'"
              class="text-right"
            >
              <b-button
                class=""
                variant="secondary"
                @click="onClickNextTab()"
              >
                <span class="align-middle">Next</span>
                <feather-icon
                  icon="ChevronRightIcon"
                  class="ml-50"
                />
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton,
  BFormCheckbox, BFormCheckboxGroup, BMedia, BCard, BCardText, BAvatar, BFormTimepicker,
} from 'bootstrap-vue'
import {
  computed,
  // watch,
  ref,
} from '@vue/composition-api'
import store from '@/store'
import Swal from 'sweetalert2'
import router from '@/router'
// import vSelect from 'vue-select'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    // BFormTextarea,
    BFormGroup,
    BFormCheckbox,
    BFormCheckboxGroup,
    // BFormTags,
    // vSelect,
    BForm,
    BRow,
    BCol,
    BButton,
    BMedia,
    BCard,
    BCardText,
    BAvatar,
    BFormTimepicker,
  },
  props: {
    userRole: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const pageTitle = 'Sleep Habits'
    const localUserRole = ref(props.userRole)
    const clientId = router.currentRoute.query.id ? router.currentRoute.query.id : ''
    const sleepHabits = ref(computed(() => store.state.client_assessmentcare.sleepHabits))
    const sleepHabitsBckUp = ref(computed(() => store.state.client_assessmentcare.sleepHabitsBckUp))
    const simpleRules = ref(null)
    const form = ref(null)
    const row = ref(null)
    const onClickNextTab = () => {
      emit('next-tab')
    }
    const sleepingHabitOptions = [
      { id: 1, text: 'Nocturnal awakening', value: 'nocturnal_awakening' },
      { id: 2, text: 'Daytime napping', value: 'daytime_napping' },
    ]
    // *===============================================---*
    // *--------- RETRIEVE DATA ---------------------------*
    // *===============================================---*
    /* const fetchMyData = () => {
      store.dispatch('client_assessmentcare/getTabDATA2', pageTitle)
        .then(response => {
          if (response.data.response.data.length !== 0) {
            store.dispatch('client_assessmentcare/filterTab12', response.data.response.data)
          }
          // console.log('client_assessmentcare/filterTab6', response.data.response.data)
        })
        .catch(er => {
          console.log('some error message', er)
        })
    }
    fetchMyData() */

    // *===============================================---*
    // *--------- SAVE DATA ------------------------------*
    // *===============================================---*
    const onSaveData = () => {
      // const uData = localStorage.getItem('userData')
      // const uID = JSON.parse(uData)
      const formData = new FormData()
      const data = JSON.stringify({
        // client_id: uID.clientId,
        client_id: clientId,
        tab_name: pageTitle,
        bedtime: sleepHabits.value.bedtime,
        arises: sleepHabits.value.arises,
        noc_day_opts: sleepHabits.value.noc_day_opts,
        nocturnal: sleepHabits.value.nocturnal,
        daytime: sleepHabits.value.daytime,
      })
      formData.append('data', data)
      store.dispatch('client_assessmentcare/insertData', formData)
        .then(() => {
          Swal.fire({
            icon: 'success',
            title: 'Updated Succesfully',
            showConfirmButton: false,
            timer: 1500,
            customClass: {
              confirmButton: 'btn btn-secondary',
            },
            buttonsStyling: false,
          })
          emit('fetch-my-data')
        })
        .catch(error => {
          console.error(error)
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
            showConfirmButton: false,
            timer: 1500,
          })
          // console.log('error', error)
        })
    }
    const validationForm = () => {
      simpleRules.value.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          // alert('form submitted!')
          onSaveData()
          // console.log('ref.simpleRules', simpleRules.value)
        } else {
          Swal.fire({
            icon: 'warning',
            title: 'Oops...',
            text: 'Make sure to fill up  the required fields',
            showConfirmButton: false,
            timer: 1500,
          })
        }
      })
    }
    return {
      pageTitle,
      sleepHabits,
      sleepHabitsBckUp,
      sleepingHabitOptions,
      localUserRole,
      onClickNextTab,
      validationForm,
      // refs
      simpleRules,
      form,
      row,
      required,
    }
  },
  methods: {
    checkVal(ind) {
      if (this.sleepHabits.noc_day_opts.length > 0) {
        // eslint-disable-next-line
        return !this.sleepHabits.noc_day_opts.find(f => f === this.sleepingHabitOptions[ind].value) ? true : false
      }
      return true
    },
    isNocDaySelected(val) {
      const op1 = val.find(f => f === this.sleepingHabitOptions[0].value)
      const op2 = val.find(f => f === this.sleepingHabitOptions[1].value)
      // console.log(op1, op2)
      if (op1 !== this.sleepingHabitOptions[0].value) {
        this.sleepHabits.nocturnal = ''
      }
      if (op2 !== this.sleepingHabitOptions[1].value) {
        this.sleepHabits.daytime = ''
      }
    },
    onReset() {
      const bckUpData = this.sleepHabitsBckUp
      if (bckUpData.length !== 0) {
        store.dispatch('client_assessmentcare/filterTab12', bckUpData)
      } else {
        store.state.client_assessmentcare.sleepHabits = {
          bedtime: '',
          arises: '',
          noc_day_opts: [],
          nocturnal: '',
          daytime: '',
        }
      }
    },
  },
}
</script>

<style>
.btn-reset:focus  {
  border: 1px solid #82868b !important;
  background-color:transparent !important;
  box-shadow: none !important;
}
.btn-reset:hover  {
  box-shadow: 0 8px 25px -8px #9c9fa0 !important;
}
</style>
