<!-- eslint-disable -->
<template>
  <div>
    <b-media vertical-align="top">
      <template #aside>
        <b-avatar
          rounded
          variant="light-secondary"
          size="50"
          style="background-color: white; box-shadow: 1px 1px 1px #aaaaaa"
        >
          <img
            src="@/assets/images/icons/assessment-care/dressings-icon-default.svg"
            width="28px"
            height="28px"
          >
        </b-avatar>
      </template>
      <h4 class="media-heading">
        {{ pageTitle }}
      </h4>
      <b-card-text class="mb-0">
        Whether a client needs an assistance in dressing themselves.
      </b-card-text>
    </b-media>
    <!-- form -->
    <b-card class="mt-2">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col md="12">
              <b-form-radio-group
                v-model="dressings.dressings"
                name="can_dress"
                class=""
              >
                <b-form-radio
                  v-for="option in radioYN"
                  :key="option.text"
                  :value="option.value"
                  :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                  class="mr-4"
                >
                  {{ option.text }}
                </b-form-radio>
              </b-form-radio-group>
            </b-col>
          </b-row>
          <b-row class="mt-4">
            <b-col
              v-if="localUserRole !== 'client' && localUserRole !== 'agent' "
              md="10"
            >
              <!-- Action Buttons -->
              <b-button
                variant="secondary"
                class="col-sm-2 mr-1"
                type="submit"
                @click.prevent="validationForm"
              >
                Save
              </b-button>
              <b-button
                variant="outline-dark"
                class="col-sm-2 btn-reset"
                @click="onReset()"
              >
                Reset
              </b-button>
            </b-col>
            <b-col
              v-if="localUserRole === 'client' || localUserRole === 'agent'"
              class="text-right"
            >
              <b-button
                class=""
                variant="secondary"
                @click="onClickNextTab()"
              >
                <span class="align-middle">Next</span>
                <feather-icon
                  icon="ChevronRightIcon"
                  class="ml-50"
                />
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable */
import {
  BFormGroup, BForm, BRow, BCol, BButton, BFormRadioGroup, BFormRadio,
  BMedia, BCard, BCardText, BAvatar,
} from 'bootstrap-vue'
import {
  computed,
  // watch,
  ref,
} from '@vue/composition-api'
import store from '@/store'
import Swal from 'sweetalert2'
import router from '@/router'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    BFormGroup,
    BForm,
    BFormRadioGroup,
    BFormRadio,
    BButton,
    BRow,
    BCol,
    BMedia, 
    BCard, 
    BCardText,
    BAvatar,
  },
  props: {
    userRole: {
      type: String,
      required: true,
    },
  },
  methods: {
    onReset() {
      const bckUpData = this.dressingsBckUp
      if (bckUpData.length !== 0) {
        store.dispatch('client_assessmentcare/filterTab8', bckUpData)
      } else {
        store.state.client_assessmentcare.dressings = {}
      }
    },
  },
  setup(props, { emit }) {
    const pageTitle = 'Dressings'
    const localUserRole = ref(props.userRole)
    const clientId = router.currentRoute.query.id ? router.currentRoute.query.id : ''
    const dressings = ref(computed(() => store.state.client_assessmentcare.dressings))
    const dressingsBckUp = ref(computed(() => store.state.client_assessmentcare.dressingsBckUp))
    const simpleRules = ref(null)
    const onClickNextTab = () => {
      emit('next-tab')
    }
    const radioYN = [
      { id: 1, text: 'Able to dress self', value: 'able_to_dress' },
      { id: 2, text: 'Needs Assistance', value: 'needs_assistance' },
    ]

    // *===============================================---*
    // *--------- SAVE DATA ------------------------------*
    // *===============================================---*
    const onSaveData = () => {
      // const uData = localStorage.getItem('userData')
      // const uID = JSON.parse(uData)
      // console.log('user data', )
      const formData = new FormData()
      const data = JSON.stringify({
        // client_id: uID.clientId,
        client_id: clientId,
        tab_name: pageTitle,
        dressings: dressings.value.dressings,
      })
      formData.append('data', data)
      for (var value of formData.values()) {
        // console.log(value);
      }
      store.dispatch('client_assessmentcare/insertData', formData)
        .then(() => {
          Swal.fire({
            icon: 'success',
            title: 'Updated Succesfully',
            showConfirmButton: false,
            timer: 1500,
            customClass: {
              confirmButton: 'btn btn-secondary',
            },
            buttonsStyling: false,
          })
          emit('fetch-my-data')
        })
        .catch(error => {
          console.error(error)
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
            showConfirmButton: false,
            timer: 1500,
          })
          // console.log('error', error)
        })
    }
    const validationForm = () => {
      simpleRules.value.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          // alert('form submitted!')
          onSaveData()
          // console.log('ref.simpleRules', simpleRules.value)
        } else {
          Swal.fire({
            icon: 'warning',
            title: 'Oops...',
            text: 'Make sure to fill up  the required fields',
            showConfirmButton: false,
            timer: 1500,
          })
        }
      })
    }
    return {
      pageTitle,
      simpleRules,
      localUserRole,
      dressings,
      dressingsBckUp,
      validationForm,
      onClickNextTab,
      radioYN,
      required,
    }
  },
}
</script>

<style>
.btn-reset:focus  {
  border: 1px solid #82868b !important;
  background-color:transparent !important;
  box-shadow: none !important;
}
.btn-reset:hover  {
  box-shadow: 0 8px 25px -8px #9c9fa0 !important;
}
</style>
