var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-media',{attrs:{"vertical-align":"top"},scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{staticStyle:{"background-color":"white","box-shadow":"1px 1px 1px #aaaaaa"},attrs:{"rounded":"","variant":"light-secondary","size":"50"}},[_c('img',{attrs:{"src":require("@/assets/images/icons/assessment-care/ambulation-icon-default.svg"),"width":"28px","height":"28px"}})])]},proxy:true}])},[_c('h4',{staticClass:"media-heading"},[_vm._v(" "+_vm._s(_vm.pageTitle)+" ")]),_c('b-card-text',{staticClass:"mb-0"},[_vm._v(" Client's ability to walk from place to place independently, with or without assistive devices. ")])],1),_c('b-card',{staticClass:"mt-2"},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Aids","label-for":"aids"}},[_c('validation-provider',{attrs:{"name":"Aids","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox-group',{attrs:{"state":errors.length > 0 ? false : null,"name":"Aids Options","stacked":"","disabled":_vm.localUserRole === 'client' || _vm.localUserRole === 'agent' ? true : false},model:{value:(_vm.ambulation.aids),callback:function ($$v) {_vm.$set(_vm.ambulation, "aids", $$v)},expression:"ambulation.aids"}},_vm._l((_vm.aidsOptions),function(option){return _c('b-form-checkbox',{key:option.id,staticClass:"mb-1",attrs:{"value":option.value}},[_vm._v(" "+_vm._s(option.text)+" ")])}),1),(_vm.localUserRole !== 'client' && _vm.localUserRole !== 'agent' )?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Fall risk","label-for":"fall-risk"}},[_c('validation-provider',{attrs:{"name":"Fall risk","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox-group',{attrs:{"state":errors.length > 0 ? false : null,"name":"Fall risks Options","stacked":"","disabled":_vm.localUserRole === 'client' || _vm.localUserRole === 'agent' ? true : false},model:{value:(_vm.ambulation.fall_risk),callback:function ($$v) {_vm.$set(_vm.ambulation, "fall_risk", $$v)},expression:"ambulation.fall_risk"}},_vm._l((_vm.fallRiskOptions),function(option){return _c('b-form-checkbox',{key:option.id,staticClass:"mb-1",attrs:{"value":option.value}},[_vm._v(" "+_vm._s(option.text)+" ")])}),1),(_vm.localUserRole !== 'client' && _vm.localUserRole !== 'agent' )?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Use of Arms/Hands","label-for":"use-of-arms/hands"}},[_c('validation-provider',{attrs:{"name":"Use of Arms/Hands","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox-group',{attrs:{"state":errors.length > 0 ? false : null,"name":"Use of Arms/Hands Options","stacked":"","disabled":_vm.localUserRole === 'client' || _vm.localUserRole === 'agent' ? true : false},model:{value:(_vm.ambulation.use_of_arm_hands),callback:function ($$v) {_vm.$set(_vm.ambulation, "use_of_arm_hands", $$v)},expression:"ambulation.use_of_arm_hands"}},_vm._l((_vm.useArmHandsOptions),function(option){return _c('b-form-checkbox',{key:option.id,staticClass:"mb-1",attrs:{"value":option.value}},[_vm._v(" "+_vm._s(option.text)+" ")])}),1),(_vm.localUserRole !== 'client' && _vm.localUserRole !== 'agent' )?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Notes","label-for":"notes"}},[_c('validation-provider',{attrs:{"name":"Notes","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"state":errors.length > 0 ? false:null,"name":"notes-ambulation","disabled":_vm.localUserRole === 'client' || _vm.localUserRole === 'agent' ? true : false,"rows":"3"},model:{value:(_vm.ambulation.notes),callback:function ($$v) {_vm.$set(_vm.ambulation, "notes", $$v)},expression:"ambulation.notes"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',{staticClass:"mt-2"},[(_vm.localUserRole !== 'client' && _vm.localUserRole !== 'agent' )?_c('b-col',{attrs:{"md":"10"}},[_c('b-button',{staticClass:"col-sm-3 mr-1",attrs:{"variant":"secondary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" Save Changes ")]),_c('b-button',{staticClass:"col-sm-2 btn-reset",attrs:{"variant":"outline-dark"},on:{"click":function($event){return _vm.onReset()}}},[_vm._v(" Reset ")])],1):_vm._e(),(_vm.localUserRole === 'client' || _vm.localUserRole === 'agent')?_c('b-col',{staticClass:"text-right"},[_c('b-button',{attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.onClickNextTab()}}},[_c('span',{staticClass:"align-middle"},[_vm._v("Next")]),_c('feather-icon',{staticClass:"ml-50",attrs:{"icon":"ChevronRightIcon"}})],1)],1):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }