<!-- eslint-disable -->
<template>
  <div>
    <b-media vertical-align="top">
      <template #aside>
        <b-avatar
          rounded
          variant="light-secondary"
          size="50"
          style="background-color: white; box-shadow: 1px 1px 1px #aaaaaa"
        >
          <img
            src="@/assets/images/icons/assessment-care/bathing-icon-default.svg"
            width="28px"
            height="28px"
          >
        </b-avatar>
      </template>
      <h4 class="media-heading">
        {{ pageTitle }}
      </h4>
      <b-card-text class="mb-0">
        Whether a client needs an assistance in bathing and other specifications.
      </b-card-text>
    </b-media>
    <!-- form -->
    <b-card class="mt-2">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col md="12">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="bathing"
                  rules="required"
                >
                  <b-form-checkbox-group
                    v-model="bathing.bathing_opts"
                    name="bathing-options"
                    class="mt-1"
                    :state="errors.length > 0 ? false:null"
                    :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                    @change="isBathingSelected(bathing.bathing_opts)"
                  >
                    <span
                      class="row col p-0 m-0 "
                    >
                      <b-form-checkbox
                        :value="bathingOptions[0].value"
                        class="col-sm-3 align-self-center"
                        name="bathing-field"
                      >
                        <span>{{ bathingOptions[0].text }}</span>
                      </b-form-checkbox>
                      <validation-provider
                        #default="{ errors }"
                        name="No assistance necessary"
                        :rules="bathing.bathing_opts.find(f => f === bathingOptions[0].value) ? 'required' : ''"
                        class="col m-0 p-0"
                      >
                        <b-form-input
                          v-model="bathing.no_assistance_necessary"
                          name="no_assistance_necessary"
                          :disabled="!bathing.bathing_opts.find(f => f === bathingOptions[0].value) || (bathing.bathing_opts.find(f => f === bathingOptions[0].value) && (localUserRole === 'client' || localUserRole === 'agent' )) ? true : false"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </span>
                    <span
                      class="row col p-0 m-0 mt-1 "
                    >
                      <b-form-checkbox
                        :value="bathingOptions[1].value"
                        class="col-sm-3 align-self-center"
                      >
                        <span>{{ bathingOptions[1].text }}</span>
                      </b-form-checkbox>
                      <validation-provider
                        #default="{ errors }"
                        name="Family will bathe"
                        :rules="bathing.bathing_opts.find(f => f === bathingOptions[1].value) ? 'required' : ''"
                        class="col m-0 p-0"
                      >
                        <b-form-input
                          v-model="bathing.family_will_bathe"
                          name="family_will_bathe"
                          :disabled="!bathing.bathing_opts.find(f => f === bathingOptions[1].value) || (bathing.bathing_opts.find(f => f === bathingOptions[1].value) && (localUserRole === 'client' || localUserRole === 'agent' )) ? true : false"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </span>
                    <span
                      class="row col p-0 m-0 mt-1 "
                    >
                      <b-form-checkbox
                        :value="bathingOptions[2].value"
                        class="col-sm-3 align-self-center"
                      >
                        <span>{{ bathingOptions[2].text }}</span>
                      </b-form-checkbox>
                      <validation-provider
                        #default="{ errors }"
                        name="Bathing assistance"
                        :rules="bathing.bathing_opts.find(f => f === bathingOptions[2].value) ? 'required' : ''"
                        class="col m-0 p-0"
                      >
                        <b-form-input
                          v-model="bathing.bathing_assistance"
                          name="bathing_assistance"
                          :disabled="!bathing.bathing_opts.find(f => f === bathingOptions[2].value) || (bathing.bathing_opts.find(f => f === bathingOptions[2].value) && (localUserRole === 'client' || localUserRole === 'agent' )) ? true : false"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </span>
                    <span
                      class="row col p-0 m-0 mt-1 "
                    >
                      <b-form-checkbox
                        :value="bathingOptions[3].value"
                        class="col-sm-3 align-self-center"
                      >
                        <span>{{ bathingOptions[3].text }}</span>
                      </b-form-checkbox>
                      <validation-provider
                        #default="{ errors }"
                        name="Monitoring only"
                        :rules="bathing.bathing_opts.find(f => f === bathingOptions[3].value) ? 'required' : ''"
                        class="col m-0 p-0"
                      >
                        <b-form-input
                          v-model="bathing.monitoring_only"
                          name="monitoring_only"
                          :disabled="!bathing.bathing_opts.find(f => f === bathingOptions[3].value) || (bathing.bathing_opts.find(f => f === bathingOptions[3].value) && (localUserRole === 'client' || localUserRole === 'agent' )) ? true : false"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </span>
                    <span
                      class="row col p-0 m-0 mt-1 "
                    >
                      <b-form-checkbox
                        :value="bathingOptions[4].value"
                        class="col-sm-3 align-self-center"
                      >
                        <span>{{ bathingOptions[4].text }}</span>
                      </b-form-checkbox>
                      <validation-provider
                        #default="{ errors }"
                        name="Compliant"
                        :rules="bathing.bathing_opts.find(f => f === bathingOptions[4].value) ? 'required' : ''"
                        class="col m-0 p-0"
                      >
                        <b-form-input
                          v-model="bathing.compliant"
                          name="compliant"
                          :disabled="!bathing.bathing_opts.find(f => f === bathingOptions[4].value) || (bathing.bathing_opts.find(f => f === bathingOptions[4].value) && (localUserRole === 'client' || localUserRole === 'agent' )) ? true : false"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </span>
                    <span
                      class="row col p-0 m-0 mt-1 "
                    >
                      <b-form-checkbox
                        :value="bathingOptions[5].value"
                        name="selected"
                        class="col-sm-3 align-self-center"
                      >
                        <span>{{ bathingOptions[5].text }}</span>
                      </b-form-checkbox>
                      <validation-provider
                        #default="{ errors }"
                        name="Non-compliant"
                        :rules="bathing.bathing_opts.find(f => f === bathingOptions[5].value) ? 'required' : ''"
                        class="col m-0 p-0"
                      >
                        <b-form-input
                          v-model="bathing.non_compliant"
                          name="non_compliant"
                          :disabled="!bathing.bathing_opts.find(f => f === bathingOptions[5].value) || (bathing.bathing_opts.find(f => f === bathingOptions[5].value) && (localUserRole === 'client' || localUserRole === 'agent' )) ? true : false"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </span>
                  </b-form-checkbox-group>
                  <small v-if="localUserRole !== 'client' && localUserRole !== 'agent' " class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-4">
            <b-col
              v-if="localUserRole !== 'client' && localUserRole !== 'agent' "
              md="10"
            >
              <!-- Action Buttons -->
              <b-button
                variant="secondary"
                class="col-sm-2 mr-1"
                type="submit"
                @click.prevent="validationForm"
              >
                Save
              </b-button>
              <b-button
                variant="outline-dark"
                class="col-sm-2 btn-reset"
                @click="onReset()"
              >
                Reset
              </b-button>
            </b-col>
            <b-col
              v-if="localUserRole === 'client' || localUserRole === 'agent'"
              class="text-right"
            >
              <b-button
                class=""
                variant="secondary"
                @click="onClickNextTab()"
              >
                <span class="align-middle">Next</span>
                <feather-icon
                  icon="ChevronRightIcon"
                  class="ml-50"
                />
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable */
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton,
  BFormTextarea, BFormCheckbox, BFormCheckboxGroup, BFormTags,
  BMedia, BCard, BCardText, BAvatar,
} from 'bootstrap-vue'
import {
  computed,
  // watch,
  ref,
} from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import Swal from 'sweetalert2'
// import vSelect from 'vue-select'
import { required, minLength } from '@validations'
import { localize, ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    localize,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormTags,
    // vSelect,
    BForm,
    BRow,
    BCol,
    BButton,
    BMedia,
    BCard,
    BCardText,
    BAvatar,
  },
  props: {
    userRole: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const pageTitle = 'Bathing'
    const localUserRole = ref(props.userRole)
    const bathing = ref(computed(() => store.state.client_assessmentcare.bathing))
    const bathingBckUp = ref(computed(() => store.state.client_assessmentcare.bathingBckUp))
    const clientId = router.currentRoute.query.id ? router.currentRoute.query.id : ''
    const simpleRules = ref(null)
    const form = ref(null)
    const row = ref(null)
    const bathingOptions = [
      { id: 1, text: 'No assistance necessary', value: 'no_assistance_necessary' },
      { id: 2, text: 'Family will bathe', value: 'family_will_bathe' },
      { id: 3, text: 'Bathing assistance', value: 'bathing_assistance' },
      { id: 4, text: 'Monitoring only', value: 'monitoring_only' },
      { id: 5, text: 'Compliant', value: 'compliant' },
      { id: 6, text: 'Non-compliant', value: 'non_compliant' },
    ]
    const onClickNextTab = () => {
      emit('next-tab')
    }
    
    localize({
      en: {
        fields: {
          bathing: {
            required: 'Atleast one item must be checked',
          }
        }
      }
    })

    // *===============================================---*
    // *--------- SAVE DATA ------------------------------*
    // *===============================================---*
    const onSaveData = () => {
      // const uData = localStorage.getItem('userData')
      // const uID = JSON.parse(uData)
      const formData = new FormData()
      const data = JSON.stringify({
        // client_id: uID.clientId,
        client_id: clientId,
        tab_name: pageTitle,
        bathing_opts: bathing.value.bathing_opts,
        no_assistance_necessary: bathing.value.no_assistance_necessary,
        family_will_bathe: bathing.value.family_will_bathe,
        bathing_assistance: bathing.value.bathing_assistance,
        monitoring_only: bathing.value.monitoring_only,
        compliant: bathing.value.compliant,
        non_compliant: bathing.value.non_compliant,
      })
      formData.append('data', data)
      /* for (const value of formData.values()) {
        console.log(value)
      } */
      store.dispatch('client_assessmentcare/insertData', formData)
        .then(() => {
          Swal.fire({
            icon: 'success',
            title: 'Updated Succesfully',
            showConfirmButton: false,
            timer: 1500,
            customClass: {
              confirmButton: 'btn btn-secondary',
            },
            buttonsStyling: false,
          })
          emit('fetch-my-data')
        })
        .catch(error => {
          console.error(error)
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
            showConfirmButton: false,
            timer: 1500,
          })
          // console.log('error', error)
        })
    }
    const validationForm = () => {
      simpleRules.value.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          // alert('form submitted!')
          onSaveData()
          // console.log('ref.simpleRules', simpleRules.value)
        } else {
          Swal.fire({
            icon: 'warning',
            title: 'Oops...',
            text: 'Make sure to fill up  the required fields',
            showConfirmButton: false,
            timer: 1500,
          })
        }
      })
    }
    return {
      pageTitle,
      bathing,
      bathingBckUp,
      bathingOptions,
      localUserRole,
      onClickNextTab,
      validationForm,
      // refs
      simpleRules,
      form,
      row,
      required,
      minLength,
    }
  },
  methods: {
    isBathingSelected(val) {
      const op1 = val.find(f => f === this.bathingOptions[0].value)
      const op2 = val.find(f => f === this.bathingOptions[1].value)
      const op3 = val.find(f => f === this.bathingOptions[2].value)
      const op4 = val.find(f => f === this.bathingOptions[3].value)
      const op5 = val.find(f => f === this.bathingOptions[4].value)
      const op6 = val.find(f => f === this.bathingOptions[5].value)
      
      if (op1 !== this.bathingOptions[0].value) {
        this.bathing.no_assistance_necessary = ''
      }
      if (op2 !== this.bathingOptions[1].value) {
        this.bathing.family_will_bathe = ''
      }
      if (op3 !== this.bathingOptions[2].value) {
        this.bathing.bathing_assistance = ''
      }
      if (op4 !== this.bathingOptions[3].value) {
        this.bathing.monitoring_only = ''
      }
      if (op5 !== this.bathingOptions[4].value) {
        this.bathing.compliant = ''
      }
      if (op6 !== this.bathingOptions[5].value) {
        this.bathing.non_compliant = ''
      }
    },
    onReset() {
      const bckUpData = this.bathingBckUp
      if (bckUpData.length !== 0) {
        store.dispatch('client_assessmentcare/filterTab9', bckUpData)
      } else {
        store.state.client_assessmentcare.bathing = {}
      }
    },
  },
}
</script>

<style>
.btn-reset:focus  {
  border: 1px solid #82868b !important;
  background-color:transparent !important;
  box-shadow: none !important;
}
.btn-reset:hover  {
  box-shadow: 0 8px 25px -8px #9c9fa0 !important;
}
</style>
