var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-media',{attrs:{"vertical-align":"top"},scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{staticStyle:{"background-color":"white","box-shadow":"1px 1px 1px #aaaaaa"},attrs:{"rounded":"","variant":"light-secondary","size":"50"}},[_c('img',{attrs:{"src":require("@/assets/images/icons/assessment-care/meals-icon-default.svg"),"width":"28px","height":"28px"}})])]},proxy:true}])},[_c('h4',{staticClass:"media-heading"},[_vm._v(" "+_vm._s(_vm.pageTitle)+" ")]),_c('b-card-text',{staticClass:"mb-0"},[_vm._v(" Client daily diet, favorite dishes and food items to look out for. ")])],1),_c('b-card',{staticClass:"mt-2"},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Specify dietary concerns","label-for":""}},[_c('validation-provider',{attrs:{"name":"Dietary concerns","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"state":errors.length > 0 ? false:null,"disabled":_vm.localUserRole === 'client' || _vm.localUserRole === 'agent' ? true : false,"rows":"3"},model:{value:(_vm.meals.dietary_concers),callback:function ($$v) {_vm.$set(_vm.meals, "dietary_concers", $$v)},expression:"meals.dietary_concers"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Typically eats:","label-for":""}},[_c('b-form-checkbox-group',{staticClass:"mt-1",attrs:{"name":"Typically eats","disabled":_vm.localUserRole === 'client' || _vm.localUserRole === 'agent' ? true : false},on:{"change":function($event){return _vm.isTypicallyEats(_vm.meals.typically_eats)}},model:{value:(_vm.meals.typically_eats),callback:function ($$v) {_vm.$set(_vm.meals, "typically_eats", $$v)},expression:"meals.typically_eats"}},[_c('span',{staticClass:"row col p-0 m-0 "},[_c('b-form-checkbox',{staticClass:"col-sm-3",attrs:{"value":_vm.eatTypeOptions[0].value}},[_c('span',[_vm._v(_vm._s(_vm.eatTypeOptions[0].text))])]),_c('validation-provider',{staticClass:"col m-0 p-0",attrs:{"name":"Breakfast","rules":_vm.meals.typically_eats.find(function (f) { return f === _vm.eatTypeOptions[0].value; }) ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":!_vm.meals.typically_eats.find(function (f) { return f === _vm.eatTypeOptions[0].value; }) || (_vm.localUserRole === 'client' || _vm.localUserRole === 'agent') ? true : false,"name":"breakfast","state":errors.length > 0 ? false : null,"size":"sm"},model:{value:(_vm.meals.typically_eats_breakfast),callback:function ($$v) {_vm.$set(_vm.meals, "typically_eats_breakfast", $$v)},expression:"meals.typically_eats_breakfast"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('span',{staticClass:"row col p-0 m-0 mt-1 "},[_c('b-form-checkbox',{staticClass:"col-sm-3",attrs:{"value":_vm.eatTypeOptions[1].value}},[_c('span',[_vm._v(_vm._s(_vm.eatTypeOptions[1].text))])]),_c('validation-provider',{staticClass:"col m-0 p-0",attrs:{"name":"Lunch","rules":_vm.meals.typically_eats.find(function (f) { return f === _vm.eatTypeOptions[1].value; }) ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":!_vm.meals.typically_eats.find(function (f) { return f === _vm.eatTypeOptions[1].value; }) || (_vm.localUserRole === 'client' || _vm.localUserRole === 'agent') ? true : false,"name":"lunch","state":errors.length > 0 ? false : null,"size":"sm"},model:{value:(_vm.meals.typically_eats_lunch),callback:function ($$v) {_vm.$set(_vm.meals, "typically_eats_lunch", $$v)},expression:"meals.typically_eats_lunch"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('span',{staticClass:"row col p-0 m-0 mt-1 "},[_c('b-form-checkbox',{staticClass:"col-sm-3",attrs:{"value":_vm.eatTypeOptions[2].value}},[_c('span',[_vm._v(_vm._s(_vm.eatTypeOptions[2].text))])]),_c('validation-provider',{staticClass:"col m-0 p-0",attrs:{"name":"Dinner","rules":_vm.meals.typically_eats.find(function (f) { return f === _vm.eatTypeOptions[2].value; }) ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":!_vm.meals.typically_eats.find(function (f) { return f === _vm.eatTypeOptions[2].value; }) || (_vm.localUserRole === 'client' || _vm.localUserRole === 'agent') ? true : false,"name":"dinner","state":errors.length > 0 ? false : null,"size":"sm"},model:{value:(_vm.meals.typically_eats_dinner),callback:function ($$v) {_vm.$set(_vm.meals, "typically_eats_dinner", $$v)},expression:"meals.typically_eats_dinner"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('span',{staticClass:"row col p-0 m-0 mt-1 "},[_c('b-form-checkbox',{staticClass:"col-sm-3",attrs:{"value":_vm.eatTypeOptions[3].value}},[_c('span',[_vm._v(_vm._s(_vm.eatTypeOptions[3].text))])]),_c('validation-provider',{staticClass:"col m-0 p-0",attrs:{"name":"Snacks","rules":_vm.meals.typically_eats.find(function (f) { return f === _vm.eatTypeOptions[3].value; }) ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":!_vm.meals.typically_eats.find(function (f) { return f === _vm.eatTypeOptions[3].value; }) || (_vm.localUserRole === 'client' || _vm.localUserRole === 'agent') ? true : false,"name":"snacks","state":errors.length > 0 ? false : null,"size":"sm"},model:{value:(_vm.meals.typically_eats_snacks),callback:function ($$v) {_vm.$set(_vm.meals, "typically_eats_snacks", $$v)},expression:"meals.typically_eats_snacks"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Who cooks?","label-for":"tags-remove-on-delete"}},[_c('b-form-tags',{staticClass:"mb-2",attrs:{"separator":"","placeholder":"","add-on-change":"","remove-on-delete":"","tag-remove-label":"Remove tag","disabled":_vm.localUserRole === 'client' || _vm.localUserRole === 'agent' ? true : false},model:{value:(_vm.meals.person_cooks),callback:function ($$v) {_vm.$set(_vm.meals, "person_cooks", $$v)},expression:"meals.person_cooks"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Other food providers","label-for":"other-food-providers"}},[_c('b-form-tags',{staticClass:"mb-2",attrs:{"separator":"","placeholder":"","add-on-change":"","remove-on-delete":"","disabled":_vm.localUserRole === 'client' || _vm.localUserRole === 'agent' ? true : false},model:{value:(_vm.meals.other_food_provider),callback:function ($$v) {_vm.$set(_vm.meals, "other_food_provider", $$v)},expression:"meals.other_food_provider"}})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Favorite foods","label-for":""}},[_c('validation-provider',{attrs:{"name":"favorite-foods","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"state":errors.length > 0 ? false:null,"disabled":_vm.localUserRole === 'client' || _vm.localUserRole === 'agent' ? true : false,"rows":"3"},model:{value:(_vm.meals.fav_food),callback:function ($$v) {_vm.$set(_vm.meals, "fav_food", $$v)},expression:"meals.fav_food"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',{staticClass:"mt-2"},[(_vm.localUserRole !== 'client' && _vm.localUserRole !== 'agent')?_c('b-col',{attrs:{"md":"10"}},[_c('b-button',{staticClass:"col-sm-3 mr-1",attrs:{"variant":"secondary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" Save Changes ")]),_c('b-button',{staticClass:"col-sm-2 btn-reset",attrs:{"variant":"outline-dark"},on:{"click":function($event){return _vm.onReset()}}},[_vm._v(" Reset ")])],1):_vm._e(),(_vm.localUserRole === 'client' || _vm.localUserRole === 'agent')?_c('b-col',{staticClass:"text-right"},[_c('b-button',{attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.onClickNextTab()}}},[_c('span',{staticClass:"align-middle"},[_vm._v("Next")]),_c('feather-icon',{staticClass:"ml-50",attrs:{"icon":"ChevronRightIcon"}})],1)],1):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }