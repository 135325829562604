<template>
  <div>
    <b-media vertical-align="top">
      <template #aside>
        <b-avatar
          rounded
          variant="light-secondary"
          size="50"
          style="background-color: white; box-shadow: 1px 1px 1px #aaaaaa"
        >
          <img
            src="@/assets/images/icons/assessment-care/determination-icon-default.svg"
            width="28px"
            height="28px"
          >
        </b-avatar>
      </template>
      <h4 class="media-heading">
        {{ pageTitle }}
      </h4>
      <b-card-text class="mb-0" />
    </b-media>
    <!-- form -->
    <b-card class="mt-2">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row class="mt-1">
            <b-col md="12">
              <b-form-group
                label=""
                label-for=""
              >
                <validation-provider
                  #default="{ errors }"
                  name="determination"
                  rules="required"
                >
                  <b-form-radio-group
                    v-model="determination.determinationYN"
                    name="determination-opt"
                  >
                    <b-form-radio
                      v-for="option in radioOptions"
                      :key="option.text"
                      :value="option.text"
                      class="mr-3"
                    >
                      {{ option.text }}
                    </b-form-radio>
                  </b-form-radio-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col
              md="10"
            >
              <!-- Action Buttons -->
              <b-button
                variant="secondary"
                class="col-sm-3 mr-1"
                type="submit"
                @click.prevent="validationForm"
              >
                Save Changes
              </b-button>
              <b-button
                variant="outline-dark"
                class="col-sm-2 btn-reset"
                @click="onReset()"
              >
                Reset
              </b-button>
            </b-col>
            <!-- <b-col
              md="2"
              class=""
            >
              <b-button
                class=""
                variant="secondary"
                @click="onClickNextTab()"
              >
                <span class="align-middle">Next</span>
                <feather-icon
                  icon="ChevronRightIcon"
                  class="ml-50"
                />
              </b-button>
            </b-col> -->
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BFormRadioGroup, BFormRadio, BFormGroup, BForm, BRow, BCol, BButton,
  BMedia, BCard, BCardText, BAvatar,
} from 'bootstrap-vue'
import { mask } from 'vue-the-mask'
import {
  computed,
  // watch,
  ref,
} from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import Swal from 'sweetalert2'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormRadioGroup,
    BFormRadio,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BMedia,
    BCard,
    BCardText,
    BAvatar,
  },
  directives: {
    mask,
  },
  props: {
    userRole: {
      type: String,
      required: true,
    },
  },
  methods: {
    onReset() {
      const bckUpData = this.determinationBckUp
      if (bckUpData.length !== 0) {
        store.dispatch('client_assessmentcare/filterTab30', bckUpData)
        // console.log('bckUpData', bckUpData)
      } else {
        store.state.client_assessmentcare.determination = {}
      }
    },
  },
  setup(props, { emit }) {
    const pageTitle = 'Determination'
    const simpleRules = ref(null)
    const localUserRole = ref(props.userRole)
    const determination = ref(computed(() => store.state.client_assessmentcare.determination))
    const determinationBckUp = ref(computed(() => store.state.client_assessmentcare.determinationBckUp))
    const clientId = router.currentRoute.query.id ? router.currentRoute.query.id : ''
    const radioOptions = [
      { id: 1, text: 'Client is appropriate for home care', value: 'Client is appropriate for home care' },
      { id: 2, text: 'Client is not appropriate for home care', value: 'Client is not appropriate for home care' },
    ]
    const onClickNextTab = () => {
      emit('next-tab')
    }

    // *===============================================---*
    // *--------- SAVE DATA FUNCTION ---------------------*
    // *===============================================---*
    const onSaveData = () => {
      const formData = new FormData()
      const data = JSON.stringify({
        client_id: clientId,
        tab_name: pageTitle,
        determinationYN: determination.value.determinationYN,
      })
      formData.append('data', data)
      // console.log('formData', data)
      store.dispatch('client_assessmentcare/insertData', formData)
        .then(() => {
          Swal.fire({
            icon: 'success',
            title: 'Updated Succesfully',
            showConfirmButton: false,
            timer: 1500,
            customClass: {
              confirmButton: 'btn btn-secondary',
            },
            buttonsStyling: false,
          })
          emit('fetch-my-data')
        })
        .catch(error => {
          console.error(error)
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
            showConfirmButton: false,
            timer: 1500,
          })
          // console.log('error', error)
        })
    }

    // *===============================================---*
    // *--------- VALIDATE THEN SAVE DATA ----------------*
    // *===============================================---*
    const validationForm = () => {
      simpleRules.value.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          // alert('form submitted!')
          onSaveData()
          // console.log('ref.simpleRules', simpleRules.value)
        } else {
          Swal.fire({
            icon: 'warning',
            title: 'Oops...',
            text: 'Make sure to fill up  the required fields',
            showConfirmButton: false,
            timer: 1500,
          })
        }
      })
    }
    return {
      pageTitle,
      simpleRules,
      localUserRole,
      determination,
      determinationBckUp,
      clientId,
      radioOptions,
      validationForm,
      onClickNextTab,
      required,
    }
  },
}
</script>

<style>
.btn-reset:focus  {
  border: 1px solid #82868b !important;
  background-color:transparent !important;
  box-shadow: none !important;
}
.btn-reset:hover  {
  box-shadow: 0 8px 25px -8px #9c9fa0 !important;
}
</style>
