<template>
  <div>
    <b-media vertical-align="top">
      <template #aside>
        <b-avatar
          rounded
          variant="light-secondary"
          size="50"
          style="background-color: white; box-shadow: 1px 1px 1px #aaaaaa"
        >
          <img
            src="@/assets/images/icons/assessment-care/coverage-icon-default.svg"
            width="28px"
            height="28px"
          >
        </b-avatar>
      </template>
      <h4 class="media-heading">
        {{ pageTitle }}
      </h4>
      <b-card-text class="mb-0" />
    </b-media>
    <!-- form -->
    <b-card class="mt-2">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col md="4">
              <b-form-group
                label="Long-Term Care Coverage"
                label-for="Long-Term Care Coverage"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Long-Term Care Coverage"
                  rules="required"
                >
                  <b-form-radio-group
                    v-model="longTermCareCoverage.long_term_coverage"
                    name="long-term-care-coverage-opt"
                    :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                  >
                    <b-form-radio
                      v-for="option in radioOptions"
                      :key="option.text"
                      :value="option.text"
                      class="mr-3"
                    >
                      {{ option.text }}
                    </b-form-radio>
                  </b-form-radio-group>
                  <small
                    v-if="localUserRole !== 'client' && localUserRole !== 'agent' && (isFormSubmitted && !longTermCareCoverage.long_term_coverage)"
                    class="text-danger"
                  >{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Company"
                label-for="company-field"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Company"
                  rules="required"
                >
                  <b-form-input
                    v-model="longTermCareCoverage.company"
                    :state="errors.length > 0 ? false:null"
                    name="company-field"
                    :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Phone"
                label-for="phone-field"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Phone"
                  rules="required"
                >
                  <b-form-input
                    v-model="longTermCareCoverage.phone"
                    v-mask="['(###) ###-####']"
                    :state="errors.length > 0 ? false:null"
                    name="phone-field"
                    :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Policy#"
                label-for="policy-field"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Policy#"
                  rules="required"
                >
                  <b-form-input
                    v-model="longTermCareCoverage.policy"
                    :state="errors.length > 0 ? false:null"
                    name="policy-field"
                    :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="% covered"
                label-for="covered-field"
              >
                <validation-provider
                  #default="{ errors }"
                  name="covered"
                  rules="required|between:0,100"
                >
                  <b-form-input
                    v-model="longTermCareCoverage.covered"
                    :state="errors.length > 0 ? false:null"
                    name="covered-field"
                    :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="% not covered"
                label-for="not-covered-field"
              >
                <validation-provider
                  #default="{ errors }"
                  name="not covered"
                  rules="required|between:0,100"
                >
                  <b-form-input
                    v-model="longTermCareCoverage.not_covered"
                    :state="errors.length > 0 ? false:null"
                    name="not-covered-field"
                    :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col
              v-if="localUserRole !== 'client' && localUserRole !== 'agent' "
              md="10"
            >
              <!-- Action Buttons -->
              <b-button
                variant="secondary"
                class="col-sm-3 mr-1"
                type="submit"
                @click.prevent="validationForm"
              >
                Save Changes
              </b-button>
              <b-button
                variant="outline-dark"
                class="col-sm-2 btn-reset"
                @click="onReset()"
              >
                Reset
              </b-button>
            </b-col>
            <!-- <b-col
              v-if="localUserRole === 'client' || localUserRole === 'agent'"
              class="text-right"
            >
              <b-button
                class=""
                variant="secondary"
                @click="onClickNextTab()"
              >
                <span class="align-middle">Next</span>
                <feather-icon
                  icon="ChevronRightIcon"
                  class="ml-50"
                />
              </b-button>
            </b-col> -->
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BFormInput, BFormGroup, BForm, BFormRadioGroup, BFormRadio, BRow, BCol, BButton,
  BMedia, BCard, BCardText, BAvatar,
} from 'bootstrap-vue'
import { mask } from 'vue-the-mask'
import {
  computed,
  // watch,
  ref,
} from '@vue/composition-api'
import store from '@/store'
import Swal from 'sweetalert2'
import router from '@/router'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BFormRadioGroup,
    BFormRadio,
    BRow,
    BCol,
    BButton,
    BMedia,
    BCard,
    BCardText,
    BAvatar,
  },
  directives: {
    mask,
  },
  props: {
    userRole: {
      type: String,
      required: true,
    },
  },
  methods: {
    onReset() {
      const bckUpData = this.longTermCareCoverageBckUp
      if (bckUpData.length !== 0) {
        store.dispatch('client_assessmentcare/filterTab29', bckUpData)
        // console.log('bckUpData', bckUpData)
      } else {
        store.state.client_assessmentcare.longTermCareCoverage = {}
      }
    },
  },
  setup(props, { emit }) {
    const pageTitle = 'Long-Term Care Coverage'
    const simpleRules = ref(null)
    const isFormSubmitted = ref(false)
    const localUserRole = ref(props.userRole)
    const clientId = router.currentRoute.query.id ? router.currentRoute.query.id : ''
    const longTermCareCoverage = ref(computed(() => store.state.client_assessmentcare.longTermCareCoverage))
    const longTermCareCoverageBckUp = ref(computed(() => store.state.client_assessmentcare.longTermCareCoverageBckUp))
    const radioOptions = [
      { id: 1, text: 'Yes', value: 'Yes' },
      { id: 2, text: 'No', value: 'No' },
    ]

    // *===============================================---*
    // *--------- SAVE DATA FUNCTION ---------------------*
    // *===============================================---*
    const onSaveData = () => {
      // const uData = localStorage.getItem('userData')
      // const uID = JSON.parse(uData)
      const formData = new FormData()
      const data = JSON.stringify({
        // client_id: uID.clientId,
        client_id: clientId,
        tab_name: pageTitle,
        long_term_coverage: longTermCareCoverage.value.long_term_coverage,
        company: longTermCareCoverage.value.company,
        phone: longTermCareCoverage.value.phone,
        policy: longTermCareCoverage.value.policy,
        covered: longTermCareCoverage.value.covered,
        not_covered: longTermCareCoverage.value.not_covered,
      })
      formData.append('data', data)
      // console.log('formData', data)
      store.dispatch('client_assessmentcare/insertData', formData)
        .then(() => {
          Swal.fire({
            icon: 'success',
            title: 'Updated Succesfully',
            showConfirmButton: false,
            timer: 1500,
            customClass: {
              confirmButton: 'btn btn-secondary',
            },
            buttonsStyling: false,
          })
          emit('fetch-my-data')
          isFormSubmitted.value = false
        })
        .catch(error => {
          console.error(error)
          isFormSubmitted.value = false
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
            showConfirmButton: false,
            timer: 1500,
          })
          // console.log('error', error)
        })
    }

    // *===============================================---*
    // *--------- Go To next ----------*
    // *===============================================---*
    const onClickNextTab = () => {
      emit('next-tab')
    }

    // *===============================================---*
    // *--------- VALIDATE THEN SAVE DATA ----------------*
    // *===============================================---*
    const validationForm = () => {
      isFormSubmitted.value = true
      simpleRules.value.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          // alert('form submitted!')
          onSaveData()
          // console.log('ref.simpleRules', simpleRules.value)
        } else {
          Swal.fire({
            icon: 'warning',
            title: 'Oops...',
            text: 'Make sure to fill up  the required fields',
            showConfirmButton: false,
            timer: 1500,
          })
        }
      })
    }
    return {
      pageTitle,
      simpleRules,
      localUserRole,
      longTermCareCoverage,
      longTermCareCoverageBckUp,
      radioOptions,
      validationForm,
      onClickNextTab,
      required,
      isFormSubmitted,
    }
  },
}
</script>

<style>
.btn-reset:focus  {
  border: 1px solid #82868b !important;
  background-color:transparent !important;
  box-shadow: none !important;
}
.btn-reset:hover  {
  box-shadow: 0 8px 25px -8px #9c9fa0 !important;
}
</style>
