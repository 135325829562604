<template>
  <div>
    <b-media vertical-align="top">
      <template #aside>
        <b-avatar
          rounded
          variant="light-secondary"
          size="50"
          style="background-color: white; box-shadow: 1px 1px 1px #aaaaaa"
        >
          <img
            src="@/assets/images/icons/assessment-care/meals-icon-default.svg"
            width="28px"
            height="28px"
          >
        </b-avatar>
      </template>
      <h4 class="media-heading">
        {{ pageTitle }}
      </h4>
      <b-card-text class="mb-0">
        Client daily diet, favorite dishes and food items to look out for.
      </b-card-text>
    </b-media>
    <!-- form -->
    <b-card class="mt-2">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col md="12">
              <b-form-group
                label="Specify dietary concerns"
                label-for=""
              >
                <validation-provider
                  #default="{ errors }"
                  name="Dietary concerns"
                  rules="required"
                >
                  <b-form-textarea
                    v-model="meals.dietary_concers"
                    :state="errors.length > 0 ? false:null"
                    :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                    rows="3"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Typically eats:"
                label-for=""
              >
                <b-form-checkbox-group
                  v-model="meals.typically_eats"
                  name="Typically eats"
                  class="mt-1"
                  :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                  @change="isTypicallyEats(meals.typically_eats)"
                >
                  <span
                    class="row col p-0 m-0 "
                  >
                    <b-form-checkbox
                      :value="eatTypeOptions[0].value"
                      class="col-sm-3"
                    >
                      <span>{{ eatTypeOptions[0].text }}</span>
                    </b-form-checkbox>
                    <validation-provider
                      #default="{ errors }"
                      name="Breakfast"
                      :rules="meals.typically_eats.find(f => f === eatTypeOptions[0].value) ? 'required' : ''"
                      class="col m-0 p-0"
                    >
                      <b-form-input
                        v-model="meals.typically_eats_breakfast"
                        :disabled="!meals.typically_eats.find(f => f === eatTypeOptions[0].value) || (localUserRole === 'client' || localUserRole === 'agent') ? true : false"
                        name="breakfast"
                        :state="errors.length > 0 ? false : null"
                        size="sm"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </span>
                  <span
                    class="row col p-0 m-0 mt-1 "
                  >
                    <b-form-checkbox
                      :value="eatTypeOptions[1].value"
                      class="col-sm-3"
                    >
                      <span>{{ eatTypeOptions[1].text }}</span>
                    </b-form-checkbox>
                    <validation-provider
                      #default="{ errors }"
                      name="Lunch"
                      :rules="meals.typically_eats.find(f => f === eatTypeOptions[1].value) ? 'required' : ''"
                      class="col m-0 p-0"
                    >
                      <b-form-input
                        v-model="meals.typically_eats_lunch"
                        :disabled="!meals.typically_eats.find(f => f === eatTypeOptions[1].value) || (localUserRole === 'client' || localUserRole === 'agent') ? true : false"
                        name="lunch"
                        :state="errors.length > 0 ? false : null"
                        size="sm"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </span>
                  <span
                    class="row col p-0 m-0 mt-1 "
                  >
                    <b-form-checkbox
                      :value="eatTypeOptions[2].value"
                      class="col-sm-3"
                    >
                      <span>{{ eatTypeOptions[2].text }}</span>
                    </b-form-checkbox>
                    <validation-provider
                      #default="{ errors }"
                      name="Dinner"
                      :rules="meals.typically_eats.find(f => f === eatTypeOptions[2].value) ? 'required' : ''"
                      class="col m-0 p-0"
                    >
                      <b-form-input
                        v-model="meals.typically_eats_dinner"
                        :disabled="!meals.typically_eats.find(f => f === eatTypeOptions[2].value) || (localUserRole === 'client' || localUserRole === 'agent') ? true : false"
                        name="dinner"
                        :state="errors.length > 0 ? false : null"
                        size="sm"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </span>
                  <span
                    class="row col p-0 m-0 mt-1 "
                  >
                    <b-form-checkbox
                      :value="eatTypeOptions[3].value"
                      class="col-sm-3"
                    >
                      <span>{{ eatTypeOptions[3].text }}</span>
                    </b-form-checkbox>
                    <validation-provider
                      #default="{ errors }"
                      name="Snacks"
                      :rules="meals.typically_eats.find(f => f === eatTypeOptions[3].value) ? 'required' : ''"
                      class="col m-0 p-0"
                    >
                      <b-form-input
                        v-model="meals.typically_eats_snacks"
                        :disabled="!meals.typically_eats.find(f => f === eatTypeOptions[3].value) || (localUserRole === 'client' || localUserRole === 'agent') ? true : false"
                        name="snacks"
                        :state="errors.length > 0 ? false : null"
                        size="sm"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </span>
                </b-form-checkbox-group>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Who cooks?"
                label-for="tags-remove-on-delete"
              >
                <b-form-tags
                  v-model="meals.person_cooks"
                  separator=""
                  placeholder=""
                  add-on-change
                  remove-on-delete
                  tag-remove-label="Remove tag"
                  :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                  class="mb-2"
                />
                <!-- <v-select
                  v-model="meals.person_cooks"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  multiple
                  taggable
                  push-tags
                  placeholder="Select or Type Hair Color"
                /> -->
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Other food providers"
                label-for="other-food-providers"
              >
                <b-form-tags
                  v-model="meals.other_food_provider"
                  separator=""
                  placeholder=""
                  add-on-change
                  remove-on-delete
                  :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                  class="mb-2"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Favorite foods"
                label-for=""
              >
                <validation-provider
                  #default="{ errors }"
                  name="favorite-foods"
                  rules="required"
                >
                  <b-form-textarea
                    v-model="meals.fav_food"
                    :state="errors.length > 0 ? false:null"
                    :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                    rows="3"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col
              v-if="localUserRole !== 'client' && localUserRole !== 'agent'"
              md="10"
            >
              <!-- Action Buttons -->
              <b-button
                variant="secondary"
                class="col-sm-3 mr-1"
                type="submit"
                @click.prevent="validationForm"
              >
                Save Changes
              </b-button>
              <b-button
                variant="outline-dark"
                class="col-sm-2 btn-reset"
                @click="onReset()"
              >
                Reset
              </b-button>
            </b-col>
            <b-col
              v-if="localUserRole === 'client' || localUserRole === 'agent'"
              class="text-right"
            >
              <b-button
                class=""
                variant="secondary"
                @click="onClickNextTab()"
              >
                <span class="align-middle">Next</span>
                <feather-icon
                  icon="ChevronRightIcon"
                  class="ml-50"
                />
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton,
  BFormTextarea, BFormCheckbox, BFormCheckboxGroup, BFormTags,
  BMedia, BCard, BCardText, BAvatar,
} from 'bootstrap-vue'
import {
  computed,
  // watch,
  ref,
} from '@vue/composition-api'
import store from '@/store'
import Swal from 'sweetalert2'
import router from '@/router'
// import vSelect from 'vue-select'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormTags,
    // vSelect,
    BForm,
    BRow,
    BCol,
    BButton,
    BMedia,
    BCard,
    BCardText,
    BAvatar,
  },
  props: {
    userRole: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const pageTitle = 'Meals'
    const localUserRole = ref(props.userRole)
    const meals = ref(computed(() => store.state.client_assessmentcare.meals))
    const mealsBckUp = ref(computed(() => store.state.client_assessmentcare.mealsBckUp))
    const clientId = router.currentRoute.query.id ? router.currentRoute.query.id : ''
    const simpleRules = ref(null)
    const form = ref(null)
    const row = ref(null)
    const eatTypeOptions = [
      { id: 1, text: 'Breakfast', value: 'Breakfast' },
      { id: 2, text: 'Lunch', value: 'Lunch' },
      { id: 3, text: 'Dinner', value: 'Dinner' },
      { id: 4, text: 'Snacks', value: 'Snacks' },
    ]
    const onClickNextTab = () => {
      emit('next-tab')
    }

    // *===============================================---*
    // *--------- SAVE DATA ------------------------------*
    // *===============================================---*
    const onSaveData = () => {
      // const uData = localStorage.getItem('userData')
      // const uID = JSON.parse(uData)
      const formData = new FormData()
      const data = JSON.stringify({
        // client_id: uID.clientId,
        client_id: clientId,
        tab_name: pageTitle,
        dietary_concers: meals.value.dietary_concers,
        typically_eats: meals.value.typically_eats,
        typically_eats_breakfast: meals.value.typically_eats_breakfast,
        typically_eats_lunch: meals.value.typically_eats_lunch,
        typically_eats_dinner: meals.value.typically_eats_dinner,
        typically_eats_snacks: meals.value.typically_eats_snacks,
        person_cooks: meals.value.person_cooks.length > 0 ? meals.value.person_cooks : [],
        other_food_provider: meals.value.other_food_provider.length > 0 ? meals.value.other_food_provider : [''],
        fav_food: meals.value.fav_food,
      })
      formData.append('data', data)
      store.dispatch('client_assessmentcare/insertData', formData)
        .then(() => {
          Swal.fire({
            icon: 'success',
            title: 'Updated Succesfully',
            showConfirmButton: false,
            timer: 1500,
            customClass: {
              confirmButton: 'btn btn-secondary',
            },
            buttonsStyling: false,
          })
          emit('fetch-my-data')
        })
        .catch(error => {
          console.error(error)
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
            showConfirmButton: false,
            timer: 1500,
          })
          // console.log('error', error)
        })
    }
    const validationForm = () => {
      simpleRules.value.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          // alert('form submitted!')
          onSaveData()
          // console.log('ref.simpleRules', simpleRules.value)
        } else {
          Swal.fire({
            icon: 'warning',
            title: 'Oops...',
            text: 'Make sure to fill up  the required fields',
            showConfirmButton: false,
            timer: 1500,
          })
        }
      })
    }
    // const onClear = () => {
    //   store.state.client_assessmentcare.meals = {
    //     dietary_concers: '',
    //     typically_eats: [],
    //     typically_eats_breakfast: '',
    //     typically_eats_lunch: '',
    //     typically_eats_dinner: '',
    //     typically_eats_snacks: '',
    //     person_cooks: [],
    //     other_food_provider: [],
    //     fav_food: '',
    //   }
    // }
    return {
      pageTitle,
      localUserRole,
      meals,
      mealsBckUp,
      eatTypeOptions,
      onClickNextTab,
      // onClear,
      validationForm,
      // refs
      simpleRules,
      form,
      row,
      required,
    }
  },
  methods: {
    isTypicallyEats(val) {
      const op1 = val.find(f => f === this.eatTypeOptions[0].value)
      const op2 = val.find(f => f === this.eatTypeOptions[1].value)
      const op3 = val.find(f => f === this.eatTypeOptions[2].value)
      const op4 = val.find(f => f === this.eatTypeOptions[3].value)
      if (op1 !== this.eatTypeOptions[0].value) {
        this.meals.typically_eats_breakfast = ''
      }
      if (op2 !== this.eatTypeOptions[1].value) {
        this.meals.typically_eats_lunch = ''
      }
      if (op3 !== this.eatTypeOptions[2].value) {
        this.meals.typically_eats_dinner = ''
      }
      if (op4 !== this.eatTypeOptions[3].value) {
        this.meals.typically_eats_snacks = ''
      }
    },
    onReset() {
      const bckUpData = this.mealsBckUp
      if (bckUpData.length !== 0) {
        store.dispatch('client_assessmentcare/filterTab7', bckUpData)
      } else {
        store.state.client_assessmentcare.meals = {
          dietary_concers: '',
          typically_eats: [],
          typically_eats_breakfast: '',
          typically_eats_lunch: '',
          typically_eats_dinner: '',
          typically_eats_snacks: '',
          person_cooks: [],
          other_food_provider: [],
          fav_food: '',
        }
      }
    },
  },
}
</script>

<style>
.btn-reset:focus  {
  border: 1px solid #82868b !important;
  background-color:transparent !important;
  box-shadow: none !important;
}
.btn-reset:hover  {
  box-shadow: 0 8px 25px -8px #9c9fa0 !important;
}
[dir] .pb-0, [dir] .py-0 {
  padding-bottom: unset !important;
}
</style>
