<!-- eslint-disable -->
<template>
  <div class="careneeds-wrapper">
    <b-media vertical-align="top">
      <template #aside>
        <b-avatar
          rounded
          variant="light-secondary"
          size="50"
          style="background: #FFFFFF"
        >
          <img
            src="@/assets/images/icons/assessment-care/careneed-icon-default.svg"
            width="28px"
            height="28px"
          >
        </b-avatar>
      </template>
      <h4 class="media-heading">
        {{ pageTitle }}
      </h4>
      <b-card-text class="mb-0">
        Below are the proposed Goal and schedule based on the initial client review.
      </b-card-text>
    </b-media>
    <!-- form -->
    <b-card class="mt-2">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Initial Contact"
                label-for="initial-contact"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Initial Contact"
                  rules="required"
                >
                  <flat-pickr
                    v-model="careNeeds.initial_contact"
                    id="initial-contact"
                    :config="{ altInput: true, altFormat: 'F j, Y', dateFormat: 'Y-m-d',}"
                    :state="errors.length > 0 ? false : null"
                    :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                  />
                  <small 
                    v-if="localUserRole !== 'client' && localUserRole !== 'agent' "
                    class="text-danger"
                  >{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Start Date"
                rules="required"
              >
                <b-form-group
                  label="Start Date"
                  label-for="start-date"
                >
                  <flat-pickr
                    v-model="careNeeds.start_date"
                    id="date-start"
                    :config="{ altInput: true, altFormat: 'F j, Y', dateFormat: 'Y-m-d',}"
                    :state="errors.length > 0 ? false : null"
                    :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                  />
                  <small
                    v-if="localUserRole !== 'client' && localUserRole !== 'agent'"
                    class="text-danger"
                  >{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12">
              <b-form-group>
                <h6>Care Goals</h6>
                <validation-provider
                  #default="{ errors }"
                  name="Care Goals"
                  rules="required"
                >
                  <b-form-textarea
                    :state="errors.length > 0 ? false : null"
                    :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                    value="Assist in improving/maintaining client's function and live with great comfort and care by promoting Client's optimal level of well-being and assisting the client to remain at home, avoiding hospitalization or admission to long-term care institutions. Below"
                    no-resize
                    rows="3"
                    readonly
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <hr>
          <b-row>
            <b-col
              cols="12"
              class="mt-2 mb-1"
            >
              <b-media vertical-align="top">
                <template #aside>
                  <feather-icon
                    icon="CalendarIcon"
                    size="24"
                  />
                </template>
                <h4 class="section-title">
                  Proposed Schedule
                </h4>
              </b-media>
            </b-col>
            <b-col
              cols="12"
              class="mb-1"
            >
              <b-row class="p-0 m-0">
                <validation-provider
                  #default="{ errors }"
                  name="Shift Type"
                  :rules="localUserRole !== 'client' && localUserRole !== 'agent' ? 'isFalse|required' : ''"
                  class="col-sm-7 p-0"
                >
                  <b-form-group
                    label="Shift Type"
                    label-for="shift-type"
                  >
                    <b-row
                      class="m-0 py-0"
                      style="margin-top: 0.8rem !important;"
                    >
                      <b-form-checkbox
                        v-model="careNeeds.shift_type"
                        :value="shiftTypeOptions[0].value"
                        class="mr-4"
                        :state="errors.length > 0 ? false : null"
                        :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                        @change="isOtherSelected(careNeeds.shift_type)"
                      >
                        {{ shiftTypeOptions[0].text }}
                      </b-form-checkbox>
                      <b-form-checkbox
                        v-model="careNeeds.shift_type"
                        :value="shiftTypeOptions[1].value"
                        class="mr-4"
                        :state="errors.length > 0 ? false : null"
                        :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                        @change="isOtherSelected(careNeeds.shift_type)"
                      >
                        {{ shiftTypeOptions[1].text }}
                      </b-form-checkbox>
                      <b-form-checkbox
                        v-model="careNeeds.shift_type"
                        :value="shiftTypeOptions[2].value"
                        class="mr-4"
                        :state="errors.length > 0 ? false : null"
                        :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                        @change="isOtherSelected(careNeeds.shift_type)"
                      >
                        {{ shiftTypeOptions[2].text }}
                      </b-form-checkbox>
                      <b-form-checkbox
                        v-model="careNeeds.shift_type"
                        :value="shiftTypeOptions[3].value"
                        class="mr-2"
                        :state="errors.length > 0 ? false : null"
                        :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                        @change="isOtherSelected(careNeeds.shift_type)"
                      >
                        {{ shiftTypeOptions[3].text }}
                      </b-form-checkbox>
                    </b-row>
                  </b-form-group>
                  <small
                    class="text-danger"
                  >{{ errors[0] }}</small>
                </validation-provider>
                <validation-provider
                  #default="{ errors }"
                  name="Shift Type Options Input"
                  :rules="careNeeds.shift_type === shiftTypeOptions[3].value ? 'required' : ''"
                  class="col m-0 p-0"
                >
                  <b-form-input
                    v-model="careNeeds.shift_type_input"
                    :disabled="careNeeds.shift_type !== shiftTypeOptions[3].value || (localUserRole === 'client' || localUserRole === 'agent') ? true : false"
                    :state="errors.length > 0 ? false : null"
                    name="Shift Type Options Input"
                    class="mt-1"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-row>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Select days and select hours"
                label-for="select-day-hours"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Select day Hours"
                  rules="required"
                  fluid="sm"
                >
                  <b-form-checkbox-group
                    v-model="careNeeds.selected_days"
                    :state="errors.length > 0 ? false : null"
                    :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                    name="day hour"
                    class=""
                  >
                    <!-- Sunday -->
                    <b-col
                      md="7"
                      class="py-0 px-1 mt-1"
                    >
                      <b-row class="m-0 d-flex justify-content-between align-items-center mb-1">
                        <b-form-checkbox
                          :value="selectDaysTimeOptions[0].value"
                          @change="isSelectedHours(careNeeds.selected_days)"
                        >
                          <span>{{ selectDaysTimeOptions[0].text }}</span>
                        </b-form-checkbox>
                        <div
                          v-if="localUserRole !== 'client' && localUserRole !== 'agent' ? true : false"
                          style="margin-right: 20px"
                        >
                          <feather-icon
                            v-if="careNeeds.sun_selected_hours.length !== 5 ? true : false"
                            size="20"
                            icon="PlusIcon"
                            class="cursor-pointer"
                            @click="addNewTimeSun"
                          />
                        </div>
                      </b-row>
                      <b-row class="m-0">
                        <!-- Items Section -->
                        <div
                          ref="sunForm"
                          class="repeater-form h-auto w-100"
                          :style="{height: trHeight}"
                        >
                          <b-row
                            v-for="(item, index) in careNeeds.sun_selected_hours"
                            :key="index"
                            ref="sunRow"
                            class="pb-1 m-0 p-0"
                          >
                            <!-- Item Form -->
                            <b-col
                              cols="12"
                              class="p-0"
                            >
                              <!-- Form Input Fields OR content inside bordered area  -->
                              <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                              <div class="border rounded">
                                <b-row class="p-1 m-0">
                                  <b-col
                                    md="5"
                                    class="p-0"
                                  >
                                    <validation-provider
                                      #default="{ errors }"
                                      name="sun start time"
                                      :rules="careNeeds.selected_days.find(f => f === selectDaysTimeOptions[0].value) ? 'required' : ''"
                                      fluid="sm"
                                    >
                                      <b-form-timepicker
                                        v-model="item.start_time"
                                        :disabled="!careNeeds.selected_days.find(f => f === selectDaysTimeOptions[0].text) || (localUserRole === 'client' || localUserRole === 'agent') ? true : false"
                                        now-button
                                        reset-button
                                        locale="en"
                                        placeholder=""
                                        class="h-auto"
                                        :state="errors.length > 0 ? false : null"
                                      />
                                      <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                  </b-col>
                                  <b-col
                                    md="1"
                                    class="p-0 my-auto"
                                  >
                                    <div class="mx-1">
                                      -
                                    </div>
                                  </b-col>
                                  <b-col
                                    sm="5"
                                    class="p-0"
                                  >
                                    <validation-provider
                                      #default="{ errors }"
                                      name="sun end time"
                                      :rules="careNeeds.selected_days.find(f => f === selectDaysTimeOptions[0].value) ? 'required' : ''"
                                      fluid="sm"
                                    >
                                      <b-form-timepicker
                                        v-model="item.end_time"
                                        :disabled="!careNeeds.selected_days.find(f => f === selectDaysTimeOptions[0].text) || (localUserRole === 'client' || localUserRole === 'agent') ? true : false"
                                        now-button
                                        reset-button
                                        locale="en"
                                        placeholder=""
                                        :state="errors.length > 0 ? false : null"
                                      />
                                      <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                  </b-col>
                                  <b-col
                                    md="1"
                                    class="p-0 my-auto"
                                  >
                                    <div
                                      v-if="localUserRole !== 'client' && localUserRole !== 'agent' ? true : false"
                                      style="margin-left: 10px"
                                    >
                                      <feather-icon
                                        v-if="careNeeds.sun_selected_hours.length !== 1 ? true : false"
                                        size="20"
                                        icon="Trash2Icon"
                                        class="cursor-pointer"
                                        @click="removeTimeSun(index)"
                                      />
                                    </div>
                                  </b-col>
                                </b-row>
                              </div>
                            </b-col>
                          </b-row>
                        </div>
                      </b-row>
                    </b-col>
                    <!-- Monday -->
                    <b-col
                      md="7"
                      class="py-0 px-1 mt-1"
                    >
                      <b-row class="m-0 d-flex justify-content-between align-items-center mb-1">
                        <b-form-checkbox
                          :value="selectDaysTimeOptions[1].value"
                          @change="isSelectedHours(careNeeds.selected_days)"
                        >
                          <span>{{ selectDaysTimeOptions[1].text }}</span>
                        </b-form-checkbox>
                        <div
                          v-if="localUserRole !== 'client' && localUserRole !== 'agent' ? true : false"
                          style="margin-right: 20px"
                        >
                          <feather-icon
                            v-if="careNeeds.mon_selected_hours.length !== 5 ? true : false"
                            size="20"
                            icon="PlusIcon"
                            class="cursor-pointer"
                            @click="addNewTimeMon"
                          />
                        </div>
                      </b-row>
                      <b-row class="m-0">
                        <!-- Items Section -->
                        <div
                          ref="monForm"
                          class="repeater-form h-auto w-100"
                          :style="{height: trHeight}"
                        >
                          <b-row
                            v-for="(mon, index) in careNeeds.mon_selected_hours"
                            :key="index"
                            ref="monRow"
                            class="pb-1 m-0 p-0"
                          >
                            <!-- Item Form -->
                            <b-col
                              cols="12"
                              class="p-0"
                            >
                              <!-- Form Input Fields OR content inside bordered area  -->
                              <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                              <div class="border rounded">
                                <b-row class="p-1 m-0">
                                  <b-col
                                    md="5"
                                    class="p-0"
                                  >
                                    <validation-provider
                                      #default="{ errors }"
                                      name="mon start time"
                                      :rules="careNeeds.selected_days.find(f => f === selectDaysTimeOptions[1].value) ? 'required' : ''"
                                      fluid="sm"
                                    >
                                      <b-form-timepicker
                                        v-model="mon.start_time"
                                        :disabled="!careNeeds.selected_days.find(f => f === selectDaysTimeOptions[1].text) || (localUserRole === 'client' || localUserRole === 'agent') ? true : false"
                                        now-button
                                        reset-button
                                        locale="en"
                                        placeholder=""
                                        class="h-auto"
                                        :state="errors.length > 0 ? false : null"
                                      />
                                      <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                  </b-col>
                                  <b-col
                                    md="1"
                                    class="p-0 my-auto"
                                  >
                                    <div class="mx-1">
                                      -
                                    </div>
                                  </b-col>
                                  <b-col
                                    sm="5"
                                    class="p-0"
                                  >
                                    <validation-provider
                                      #default="{ errors }"
                                      name="mon end time"
                                      :rules="careNeeds.selected_days.find(f => f === selectDaysTimeOptions[1].value) ? 'required' : ''"
                                      fluid="sm"
                                    >
                                      <b-form-timepicker
                                        v-model="mon.end_time"
                                        :disabled="!careNeeds.selected_days.find(f => f === selectDaysTimeOptions[1].text) || (localUserRole === 'client' || localUserRole === 'agent') ? true : false"
                                        now-button
                                        reset-button
                                        locale="en"
                                        placeholder=""
                                        :state="errors.length > 0 ? false : null"
                                      />
                                      <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                  </b-col>
                                  <b-col
                                    md="1"
                                    class="p-0 my-auto"
                                  >
                                    <div
                                      v-if="localUserRole !== 'client' && localUserRole !== 'agent' ? true : false"
                                      style="margin-left: 10px"
                                    >
                                      <feather-icon
                                        v-if="careNeeds.mon_selected_hours.length !== 1 ? true : false"
                                        size="20"
                                        icon="Trash2Icon"
                                        class="cursor-pointer"
                                        @click="removeTimeMon(index)"
                                      />
                                    </div>
                                  </b-col>
                                </b-row>
                              </div>
                            </b-col>
                          </b-row>
                        </div>
                      </b-row>
                    </b-col>
                    <!-- Tuesday -->
                    <b-col
                      md="7"
                      class="py-0 px-1 mt-1"
                    >
                      <b-row class="m-0 d-flex justify-content-between align-items-center mb-1">
                        <b-form-checkbox
                          :value="selectDaysTimeOptions[2].value"
                          @change="isSelectedHours(careNeeds.selected_days)"
                        >
                          <span>{{ selectDaysTimeOptions[2].text }}</span>
                        </b-form-checkbox>
                        <div
                          v-if="localUserRole !== 'client' && localUserRole !== 'agent' ? true : false"
                          style="margin-right: 20px"
                        >
                          <feather-icon
                            v-if="careNeeds.tue_selected_hours.length !== 5 ? true : false"
                            size="20"
                            icon="PlusIcon"
                            class="cursor-pointer"
                            @click="addNewTimeTue"
                          />
                        </div>
                      </b-row>
                      <b-row class="m-0">
                        <!-- Items Section -->
                        <div
                          ref="tueForm"
                          class="repeater-form h-auto w-100"
                          :style="{height: trHeight}"
                        >
                          <b-row
                            v-for="(item, index) in careNeeds.tue_selected_hours"
                            :key="index"
                            ref="tueRow"
                            class="pb-1 m-0 p-0"
                          >
                            <!-- Item Form -->
                            <b-col
                              cols="12"
                              class="p-0"
                            >
                              <!-- Form Input Fields OR content inside bordered area  -->
                              <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                              <div class="border rounded">
                                <b-row class="p-1 m-0">
                                  <b-col
                                    md="5"
                                    class="p-0"
                                  >
                                    <validation-provider
                                      #default="{ errors }"
                                      name="tue start time"
                                      :rules="careNeeds.selected_days.find(f => f === selectDaysTimeOptions[2].value) ? 'required' : ''"
                                      fluid="sm"
                                    >
                                      <b-form-timepicker
                                        v-model="item.start_time"
                                        :disabled="!careNeeds.selected_days.find(f => f === selectDaysTimeOptions[2].text) || (localUserRole === 'client' || localUserRole === 'agent') ? true : false"
                                        now-button
                                        reset-button
                                        locale="en"
                                        placeholder=""
                                        class="h-auto"
                                        :state="errors.length > 0 ? false : null"
                                      />
                                      <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                  </b-col>
                                  <b-col
                                    md="1"
                                    class="p-0 my-auto"
                                  >
                                    <div class="mx-1">
                                      -
                                    </div>
                                  </b-col>
                                  <b-col
                                    sm="5"
                                    class="p-0"
                                  >
                                    <validation-provider
                                      #default="{ errors }"
                                      name="tue end time"
                                      :rules="careNeeds.selected_days.find(f => f === selectDaysTimeOptions[2].value) ? 'required' : ''"
                                      fluid="sm"
                                    >
                                      <b-form-timepicker
                                        v-model="item.end_time"
                                        :disabled="!careNeeds.selected_days.find(f => f === selectDaysTimeOptions[2].text) || (localUserRole === 'client' || localUserRole === 'agent') ? true : false"
                                        now-button
                                        reset-button
                                        locale="en"
                                        placeholder=""
                                        :state="errors.length > 0 ? false : null"
                                      />
                                      <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                  </b-col>
                                  <b-col
                                    md="1"
                                    class="p-0 my-auto"
                                  >
                                    <div
                                      v-if="localUserRole !== 'client' && localUserRole !== 'agent' ? true : false"
                                      style="margin-left: 10px"
                                    >
                                      <feather-icon
                                        v-if="careNeeds.tue_selected_hours.length !== 1 ? true : false"
                                        size="20"
                                        icon="Trash2Icon"
                                        class="cursor-pointer"
                                        @click="removeTimeTue(index)"
                                      />
                                    </div>
                                  </b-col>
                                </b-row>
                              </div>
                            </b-col>
                          </b-row>
                        </div>
                      </b-row>
                    </b-col>
                    <!-- Wednesday -->
                    <b-col
                      md="7"
                      class="py-0 px-1 mt-1"
                    >
                      <b-row class="m-0 d-flex justify-content-between align-items-center mb-1">
                        <b-form-checkbox
                          :value="selectDaysTimeOptions[3].value"
                          @change="isSelectedHours(careNeeds.selected_days)"
                        >
                          <span>{{ selectDaysTimeOptions[3].text }}</span>
                        </b-form-checkbox>
                        <div
                          v-if="localUserRole !== 'client' && localUserRole !== 'agent' ? true : false"
                          style="margin-right: 20px"
                        >
                          <feather-icon
                            v-if="careNeeds.wed_selected_hours.length !== 5 ? true : false"
                            size="20"
                            icon="PlusIcon"
                            class="cursor-pointer"
                            @click="addNewTimeWed"
                          />
                        </div>
                      </b-row>
                      <b-row class="m-0">
                        <!-- Items Section -->
                        <div
                          ref="wedForm"
                          class="repeater-form h-auto w-100"
                          :style="{height: trHeight}"
                        >
                          <b-row
                            v-for="(item, index) in careNeeds.wed_selected_hours"
                            :key="index"
                            ref="wedRow"
                            class="pb-1 m-0 p-0"
                          >
                            <!-- Item Form -->
                            <b-col
                              cols="12"
                              class="p-0"
                            >
                              <!-- Form Input Fields OR content inside bordered area  -->
                              <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                              <div class="border rounded">
                                <b-row class="p-1 m-0">
                                  <b-col
                                    md="5"
                                    class="p-0"
                                  >
                                    <validation-provider
                                      #default="{ errors }"
                                      name="wed start time"
                                      :rules="careNeeds.selected_days.find(f => f === selectDaysTimeOptions[3].value) ? 'required' : ''"
                                      fluid="sm"
                                    >
                                      <b-form-timepicker
                                        v-model="item.start_time"
                                        :disabled="!careNeeds.selected_days.find(f => f === selectDaysTimeOptions[3].text) || (localUserRole === 'client' || localUserRole === 'agent') ? true : false"
                                        now-button
                                        reset-button
                                        locale="en"
                                        placeholder=""
                                        class="h-auto"
                                        :state="errors.length > 0 ? false : null"
                                      />
                                      <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                  </b-col>
                                  <b-col
                                    md="1"
                                    class="p-0 my-auto"
                                  >
                                    <div class="mx-1">
                                      -
                                    </div>
                                  </b-col>
                                  <b-col
                                    sm="5"
                                    class="p-0"
                                  >
                                    <validation-provider
                                      #default="{ errors }"
                                      name="wed end time"
                                      :rules="careNeeds.selected_days.find(f => f === selectDaysTimeOptions[3].value) ? 'required' : ''"
                                      fluid="sm"
                                    >
                                      <b-form-timepicker
                                        v-model="item.end_time"
                                        :disabled="!careNeeds.selected_days.find(f => f === selectDaysTimeOptions[3].text) || (localUserRole === 'client' || localUserRole === 'agent') ? true : false"
                                        now-button
                                        reset-button
                                        locale="en"
                                        placeholder=""
                                        :state="errors.length > 0 ? false : null"
                                      />
                                      <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                  </b-col>
                                  <b-col
                                    md="1"
                                    class="p-0 my-auto"
                                  >
                                    <div
                                      v-if="localUserRole !== 'client' && localUserRole !== 'agent' ? true : false"
                                      style="margin-left: 15px"
                                    >
                                      <feather-icon
                                        v-if="careNeeds.wed_selected_hours.length !== 1 ? true : false"
                                        size="20"
                                        icon="Trash2Icon"
                                        class="cursor-pointer"
                                        @click="removeTimeWed(index)"
                                      />
                                    </div>
                                  </b-col>
                                </b-row>
                              </div>
                            </b-col>
                          </b-row>
                        </div>
                      </b-row>
                    </b-col>
                    <!-- Thursday -->
                    <b-col
                      md="7"
                      class="py-0 px-1 mt-1"
                    >
                      <b-row class="m-0 d-flex justify-content-between align-items-center mb-1">
                        <b-form-checkbox
                          :value="selectDaysTimeOptions[4].value"
                          @change="isSelectedHours(careNeeds.selected_days)"
                        >
                          <span>{{ selectDaysTimeOptions[4].text }}</span>
                        </b-form-checkbox>
                        <div
                          v-if="localUserRole !== 'client' && localUserRole !== 'agent' ? true : false"
                          style="margin-right: 20px"
                        >
                          <feather-icon
                            v-if="careNeeds.th_selected_hours.length !== 5 ? true : false"
                            size="20"
                            icon="PlusIcon"
                            class="cursor-pointer"
                            @click="addNewTimeTh"
                          />
                        </div>
                      </b-row>
                      <b-row class="m-0">
                        <!-- Items Section -->
                        <div
                          ref="thForm"
                          class="repeater-form h-auto w-100"
                          :style="{height: trHeight}"
                        >
                          <b-row
                            v-for="(item, index) in careNeeds.th_selected_hours"
                            :key="index"
                            ref="thRow"
                            class="pb-1 m-0 p-0"
                          >
                            <!-- Item Form -->
                            <b-col
                              cols="12"
                              class="p-0"
                            >
                              <!-- Form Input Fields OR content inside bordered area  -->
                              <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                              <div class="border rounded">
                                <b-row class="p-1 m-0">
                                  <b-col
                                    md="5"
                                    class="p-0"
                                  >
                                    <validation-provider
                                      #default="{ errors }"
                                      name="th start time"
                                      :rules="careNeeds.selected_days.find(f => f === selectDaysTimeOptions[4].value) ? 'required' : ''"
                                      fluid="sm"
                                    >
                                      <b-form-timepicker
                                        v-model="item.start_time"
                                        :disabled="!careNeeds.selected_days.find(f => f === selectDaysTimeOptions[4].text) || (localUserRole === 'client' || localUserRole === 'agent') ? true : false"
                                        now-button
                                        reset-button
                                        locale="en"
                                        placeholder=""
                                        class="h-auto"
                                        :state="errors.length > 0 ? false : null"
                                      />
                                      <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                  </b-col>
                                  <b-col
                                    md="1"
                                    class="p-0 my-auto"
                                  >
                                    <div class="mx-1">
                                      -
                                    </div>
                                  </b-col>
                                  <b-col
                                    sm="5"
                                    class="p-0"
                                  >
                                    <validation-provider
                                      #default="{ errors }"
                                      name="th end time"
                                      :rules="careNeeds.selected_days.find(f => f === selectDaysTimeOptions[4].value) ? 'required' : ''"
                                      fluid="sm"
                                    >
                                      <b-form-timepicker
                                        v-model="item.end_time"
                                        :disabled="!careNeeds.selected_days.find(f => f === selectDaysTimeOptions[4].text) || (localUserRole === 'client' || localUserRole === 'agent') ? true : false"
                                        now-button
                                        reset-button
                                        locale="en"
                                        placeholder=""
                                        :state="errors.length > 0 ? false : null"
                                      />
                                      <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                  </b-col>
                                  <b-col
                                    md="1"
                                    class="p-0 my-auto"
                                  >
                                    <div
                                      v-if="localUserRole !== 'client' && localUserRole !== 'agent' ? true : false"
                                      style="margin-left: 10px"
                                    >
                                      <feather-icon
                                        v-if="careNeeds.th_selected_hours.length !== 1 ? true : false"
                                        size="20"
                                        icon="Trash2Icon"
                                        class="cursor-pointer"
                                        @click="removeTimeTh(index)"
                                      />
                                    </div>
                                  </b-col>
                                </b-row>
                              </div>
                            </b-col>
                          </b-row>
                        </div>
                      </b-row>
                    </b-col>
                    <!-- Friday -->
                    <b-col
                      md="7"
                      class="py-0 px-1 mt-1"
                    >
                      <b-row class="m-0 d-flex justify-content-between align-items-center mb-1">
                        <b-form-checkbox
                          :value="selectDaysTimeOptions[5].value"
                          @change="isSelectedHours(careNeeds.selected_days)"
                        >
                          <span>{{ selectDaysTimeOptions[5].text }}</span>
                        </b-form-checkbox>
                        <div
                          v-if="localUserRole !== 'client' && localUserRole !== 'agent' ? true : false"
                          style="margin-right: 20px"
                        >
                          <feather-icon
                            v-if="careNeeds.fri_selected_hours.length !== 5 ? true : false"
                            size="20"
                            icon="PlusIcon"
                            class="cursor-pointer"
                            @click="addNewTimeFri"
                          />
                        </div>
                      </b-row>
                      <b-row class="m-0">
                        <!-- Items Section -->
                        <div
                          ref="friForm"
                          class="repeater-form h-auto w-100"
                          :style="{height: trHeight}"
                        >
                          <b-row
                            v-for="(item, index) in careNeeds.fri_selected_hours"
                            :key="index"
                            ref="friRow"
                            class="pb-1 m-0 p-0"
                          >
                            <!-- Item Form -->
                            <b-col
                              cols="12"
                              class="p-0"
                            >
                              <!-- Form Input Fields OR content inside bordered area  -->
                              <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                              <div class="border rounded">
                                <b-row class="p-1 m-0">
                                  <b-col
                                    md="5"
                                    class="p-0"
                                  >
                                    <validation-provider
                                      #default="{ errors }"
                                      name="fri start time"
                                      :rules="careNeeds.selected_days.find(f => f === selectDaysTimeOptions[5].value) ? 'required' : ''"
                                      fluid="sm"
                                    >
                                      <b-form-timepicker
                                        v-model="item.start_time"
                                        :disabled="!careNeeds.selected_days.find(f => f === selectDaysTimeOptions[5].text) || (localUserRole === 'client' || localUserRole === 'agent') ? true : false"
                                        now-button
                                        reset-button
                                        locale="en"
                                        placeholder=""
                                        class="h-auto"
                                        :state="errors.length > 0 ? false : null"
                                      />
                                      <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                  </b-col>
                                  <b-col
                                    md="1"
                                    class="p-0 my-auto"
                                  >
                                    <div class="mx-1">
                                      -
                                    </div>
                                  </b-col>
                                  <b-col
                                    sm="5"
                                    class="p-0"
                                  >
                                    <validation-provider
                                      #default="{ errors }"
                                      name="fri end time"
                                      :rules="careNeeds.selected_days.find(f => f === selectDaysTimeOptions[5].value) ? 'required' : ''"
                                      fluid="sm"
                                    >
                                      <b-form-timepicker
                                        v-model="item.end_time"
                                        :disabled="!careNeeds.selected_days.find(f => f === selectDaysTimeOptions[5].text) || (localUserRole === 'client' || localUserRole === 'agent') ? true : false"
                                        now-button
                                        reset-button
                                        locale="en"
                                        placeholder=""
                                        :state="errors.length > 0 ? false : null"
                                      />
                                      <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                  </b-col>
                                  <b-col
                                    md="1"
                                    class="p-0 my-auto"
                                  >
                                    <div
                                      v-if="localUserRole !== 'client' && localUserRole !== 'agent' ? true : false"
                                      style="margin-left: 10px"
                                    >
                                      <feather-icon
                                        v-if="careNeeds.fri_selected_hours.length !== 1 ? true : false"
                                        size="20"
                                        icon="Trash2Icon"
                                        class="cursor-pointer"
                                        @click="removeTimeFri(index)"
                                      />
                                    </div>
                                  </b-col>
                                </b-row>
                              </div>
                            </b-col>
                          </b-row>
                        </div>
                      </b-row>
                    </b-col>
                    <!-- Saturday -->
                    <b-col
                      md="7"
                      class="py-0 px-1 mt-1"
                    >
                      <b-row class="m-0 d-flex justify-content-between align-items-center mb-1">
                        <b-form-checkbox
                          :value="selectDaysTimeOptions[6].value"
                          @change="isSelectedHours(careNeeds.selected_days)"
                        >
                          <span>{{ selectDaysTimeOptions[6].text }}</span>
                        </b-form-checkbox>
                        <div
                          v-if="localUserRole !== 'client' && localUserRole !== 'agent' ? true : false"
                          style="margin-right: 20px"
                        >
                          <feather-icon
                            v-if="careNeeds.sat_selected_hours.length !== 5 ? true : false"
                            size="20"
                            icon="PlusIcon"
                            class="cursor-pointer"
                            @click="addNewTimeSat"
                          />
                        </div>
                      </b-row>
                      <b-row class="m-0">
                        <!-- Items Section -->
                        <div
                          ref="satForm"
                          class="repeater-form h-auto w-100"
                          :style="{height: trHeight}"
                        >
                          <b-row
                            v-for="(item, index) in careNeeds.sat_selected_hours"
                            :key="index"
                            ref="satRow"
                            class="pb-1 m-0 p-0"
                          >
                            <!-- Item Form -->
                            <b-col
                              cols="12"
                              class="p-0"
                            >
                              <!-- Form Input Fields OR content inside bordered area  -->
                              <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                              <div class="border rounded">
                                <b-row class="p-1 m-0">
                                  <b-col
                                    md="5"
                                    class="p-0"
                                  >
                                    <validation-provider
                                      #default="{ errors }"
                                      name="sat start time"
                                      :rules="careNeeds.selected_days.find(f => f === selectDaysTimeOptions[6].value) ? 'required' : ''"
                                      fluid="sm"
                                    >
                                      <b-form-timepicker
                                        v-model="item.start_time"
                                        :disabled="!careNeeds.selected_days.find(f => f === selectDaysTimeOptions[6].text) || (localUserRole === 'client' || localUserRole === 'agent') ? true : false"
                                        now-button
                                        reset-button
                                        locale="en"
                                        placeholder=""
                                        class="h-auto"
                                        :state="errors.length > 0 ? false : null"
                                      />
                                      <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                  </b-col>
                                  <b-col
                                    md="1"
                                    class="p-0 my-auto"
                                  >
                                    <div class="mx-1">
                                      -
                                    </div>
                                  </b-col>
                                  <b-col
                                    sm="5"
                                    class="p-0"
                                  >
                                    <validation-provider
                                      #default="{ errors }"
                                      name="sat end time"
                                      :rules="careNeeds.selected_days.find(f => f === selectDaysTimeOptions[6].value) ? 'required' : ''"
                                      fluid="sm"
                                    >
                                      <b-form-timepicker
                                        v-model="item.end_time"
                                        :disabled="!careNeeds.selected_days.find(f => f === selectDaysTimeOptions[6].text) || (localUserRole === 'client' || localUserRole === 'agent') ? true : false"
                                        now-button
                                        reset-button
                                        locale="en"
                                        placeholder=""
                                        :state="errors.length > 0 ? false : null"
                                      />
                                      <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                  </b-col>
                                  <b-col
                                    md="1"
                                    class="p-0 my-auto"
                                  >
                                    <div
                                      v-if="localUserRole !== 'client' && localUserRole !== 'agent'  ? true : false"
                                      style="margin-left: 10px"
                                    >
                                      <feather-icon
                                        v-if="careNeeds.sat_selected_hours.length !== 1 ? true : false"
                                        size="20"
                                        icon="Trash2Icon"
                                        class="cursor-pointer"
                                        @click="removeTimeSat(index)"
                                      />
                                    </div>
                                  </b-col>
                                </b-row>
                              </div>
                            </b-col>
                          </b-row>
                        </div>
                      </b-row>
                    </b-col>
                  </b-form-checkbox-group>
                  <small
                    v-if="localUserRole !== 'client' && localUserRole !== 'agent' "
                    class="text-danger"
                  >{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col
              v-if="localUserRole !== 'client' && localUserRole !== 'agent' "
              md="10"
            >
              <!-- Action Buttons -->
              <b-button
                variant="secondary"
                class="col-sm-3 mr-1"
                type="submit"
                @click.prevent="validationForm"
              >
                Save Changes
              </b-button>
              <b-button
                variant="outline-dark"
                class="col-sm-2 btn-reset"
                @click="onReset()"
              >
                Reset
              </b-button>
            </b-col>
            <b-col
              v-if="localUserRole === 'client' || localUserRole === 'agent'"
              class="text-right"
            >
              <b-button
                class=""
                variant="secondary"
                @click="onClickNextTab()"
              >
                <span class="align-middle">Next</span>
                <feather-icon
                  icon="ChevronRightIcon"
                  class="ml-50"
                />
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BFormInput, BFormGroup, BForm, BFormTextarea, BFormCheckboxGroup, BFormCheckbox, BFormTimepicker,
  BRow, BCol, BButton,
  BMedia, BCard, BCardText, BAvatar,
} from 'bootstrap-vue'
import {
  computed,
  ref,
} from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import Swal from 'sweetalert2'
import flatPickr from 'vue-flatpickr-component'
import { mask } from 'vue-the-mask'
import { required } from '@validations'
import { heightTransition } from '@core/mixins/ui/transition'
// import { formatDate } from '@core/utils/filter'
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate'
// import router from '@/router'

export default {
  components: {
    // UI
    BFormInput,
    BFormGroup,
    BForm,
    BFormTextarea,
    BFormCheckbox,
    BFormCheckboxGroup,
    // eslint-disable-next-line vue/no-unused-components
    BFormTimepicker,
    BRow,
    BCol,
    BButton,
    BMedia,
    BCard,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    flatPickr,
    BAvatar,
    // Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    mask,
  },
  mixins: [heightTransition],
  props: {
    userRole: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    // for sunday
    addNewTimeSun() {
      this.sunForm.style.overflow = 'hidden'
      this.careNeeds.sun_selected_hours.push(JSON.parse(JSON.stringify(this.blankItem)))

      this.$nextTick(() => {
        this.trAddHeight(this.sunRow[0].offsetHeight)
        setTimeout(() => {
          this.sunForm.style.overflow = null
        }, 350)
      })
    },
    removeTimeSun(index) {
      this.careNeeds.sun_selected_hours.splice(index, 1)
      this.trTrimHeight(this.sunRow[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.sunForm.scrollHeight)
        this.trSetHeight(this.monForm.scrollHeight)
        this.trSetHeight(this.tueForm.scrollHeight)
        this.trSetHeight(this.wedForm.scrollHeight)
        this.trSetHeight(this.thForm.scrollHeight)
        this.trSetHeight(this.friForm.scrollHeight)
        this.trSetHeight(this.satForm.scrollHeight)
      })
    },
    // for monday
    addNewTimeMon() {
      this.monForm.style.overflow = 'hidden'
      this.careNeeds.mon_selected_hours.push(JSON.parse(JSON.stringify(this.blankItem)))

      this.$nextTick(() => {
        this.trAddHeight(this.monRow[0].offsetHeight)
        setTimeout(() => {
          this.monForm.style.overflow = null
        }, 350)
      })
    },
    removeTimeMon(index) {
      this.careNeeds.mon_selected_hours.splice(index, 1)
      this.trTrimHeight(this.monRow[0].offsetHeight)
    },
    // for tuesday
    addNewTimeTue() {
      this.tueForm.style.overflow = 'hidden'
      this.careNeeds.tue_selected_hours.push(JSON.parse(JSON.stringify(this.blankItem)))

      this.$nextTick(() => {
        this.trAddHeight(this.tueRow[0].offsetHeight)
        setTimeout(() => {
          this.tueForm.style.overflow = null
        }, 350)
      })
    },
    removeTimeTue(index) {
      this.careNeeds.tue_selected_hours.splice(index, 1)
      this.trTrimHeight(this.tueRow[0].offsetHeight)
    },
    // for wednesday
    addNewTimeWed() {
      this.wedForm.style.overflow = 'hidden'
      this.careNeeds.wed_selected_hours.push(JSON.parse(JSON.stringify(this.blankItem)))

      this.$nextTick(() => {
        this.trAddHeight(this.wedRow[0].offsetHeight)
        setTimeout(() => {
          this.wedForm.style.overflow = null
        }, 350)
      })
    },
    removeTimeWed(index) {
      this.careNeeds.wed_selected_hours.splice(index, 1)
      this.trTrimHeight(this.wedRow[0].offsetHeight)
    },
    // for thurday
    addNewTimeTh() {
      this.thForm.style.overflow = 'hidden'
      this.careNeeds.th_selected_hours.push(JSON.parse(JSON.stringify(this.blankItem)))

      this.$nextTick(() => {
        this.trAddHeight(this.thRow[0].offsetHeight)
        setTimeout(() => {
          this.thForm.style.overflow = null
        }, 350)
      })
    },
    removeTimeTh(index) {
      this.careNeeds.th_selected_hours.splice(index, 1)
      this.trTrimHeight(this.thRow[0].offsetHeight)
    },
    // for friday
    addNewTimeFri() {
      this.friForm.style.overflow = 'hidden'
      this.careNeeds.fri_selected_hours.push(JSON.parse(JSON.stringify(this.blankItem)))

      this.$nextTick(() => {
        this.trAddHeight(this.friRow[0].offsetHeight)
        setTimeout(() => {
          this.friForm.style.overflow = null
        }, 350)
      })
    },
    removeTimeFri(index) {
      this.careNeeds.fri_selected_hours.splice(index, 1)
      this.trTrimHeight(this.friRow[0].offsetHeight)
    },
    // for saturday
    addNewTimeSat() {
      this.satForm.style.overflow = 'hidden'
      this.careNeeds.sat_selected_hours.push(JSON.parse(JSON.stringify(this.blankItem)))

      this.$nextTick(() => {
        this.trAddHeight(this.satRow[0].offsetHeight)
        setTimeout(() => {
          this.satForm.style.overflow = null
        }, 350)
      })
    },
    removeTimeSat(index) {
      this.careNeeds.sat_selected_hours.splice(index, 1)
      this.trTrimHeight(this.satRow[0].offsetHeight)
    },
    isOtherSelected(val) {
      // const other = val.find(f => f === this.shiftTypeOptions[3].value)
      if (val !== this.shiftTypeOptions[3].value) {
        this.careNeeds.shift_type_input = ''
      }
      // this.careNeeds.shift_type_input = ''
    },
    isSelectedHours(val) {
      const sun = val.find(f => f === this.selectDaysTimeOptions[0].value)
      const mon = val.find(f => f === this.selectDaysTimeOptions[1].value)
      const tue = val.find(f => f === this.selectDaysTimeOptions[2].value)
      const wed = val.find(f => f === this.selectDaysTimeOptions[3].value)
      const th = val.find(f => f === this.selectDaysTimeOptions[4].value)
      const fri = val.find(f => f === this.selectDaysTimeOptions[5].value)
      const sat = val.find(f => f === this.selectDaysTimeOptions[6].value)
      if (sun !== this.selectDaysTimeOptions[0].value) {
        this.careNeeds.sun_selected_hours = [{
          start_time: '',
          end_time: '',
        }]
      }
      if (mon !== this.selectDaysTimeOptions[1].value) {
        this.careNeeds.mon_selected_hours = [{
          start_time: '',
          end_time: '',
        }]
      }
      if (tue !== this.selectDaysTimeOptions[2].value) {
        this.careNeeds.tue_selected_hours = [{
          start_time: '',
          end_time: '',
        }]
      }
      if (wed !== this.selectDaysTimeOptions[3].value) {
        this.careNeeds.wed_selected_hours = [{
          start_time: '',
          end_time: '',
        }]
      }
      if (th !== this.selectDaysTimeOptions[4].value) {
        this.careNeeds.th_selected_hours = [{
          start_time: '',
          end_time: '',
        }]
      }
      if (fri !== this.selectDaysTimeOptions[5].value) {
        this.careNeeds.fri_selected_hours = [{
          start_time: '',
          end_time: '',
        }]
      }
      if (sat !== this.selectDaysTimeOptions[6].value) {
        this.careNeeds.sat_selected_hours = [{
          start_time: '',
          end_time: '',
        }]
      }
    },
    onReset() {
      const bckUpData = this.careNeedsBckUp
      if (bckUpData.length !== 0) {
        store.dispatch('client_assessmentcare/filterTab1', bckUpData)
      } else {
        store.state.client_assessmentcare.careNeeds = {
          selected_days: [],
          sun_selected_hours: [{ start_time: '', end_time: '' }],
          mon_selected_hours: [{ start_time: '', end_time: '' }],
          tue_selected_hours: [{ start_time: '', end_time: '' }],
          wed_selected_hours: [{ start_time: '', end_time: '' }],
          th_selected_hours: [{ start_time: '', end_time: '' }],
          fri_selected_hours: [{ start_time: '', end_time: '' }],
          sat_selected_hours: [{ start_time: '', end_time: '' }],
        }
      }
    },
  },
  setup(props, { emit }) {
    const pageTitle = 'Care Needs'
    const simpleRules = ref(null)
    // v-model variabe declaration
    const careNeeds = ref(computed(() => store.state.client_assessmentcare.careNeeds))
    const careNeedsBckUp = ref(computed(() => store.state.client_assessmentcare.careNeedsBckUp))
    const sunForm = ref(null)
    const monForm = ref(null)
    const tueForm = ref(null)
    const wedForm = ref(null)
    const thForm = ref(null)
    const friForm = ref(null)
    const satForm = ref(null)
    const sunRow = ref(null)
    const monRow = ref(null)
    const tueRow = ref(null)
    const wedRow = ref(null)
    const thRow = ref(null)
    const friRow = ref(null)
    const satRow = ref(null)
    const localUserRole = ref(props.userRole)
    const clientId = router.currentRoute.query.id ? router.currentRoute.query.id : ''

    // options declaration with values
    const shiftTypeOptions = [
      { id: 1, text: '4 Hours', value: '4 Hours' },
      { id: 2, text: '8 Hours', value: '8 Hours' },
      { id: 3, text: 'As Needed', value: 'As Needed' },
      { id: 4, text: 'Other', value: 'Other' },
    ]
    const selectDaysTimeOptions = [
      { id: 1, text: 'Sunday', value: 'Sunday' },
      { id: 2, text: 'Monday', value: 'Monday' },
      { id: 3, text: 'Tuesday', value: 'Tuesday' },
      { id: 4, text: 'Wednesday', value: 'Wednesday' },
      { id: 5, text: 'Thursday', value: 'Thursday' },
      { id: 6, text: 'Friday', value: 'Friday' },
      { id: 7, text: 'Saturday', value: 'Saturday' },
    ]
    const blankItem = {
      start_time: '',
      end_time: '',
    }
    const onClickNextTab = () => {
      emit('next-tab')
    }

    // *===============================================---*
    // *--------- SAVE DATA ------------------------------*
    // *===============================================---*
    const onSaveData = () => {
      // const uData = localStorage.getItem('userData')
      // const uID = JSON.parse(uData)
      // console.log('user data', uID)
      const formData = new FormData()
      const data = JSON.stringify({
        // client_id: uID.clientId,
        client_id: clientId,
        tab_name: pageTitle,
        initial_contact: careNeeds.value.initial_contact,
        start_date: careNeeds.value.start_date,
        // care_goals: careNeeds.value.care_goals,
        shift_type: careNeeds.value.shift_type !== false ? careNeeds.value.shift_type : '',
        shift_type_input: careNeeds.value.shift_type_input,
        selected_days: careNeeds.value.selected_days,
        sun_selected_hours: careNeeds.value.sun_selected_hours,
        mon_selected_hours: careNeeds.value.mon_selected_hours,
        tue_selected_hours: careNeeds.value.tue_selected_hours,
        wed_selected_hours: careNeeds.value.wed_selected_hours,
        th_selected_hours: careNeeds.value.th_selected_hours,
        fri_selected_hours: careNeeds.value.fri_selected_hours,
        sat_selected_hours: careNeeds.value.sat_selected_hours,
      })
      formData.append('data', data)
      // console.log('uData.id', formData)
      store.dispatch('client_assessmentcare/insertData', formData)
        .then(() => {
          Swal.fire({
            icon: 'success',
            title: 'Updated Succesfully',
            showConfirmButton: false,
            timer: 1500,
            customClass: {
              confirmButton: 'btn btn-secondary',
            },
            buttonsStyling: false,
          })
          emit('fetch-my-data')
        })
        .catch(error => {
          console.error(error)
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
            showConfirmButton: false,
            timer: 1500,
          })
          // console.log('error', error)
        })
    }
    const validationForm = () => {
      simpleRules.value.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          // alert('form submitted!')
          onSaveData()
          // console.log('ref.simpleRules', simpleRules.value)
        } else {
          Swal.fire({
            icon: 'warning',
            title: 'Oops...',
            text: 'Make sure to fill up  the required fields',
            showConfirmButton: false,
            timer: 1500,
          })
        }
      })
    }

    // const onClear = () => {
    //   store.state.client_assessmentcare.careNeeds = {
    //     selected_days: [],
    //     sun_selected_hours: { start_time: '', end_time: '' },
    //     mon_selected_hours: { start_time: '', end_time: '' },
    //     tue_selected_hours: { start_time: '', end_time: '' },
    //     wed_selected_hours: { start_time: '', end_time: '' },
    //     th_selected_hours: { start_time: '', end_time: '' },
    //     fri_selected_hours: { start_time: '', end_time: '' },
    //     sat_selected_hours: { start_time: '', end_time: '' },
    //   }
    //   // timeStartSun.value = ''
    //   // timeEndSun.value = ''
    //   // timeStartMon.value = ''
    //   // timeEndMon.value = ''
    //   // timeStartTue.value = ''
    //   // timeEndTue.value = ''
    //   // timeStartWed.value = ''
    //   // timeEndWed.value = ''
    //   // timeStartTh.value = ''
    //   // timeEndTh.value = ''
    //   // timeStartFri.value = ''
    //   // timeEndFri.value = ''
    //   // timeStartSat.value = ''
    //   // timeEndSat.value = ''
    // }

    return {
      pageTitle,
      careNeeds,
      careNeedsBckUp,
      simpleRules,

      // Options
      shiftTypeOptions,
      selectDaysTimeOptions,
      blankItem,

      // functions
      onSaveData,
      validationForm,
      onClickNextTab,
      sunForm,
      monForm,
      tueForm,
      wedForm,
      thForm,
      friForm,
      satForm,
      sunRow,
      monRow,
      tueRow,
      wedRow,
      thRow,
      friRow,
      satRow,

      // others
      required,
      localUserRole,
    }
  },
}

// *===============================================---*
// *--------- CUSTOM VALIDATION ----------------------*
// *===============================================---*
export const validatorFalse = value => {
  if (value !== false) {
    return true
    // console.log(value)
  }
  return false
}
export const isFalse = extend('isFalse', {
  validate: validatorFalse,
  message: 'Please select shift type.',
})
/* End of custom validation */
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style lang="scss" scoped>
.repeater-form {
  transition: .35s height;
}
</style>
