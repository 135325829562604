var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-media',{attrs:{"vertical-align":"top"},scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{staticStyle:{"background-color":"white","box-shadow":"1px 1px 1px #aaaaaa"},attrs:{"rounded":"","variant":"light-secondary","size":"50"}},[_c('img',{attrs:{"src":require("@/assets/images/icons/assessment-care/demographics-icon-default.svg"),"width":"28px","height":"28px"}})])]},proxy:true}])},[_c('h4',{staticClass:"media-heading"},[_vm._v(" "+_vm._s(_vm.pageTitle)+" ")]),_c('b-card-text',{staticClass:"mb-0"},[_vm._v(" Client's identifying information. ")])],1),_c('b-card',{staticClass:"mt-2"},[_c('validation-observer',{ref:"simpleRulesDemographics"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Date of Birth","label-for":"date-of-birth"}},[_c('flat-pickr',{attrs:{"config":{ altInput: true, altFormat: 'F j, Y', dateFormat: 'Y-m-d',},"disabled":_vm.localUserRole === 'client' || _vm.localUserRole === 'agent' ? true : false},model:{value:(_vm.demographics.date_of_birth),callback:function ($$v) {_vm.$set(_vm.demographics, "date_of_birth", $$v)},expression:"demographics.date_of_birth"}})],1)],1),_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',{attrs:{"label":"Weight (lb)","label-for":"weight"}},[_c('validation-provider',{attrs:{"name":"Weight","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:(['###.##']),expression:"['###.##']"}],attrs:{"state":errors.length > 0 ? false:null,"disabled":_vm.localUserRole === 'client' || _vm.localUserRole === 'agent' ? true : false},model:{value:(_vm.demographics.weight),callback:function ($$v) {_vm.$set(_vm.demographics, "weight", $$v)},expression:"demographics.weight"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',{attrs:{"label":"Height (cm)","label-for":"height"}},[_c('validation-provider',{attrs:{"name":"Height","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:(['###']),expression:"['###']"}],attrs:{"state":errors.length > 0 ? false : null,"disabled":_vm.localUserRole === 'client' || _vm.localUserRole === 'agent' ? true : false},model:{value:(_vm.demographics.height),callback:function ($$v) {_vm.$set(_vm.demographics, "height", $$v)},expression:"demographics.height"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Marital Status","label-for":"marital-status"}},[_c('validation-provider',{attrs:{"name":"Marital Status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"state":errors.length > 0 ? false:null,"options":_vm.maritalStatusOptions,"disabled":_vm.localUserRole === 'client' || _vm.localUserRole === 'agent' ? true : false},model:{value:(_vm.demographics.marital_status),callback:function ($$v) {_vm.$set(_vm.demographics, "marital_status", $$v)},expression:"demographics.marital_status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Spouse Name","label-for":"spouse-name"}},[_c('validation-provider',{attrs:{"name":"Spouse Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"disabled":_vm.localUserRole === 'client' || _vm.localUserRole === 'agent' ? true : false},model:{value:(_vm.demographics.spouse_name),callback:function ($$v) {_vm.$set(_vm.demographics, "spouse_name", $$v)},expression:"demographics.spouse_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Live With","label-for":"live-with"}},[_c('validation-provider',{attrs:{"name":"Live With","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"disabled":_vm.localUserRole === 'client' || _vm.localUserRole === 'agent' ? true : false},model:{value:(_vm.demographics.live_with),callback:function ($$v) {_vm.$set(_vm.demographics, "live_with", $$v)},expression:"demographics.live_with"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Religion","label-for":"religion"}},[_c('validation-provider',{attrs:{"name":"Religion","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"disabled":_vm.localUserRole === 'client' || _vm.localUserRole === 'agent' ? true : false},model:{value:(_vm.demographics.religion),callback:function ($$v) {_vm.$set(_vm.demographics, "religion", $$v)},expression:"demographics.religion"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Attend Services","label-for":"attend-services"}},[_c('validation-provider',{attrs:{"name":"Attend Services","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"disabled":_vm.localUserRole === 'client' || _vm.localUserRole === 'agent' ? true : false},model:{value:(_vm.demographics.attend_services),callback:function ($$v) {_vm.$set(_vm.demographics, "attend_services", $$v)},expression:"demographics.attend_services"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"DNR","label-for":"dnr"}},[_c('validation-provider',{attrs:{"name":"DNR","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio-group',{attrs:{"name":"dnr","state":errors.length > 0 ? false : null},model:{value:(_vm.demographics.dnr),callback:function ($$v) {_vm.$set(_vm.demographics, "dnr", $$v)},expression:"demographics.dnr"}},[_vm._l((_vm.demoShiftTypeOptions),function(option,index){return _c('b-form-radio',{key:index,class:index !== 3 ? 'mr-2' : 'mr-0',attrs:{"value":option.value,"disabled":_vm.localUserRole === 'client' || _vm.localUserRole === 'agent' ? true : false}},[_vm._v(" "+_vm._s(option.text)+" ")])}),_c('br'),(_vm.localUserRole !== 'client' && _vm.localUserRole !== 'agent' )?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],2)]}}])})],1)],1),_c('b-col',{attrs:{"cols":"8"}},[_c('b-form-group',{attrs:{"label":"Languages","label-for":"languages"}},[_c('validation-provider',{attrs:{"name":"Languages","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-tags',{attrs:{"separator":"","placeholder":"","add-on-change":"","remove-on-delete":"","tag-remove-label":"Remove tag","disabled":_vm.localUserRole === 'client' || _vm.localUserRole === 'agent' ? true : false,"state":errors.length > 0 ? false : null},model:{value:(_vm.demographics.languages),callback:function ($$v) {_vm.$set(_vm.demographics, "languages", $$v)},expression:"demographics.languages"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',{staticClass:"mt-2"},[(_vm.localUserRole !== 'client' && _vm.localUserRole !== 'agent' )?_c('b-col',{attrs:{"md":"10"}},[_c('b-button',{staticClass:"col-sm-3 mr-1",attrs:{"variant":"secondary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" Save Changes ")]),_c('b-button',{staticClass:"col-sm-2 btn-reset",attrs:{"variant":"outline-dark"},on:{"click":function($event){return _vm.onReset()}}},[_vm._v(" Reset ")])],1):_vm._e(),(_vm.localUserRole === 'client' || _vm.localUserRole === 'agent')?_c('b-col',{staticClass:"text-right"},[_c('b-button',{attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.onClickNextTab()}}},[_c('span',{staticClass:"align-middle"},[_vm._v("Next")]),_c('feather-icon',{staticClass:"ml-50",attrs:{"icon":"ChevronRightIcon"}})],1)],1):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }