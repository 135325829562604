var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-media',{attrs:{"vertical-align":"top"},scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{staticStyle:{"background-color":"white","box-shadow":"1px 1px 1px #aaaaaa"},attrs:{"rounded":"","variant":"light-secondary","size":"50"}},[_c('img',{attrs:{"src":require("@/assets/images/icons/assessment-care/physician-icon-default.svg"),"width":"28px","height":"28px"}})])]},proxy:true}])},[_c('h4',{staticClass:"media-heading"},[_vm._v(" "+_vm._s(_vm.pageTitle)+" ")]),_c('b-card-text',{staticClass:"mb-0"},[_vm._v(" Client's trusted Physician/s and their information. ")])],1),_c('b-card',{staticClass:"mt-2"},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12"}},[_c('b-media',{attrs:{"vertical-align":"top"},scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('feather-icon',{attrs:{"icon":"CalendarIcon","size":"24"}})]},proxy:true}])},[_c('h4',{staticClass:"media-heading"},[_vm._v(" Primary Care Physician ")])])],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Name","label-for":"primary-care-physician-name"}},[_c('validation-provider',{attrs:{"name":"Primary Care Physician Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"type":"text","name":"primary-care-physician-name","disabled":_vm.localUserRole === 'client' || _vm.localUserRole === 'agent' ? true : false},model:{value:(_vm.physician.primary_physician_name),callback:function ($$v) {_vm.$set(_vm.physician, "primary_physician_name", $$v)},expression:"physician.primary_physician_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Address","label-for":"primary-care-physician-address"}},[_c('validation-provider',{attrs:{"name":"Primary Care Physician Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"type":"text","name":"primary-care-physician-address","disabled":_vm.localUserRole === 'client' || _vm.localUserRole === 'agent' ? true : false},model:{value:(_vm.physician.primary_physician_address),callback:function ($$v) {_vm.$set(_vm.physician, "primary_physician_address", $$v)},expression:"physician.primary_physician_address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Phone","label-for":"primary-care-physician-phone"}},[_c('validation-provider',{attrs:{"name":"Primary Care Physician Phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:(['(###) ###-####']),expression:"['(###) ###-####']"}],attrs:{"state":errors.length > 0 ? false:null,"name":"primary-care-physician-phone","disabled":_vm.localUserRole === 'client' || _vm.localUserRole === 'agent' ? true : false},model:{value:(_vm.physician.primary_physician_phone),callback:function ($$v) {_vm.$set(_vm.physician, "primary_physician_phone", $$v)},expression:"physician.primary_physician_phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Nurse","label-for":"primary-care-physician-nurse"}},[_c('validation-provider',{attrs:{"name":"Primary Care Physician Nurse","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"type":"text","name":"primary-care-physician-nurse","disabled":_vm.localUserRole === 'client' || _vm.localUserRole === 'agent' ? true : false},model:{value:(_vm.physician.primary_physician_nurse),callback:function ($$v) {_vm.$set(_vm.physician, "primary_physician_nurse", $$v)},expression:"physician.primary_physician_nurse"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('hr',{staticClass:"my-2"}),_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12"}},[_c('b-media',{attrs:{"vertical-align":"top"},scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('feather-icon',{attrs:{"icon":"CalendarIcon","size":"24"}})]},proxy:true}])},[_c('h4',{staticClass:"media-heading"},[_vm._v(" Other Physician ")])])],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Name","label-for":"other-physician-name"}},[_c('validation-provider',{attrs:{"name":"Other Physician Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"type":"text","name":"other-physician-name","disabled":_vm.localUserRole === 'client' || _vm.localUserRole === 'agent' ? true : false},model:{value:(_vm.physician.other_physician_name),callback:function ($$v) {_vm.$set(_vm.physician, "other_physician_name", $$v)},expression:"physician.other_physician_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Address","label-for":"other-physician-address"}},[_c('validation-provider',{attrs:{"name":"Other Care Physician Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"type":"text","name":"other-physician-address","disabled":_vm.localUserRole === 'client' || _vm.localUserRole === 'agent' ? true : false},model:{value:(_vm.physician.other_physician_address),callback:function ($$v) {_vm.$set(_vm.physician, "other_physician_address", $$v)},expression:"physician.other_physician_address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Phone","label-for":"other-physician-phone"}},[_c('validation-provider',{attrs:{"name":"Other Physician Phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:(['(###) ###-####']),expression:"['(###) ###-####']"}],attrs:{"state":errors.length > 0 ? false:null,"name":"other-physician-phone","disabled":_vm.localUserRole === 'client' || _vm.localUserRole === 'agent' ? true : false},model:{value:(_vm.physician.other_physician_phone),callback:function ($$v) {_vm.$set(_vm.physician, "other_physician_phone", $$v)},expression:"physician.other_physician_phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Nurse","label-for":"other-physician-nurse"}},[_c('validation-provider',{attrs:{"name":"Other Physician Nurse","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"type":"text","name":"other-physician-nurse","disabled":_vm.localUserRole === 'client' || _vm.localUserRole === 'agent' ? true : false},model:{value:(_vm.physician.other_physician_nurse),callback:function ($$v) {_vm.$set(_vm.physician, "other_physician_nurse", $$v)},expression:"physician.other_physician_nurse"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',{staticClass:"mt-2"},[(_vm.localUserRole !== 'client' && _vm.localUserRole !== 'agent' )?_c('b-col',{attrs:{"md":"10"}},[_c('b-button',{staticClass:"col-sm-3 mr-1",attrs:{"variant":"secondary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" Save Changes ")]),_c('b-button',{staticClass:"col-sm-2 btn-reset",attrs:{"variant":"outline-secondary"},on:{"click":function($event){return _vm.onReset()}}},[_vm._v(" Reset ")])],1):_vm._e(),(_vm.localUserRole === 'client' || _vm.localUserRole === 'agent')?_c('b-col',{staticClass:"text-right"},[_c('b-button',{attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.onClickNextTab()}}},[_c('span',{staticClass:"align-middle"},[_vm._v("Next")]),_c('feather-icon',{staticClass:"ml-50",attrs:{"icon":"ChevronRightIcon"}})],1)],1):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }