<template>
  <div>
    <b-media vertical-align="top">
      <template #aside>
        <b-avatar
          rounded
          variant="light-secondary"
          size="50"
          style="background-color: white; box-shadow: 1px 1px 1px #aaaaaa"
        >
          <img
            src="@/assets/images/icons/assessment-care/elimination-icon-default.svg"
            width="28px"
            height="28px"
          >
        </b-avatar>
      </template>
      <h4 class="media-heading">
        {{ pageTitle }}
      </h4>
      <b-card-text class="mb-0">
        Client's regulation, control, and removal of by-products and wastes in the body.
      </b-card-text>
    </b-media>
    <!-- form -->
    <b-card class="mt-2">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Incontinence"
                label-for="incontinence"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Incontinence"
                  rules=""
                >
                  <b-form-checkbox-group
                    v-model="elimination.incontinence"
                    :state="errors.length > 0 ? false:null"
                    name="incontinence-options"
                    :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                  >
                    <b-form-checkbox
                      v-for="option in incontinenceOptions"
                      :key="option.id"
                      :value="option.value"
                      class="mr-4"
                    >
                      {{ option.text }}
                    </b-form-checkbox>
                  </b-form-checkbox-group>
                  <small
                    v-if="localUserRole !== 'client' && localUserRole !== 'agent' "
                    class="text-danger"
                  >{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Issues"
                label-for="issues"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Issues"
                  rules=""
                >
                  <b-form-checkbox-group
                    v-model="elimination.issues"
                    :state="errors.length > 0 ? false:null"
                    name="issues-options"
                    :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                  >
                    <b-form-checkbox
                      v-for="option in issuesOptions"
                      :key="option.id"
                      :value="option.value"
                      class="mr-4"
                    >
                      {{ option.text }}
                    </b-form-checkbox>
                  </b-form-checkbox-group>
                  <small
                    v-if="localUserRole !== 'client' && localUserRole !== 'agent' "
                    class="text-danger"
                  >{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Wear briefs?"
                label-for="wear-briefs"
                label-cols-md="3"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Wear briefs?"
                  rules="required"
                >
                  <b-form-radio-group
                    v-model="elimination.wear_briefs"
                    :state="errors.length > 0 ? false:null"
                    name="Wear briefs?"
                    :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                    style="margin-top: 8px"
                  >
                    <b-form-radio
                      v-for="option in radioYN"
                      :key="option.text"
                      :value="option.text"
                      class="mr-4"
                    >
                      {{ option.text }}
                    </b-form-radio>
                  </b-form-radio-group>
                  <small
                    v-if="localUserRole !== 'client' && localUserRole !== 'agent'"
                    class="text-danger"
                  >{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Underpads"
                label-for="underpads"
                label-cols-md="3"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Underpads"
                  rules="required"
                >
                  <b-form-radio-group
                    v-model="elimination.underpads"
                    :state="errors.length > 0 ? false:null"
                    name="underpads-radio-group"
                    :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                    style="margin-top: 8px"
                  >
                    <b-form-radio
                      v-for="option in radioYN"
                      :key="option.text"
                      :value="option.text"
                      class="mr-4"
                    >
                      {{ option.text }}
                    </b-form-radio>
                  </b-form-radio-group>
                  <small
                    v-if="localUserRole !== 'client' && localUserRole !== 'agent' "
                    class="text-danger"
                  >{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Notes"
                label-for="notes"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Notes"
                  rules=""
                >
                  <b-form-textarea
                    v-model="elimination.notes"
                    :state="errors.length > 0 ? false:null"
                    name="notes-underpads"
                    :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                    rows="3"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col
              v-if="localUserRole !== 'client' && localUserRole !== 'agent' "
              md="10"
            >
              <!-- Action Buttons -->
              <b-button
                variant="secondary"
                class="col-sm-3 mr-1"
                type="submit"
                @click.prevent="validationForm"
              >
                Save Changes
              </b-button>
              <b-button
                variant="outline-dark"
                class="col-sm-2 btn-reset"
                @click="onReset()"
              >
                Reset
              </b-button>
            </b-col>
            <b-col
              v-if="localUserRole === 'client' || localUserRole === 'agent'"
              class="text-right"
            >
              <b-button
                class=""
                variant="secondary"
                @click="onClickNextTab()"
              >
                <span class="align-middle">Next</span>
                <feather-icon
                  icon="ChevronRightIcon"
                  class="ml-50"
                />
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BFormCheckboxGroup, BFormCheckbox, BFormRadioGroup, BFormRadio, BFormTextarea, BFormGroup, BForm,
  BRow, BCol, BButton,
  BMedia, BCard, BCardText, BAvatar,
} from 'bootstrap-vue'
import {
  computed,
  // watch,
  ref,
} from '@vue/composition-api'
import store from '@/store'
import Swal from 'sweetalert2'
import router from '@/router'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormRadioGroup,
    BFormRadio,
    BFormTextarea,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BMedia,
    BCard,
    BCardText,
    BAvatar,
  },
  props: {
    userRole: {
      type: String,
      required: true,
    },
  },
  methods: {
    onReset() {
      const bckUpData = this.eliminationBckUp
      if (bckUpData.length !== 0) {
        store.dispatch('client_assessmentcare/filterTab18', bckUpData)
      } else {
        store.state.client_assessmentcare.elimination = {}
      }
    },
  },
  setup(props, { emit }) {
    const pageTitle = 'Elimination'
    const simpleRules = ref(null)
    const localUserRole = ref(props.userRole)
    const clientId = router.currentRoute.query.id ? router.currentRoute.query.id : ''
    const elimination = ref(computed(() => store.state.client_assessmentcare.elimination))
    const eliminationBckUp = ref(computed(() => store.state.client_assessmentcare.eliminationBckUp))

    // elimination.value = elimination.value ? elimination.value : emptyData
    const onClickNextTab = () => {
      emit('next-tab')
    }
    // options declaration with values
    const incontinenceOptions = [
      { id: 1, text: 'Urination', value: 'Urination' },
      { id: 2, text: 'Bowels', value: 'Bowels' },
    ]
    const issuesOptions = [
      { id: 1, text: 'Constipation', value: 'Constipation' },
      { id: 2, text: 'Diarrhea', value: 'Diarrhea' },
      { id: 3, text: 'Urination', value: 'Urination' },
    ]
    const radioYN = [
      { id: 1, text: 'Yes', value: 'Yes' },
      { id: 2, text: 'No', value: 'No' },
    ]

    // *===============================================---*
    // *--------- SAVE DATA FUNCTION ---------------------*
    // *===============================================---*
    const onSaveData = () => {
      // const uData = localStorage.getItem('userData')
      // const uID = JSON.parse(uData)
      const formData = new FormData()
      const data = JSON.stringify({
        // client_id: uID.clientId,
        client_id: clientId,
        tab_name: pageTitle,
        incontinence: elimination.value.incontinence,
        issues: elimination.value.issues,
        wear_briefs: elimination.value.wear_briefs,
        underpads: elimination.value.underpads,
        notes: elimination.value.notes,
      })
      formData.append('data', data)
      store.dispatch('client_assessmentcare/insertData', formData)
        .then(() => {
          Swal.fire({
            icon: 'success',
            title: 'Updated Succesfully',
            showConfirmButton: false,
            timer: 1500,
            customClass: {
              confirmButton: 'btn btn-secondary',
            },
            buttonsStyling: false,
          })
          emit('fetch-my-data')
        })
        .catch(error => {
          console.error(error)
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
            showConfirmButton: false,
            timer: 1500,
          })
          // console.log('error', error)
        })
    }

    // *===============================================---*
    // *--------- VALIDATE THEN SAVE DATA ----------------*
    // *===============================================---*
    const validationForm = () => {
      simpleRules.value.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          // alert('form submitted!')
          onSaveData()
          // console.log('ref.simpleRules', simpleRules.value)
        } else {
          Swal.fire({
            icon: 'warning',
            title: 'Oops...',
            text: 'Make sure to fill up  the required fields',
            showConfirmButton: false,
            timer: 1500,
          })
        }
      })
    }
    return {
      pageTitle,
      simpleRules,
      localUserRole,
      elimination,
      eliminationBckUp,
      incontinenceOptions,
      issuesOptions,
      radioYN,
      onClickNextTab,
      validationForm,
      required,
    }
  },
}
</script>

<style>
.btn-reset:focus  {
  border: 1px solid #82868b !important;
  background-color:transparent !important;
  box-shadow: none !important;
}
.btn-reset:hover  {
  box-shadow: 0 8px 25px -8px #9c9fa0 !important;
}
</style>
