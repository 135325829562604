<template>
  <div>
    <b-media vertical-align="top">
      <template #aside>
        <b-avatar
          rounded
          variant="light-secondary"
          size="50"
          style="background-color: white; box-shadow: 1px 1px 1px #aaaaaa"
        >
          <img
            src="@/assets/images/icons/assessment-care/ambulation-icon-default.svg"
            width="28px"
            height="28px"
          >
        </b-avatar>
      </template>
      <h4 class="media-heading">
        {{ pageTitle }}
      </h4>
      <b-card-text class="mb-0">
        Client's ability to walk from place to place independently, with or without assistive devices.
      </b-card-text>
    </b-media>
    <!-- form -->
    <b-card class="mt-2">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col md="4">
              <b-form-group
                label="Aids"
                label-for="aids"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Aids"
                  rules=""
                >
                  <b-form-checkbox-group
                    v-model="ambulation.aids"
                    :state="errors.length > 0 ? false : null"
                    name="Aids Options"
                    stacked
                    :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                    class=""
                  >
                    <b-form-checkbox
                      v-for="option in aidsOptions"
                      :key="option.id"
                      :value="option.value"
                      class="mb-1"
                    >
                      {{ option.text }}
                    </b-form-checkbox>
                  </b-form-checkbox-group>
                  <small
                    v-if="localUserRole !== 'client' && localUserRole !== 'agent' "
                    class="text-danger"
                  >{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Fall risk"
                label-for="fall-risk"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Fall risk"
                  rules=""
                >
                  <b-form-checkbox-group
                    v-model="ambulation.fall_risk"
                    :state="errors.length > 0 ? false : null"
                    name="Fall risks Options"
                    stacked
                    :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                    class=""
                  >
                    <b-form-checkbox
                      v-for="option in fallRiskOptions"
                      :key="option.id"
                      :value="option.value"
                      class="mb-1"
                    >
                      {{ option.text }}
                    </b-form-checkbox>
                  </b-form-checkbox-group>
                  <small
                    v-if="localUserRole !== 'client' && localUserRole !== 'agent' "
                    class="text-danger"
                  >{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Use of Arms/Hands"
                label-for="use-of-arms/hands"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Use of Arms/Hands"
                  rules=""
                >
                  <b-form-checkbox-group
                    v-model="ambulation.use_of_arm_hands"
                    :state="errors.length > 0 ? false : null"
                    name="Use of Arms/Hands Options"
                    stacked
                    :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                    class=""
                  >
                    <b-form-checkbox
                      v-for="option in useArmHandsOptions "
                      :key="option.id"
                      :value="option.value"
                      class="mb-1"
                    >
                      {{ option.text }}
                    </b-form-checkbox>
                  </b-form-checkbox-group>
                  <small
                    v-if="localUserRole !== 'client' && localUserRole !== 'agent' "
                    class="text-danger"
                  >{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Notes"
                label-for="notes"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Notes"
                  rules=""
                >
                  <b-form-textarea
                    v-model="ambulation.notes"
                    :state="errors.length > 0 ? false:null"
                    name="notes-ambulation"
                    :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                    rows="3"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col
              v-if="localUserRole !== 'client' && localUserRole !== 'agent' "
              md="10"
            >
              <!-- Action Buttons -->
              <b-button
                variant="secondary"
                class="col-sm-3 mr-1"
                type="submit"
                @click.prevent="validationForm"
              >
                Save Changes
              </b-button>
              <b-button
                variant="outline-dark"
                class="col-sm-2 btn-reset"
                @click="onReset()"
              >
                Reset
              </b-button>
            </b-col>
            <b-col
              v-if="localUserRole === 'client' || localUserRole === 'agent'"
              class="text-right"
            >
              <b-button
                class=""
                variant="secondary"
                @click="onClickNextTab()"
              >
                <span class="align-middle">Next</span>
                <feather-icon
                  icon="ChevronRightIcon"
                  class="ml-50"
                />
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BFormGroup, BForm, BFormCheckbox, BFormCheckboxGroup, BFormTextarea,
  BRow, BCol, BButton,
  BMedia, BCard, BCardText, BAvatar,
} from 'bootstrap-vue'
import {
  computed,
  // watch,
  ref,
} from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import Swal from 'sweetalert2'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormTextarea,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BMedia,
    BCard,
    BCardText,
    BAvatar,
  },
  props: {
    userRole: {
      type: String,
      required: true,
    },
  },
  methods: {
    onReset() {
      const bckUpData = this.ambulationBckUp
      if (bckUpData.length !== 0) {
        store.dispatch('client_assessmentcare/filterTab19', bckUpData)
      } else {
        store.state.client_assessmentcare.ambulation = {}
      }
    },
  },
  setup(props, { emit }) {
    const pageTitle = 'Ambulation'
    const simpleRules = ref(null)
    const ambulation = ref(computed(() => store.state.client_assessmentcare.ambulation))
    const ambulationBckUp = ref(computed(() => store.state.client_assessmentcare.ambulationBckUp))
    const localUserRole = ref(props.userRole)
    const clientId = router.currentRoute.query.id ? router.currentRoute.query.id : ''
    const onClickNextTab = () => {
      emit('next-tab')
    }

    // options declaration with values
    const aidsOptions = [
      { id: 1, text: 'Cane', value: 'Cane' },
      { id: 2, text: 'Walker', value: 'Walker' },
      { id: 3, text: 'Wheel chair', value: 'Wheel chair' },
      { id: 4, text: 'Geri-chair', value: 'Geri-chair' },
      { id: 5, text: 'Scooter', value: 'Scooter' },
      { id: 6, text: 'Hoyer Lift', value: 'Hoyer Lift' },
    ]
    const fallRiskOptions = [
      { id: 1, text: 'Fall risk', value: 'Fall risk' },
      { id: 2, text: 'No History', value: 'No History' },
      { id: 3, text: 'Poor balance', value: 'Poor balance' },
    ]
    const useArmHandsOptions = [
      { id: 1, text: 'Left', value: 'Left' },
      { id: 2, text: 'Right', value: 'Right' },
    ]

    // *===============================================---*
    // *--------- SAVE DATA FUNCTION ---------------------*
    // *===============================================---*
    const onSaveData = () => {
      // const uData = localStorage.getItem('userData')
      // const uID = JSON.parse(uData)
      const formData = new FormData()
      const data = JSON.stringify({
        // client_id: uID.clientId,
        client_id: clientId,
        tab_name: pageTitle,
        aids: ambulation.value.aids,
        fall_risk: ambulation.value.fall_risk,
        use_of_arm_hands: ambulation.value.use_of_arm_hands,
        notes: ambulation.value.notes,
      })
      formData.append('data', data)
      store.dispatch('client_assessmentcare/insertData', formData)
        .then(() => {
          Swal.fire({
            icon: 'success',
            title: 'Updated Succesfully',
            showConfirmButton: false,
            timer: 1500,
            customClass: {
              confirmButton: 'btn btn-secondary',
            },
            buttonsStyling: false,
          })
          emit('fetch-my-data')
        })
        .catch(error => {
          console.error(error)
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
            showConfirmButton: false,
            timer: 1500,
          })
          // console.log('error', error)
        })
    }

    // *===============================================---*
    // *--------- VALIDATE THEN SAVE DATA ----------------*
    // *===============================================---*
    const validationForm = () => {
      simpleRules.value.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          // alert('form submitted!')
          onSaveData()
          // console.log('ref.simpleRules', simpleRules.value)
        } else {
          Swal.fire({
            icon: 'warning',
            title: 'Oops...',
            text: 'Make sure to fill up  the required fields',
            showConfirmButton: false,
            timer: 1500,
          })
        }
      })
    }
    return {
      pageTitle,
      simpleRules,
      localUserRole,
      ambulation,
      ambulationBckUp,
      aidsOptions,
      fallRiskOptions,
      useArmHandsOptions,
      onClickNextTab,
      validationForm,
      required,
    }
  },
}
</script>

<style>
.btn-reset:focus  {
  border: 1px solid #82868b !important;
  background-color:transparent !important;
  box-shadow: none !important;
}
.btn-reset:hover  {
  box-shadow: 0 8px 25px -8px #9c9fa0 !important;
}
</style>
