<template>
  <div>
    <b-media vertical-align="top">
      <template #aside>
        <b-avatar
          rounded
          variant="light-secondary"
          size="50"
          style="background-color: white; box-shadow: 1px 1px 1px #aaaaaa"
        >
          <img
            src="@/assets/images/icons/assessment-care/medical-icon-default.svg"
            width="28px"
            height="28px"
          >
        </b-avatar>
      </template>
      <h4 class="media-heading">
        {{ pageTitle }}
      </h4>
      <b-card-text class="mb-0">
        List of client's medical condition to take note of.
      </b-card-text>
    </b-media>
    <!-- form -->
    <b-card class="mt-2">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col md="12">
              <b-form-group
                label="List any chronic or acute conditions as well as recent hospital/skilled nursing stays"
                label-for=""
              >
                <validation-provider
                  #default="{ errors }"
                  name="conditions"
                  rules="required"
                >
                  <b-form-textarea
                    v-model="medConditions.conditions"
                    :state="errors.length > 0 ? false:null"
                    :value="medConditions.conditions"
                    :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                    rows="3s"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <template
              v-for="(senses,k) in medConditionsOptions"
            >
              <b-col
                :key="senses[k]"
                cols="12 row m-0 mt-1"
              >
                <label
                  for=""
                  class="col-sm-2 p-0 h6 toCapitalFirst"
                >
                  {{ k }}
                </label>
                <b-form-radio-group
                  v-model="medConditions[k]"
                  :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                  class=""
                >
                  <b-form-radio
                    v-for="option in senses"
                    :key="option.text"
                    :value="option.value"
                    class="mr-4"
                  >
                    {{ option.text }}
                  </b-form-radio>
                </b-form-radio-group>
              </b-col>
            </template>
          </b-row>
          <b-row>
            <b-col
              cols="12 row m-0 mt-1"
            >
              <label
                for=""
                class="col-sm-2 p-0 h6 toCapitalFirst"
              >
                Other
              </label>
              <b-form-checkbox-group
                v-model="medConditions.other"
                :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                class=""
              >
                <b-form-checkbox
                  v-for="option in otherConditionsOptions"
                  :key="option.text"
                  :value="option.value"
                  class="mr-4"
                >
                  {{ option.text }}
                </b-form-checkbox>
              </b-form-checkbox-group>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col
              v-if="localUserRole !== 'client' && localUserRole !== 'agent'"
              md="10"
            >
              <!-- Action Buttons -->
              <b-button
                variant="secondary"
                class="col-sm-2 mr-1"
                type="submit"
                @click.prevent="validationForm"
              >
                Save
              </b-button>
              <b-button
                variant="outline-dark"
                class="col-sm-2 btn-reset"
                @click="onReset()"
              >
                Reset
              </b-button>
            </b-col>
            <b-col
              v-if="localUserRole === 'client' || localUserRole === 'agent'"
              class="text-right"
            >
              <b-button
                class=""
                variant="secondary"
                @click="onClickNextTab()"
              >
                <span class="align-middle">Next</span>
                <feather-icon
                  icon="ChevronRightIcon"
                  class="ml-50"
                />
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BFormGroup, BForm, BRow, BCol, BButton,
  BFormTextarea, BFormRadioGroup, BFormRadio, BFormCheckboxGroup, BFormCheckbox,
  BMedia, BCard, BCardText, BAvatar,
} from 'bootstrap-vue'
import {
  computed,
  // watch,
  ref,
} from '@vue/composition-api'
import store from '@/store'
import Swal from 'sweetalert2'
import router from '@/router'
// import vSelect from 'vue-select'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    BFormGroup,
    BFormRadioGroup,
    BFormRadio,
    BFormCheckboxGroup,
    BFormCheckbox,
    // vSelect,
    BForm,
    BRow,
    BCol,
    BButton,
    BMedia,
    BCard,
    BCardText,
    BAvatar,
  },
  props: {
    userRole: {
      type: String,
      required: true,
    },
  },
  methods: {
    onReset() {
      const bckUpData = this.medConditionsBckUp
      if (bckUpData.length !== 0) {
        store.dispatch('client_assessmentcare/filterTab15', bckUpData)
      } else {
        store.state.client_assessmentcare.medConditions = {}
      }
    },
  },
  setup(props, { emit }) {
    const pageTitle = 'Medical Conditions'
    const localUserRole = ref(props.userRole)
    const clientId = router.currentRoute.query.id ? router.currentRoute.query.id : ''
    const medConditions = ref(computed(() => store.state.client_assessmentcare.medConditions))
    const medConditionsBckUp = ref(computed(() => store.state.client_assessmentcare.medConditionsBckUp))
    const simpleRules = ref(null)
    const form = ref(null)
    const row = ref(null)
    const onClickNextTab = () => {
      emit('next-tab')
    }

    const medConditionsOptions = {
      hearing: [
        { id: 1, text: 'Good', value: 'good' },
        { id: 2, text: 'Poor', value: 'poor' },
        { id: 3, text: 'Deaf', value: 'deaf' },
        { id: 4, text: 'Hearing aid', value: 'hearing_aid' },
      ],
      speech: [
        { id: 1, text: 'Good', value: 'good' },
        { id: 2, text: 'Poor', value: 'poor' },
        { id: 3, text: 'None', value: 'none' },
      ],
      vision: [
        { id: 1, text: 'Good', value: 'good' },
        { id: 2, text: 'Poor', value: 'poor' },
        { id: 3, text: 'Blind', value: 'blind' },
        { id: 4, text: 'Glasses for reading', value: 'glasses' },
      ],
      swallowing: [
        { id: 1, text: 'Good', value: 'good' },
        { id: 2, text: 'Poor', value: 'poor' },
        { id: 3, text: 'None', value: 'none' },
      ],
    }

    const otherConditionsOptions = [
      { id: 1, text: 'Smoker', value: 'smoker' },
      { id: 2, text: 'Sensitive to smell', value: 'sensitive_to_smell' },
      { id: 3, text: 'On oxygen', value: 'on_oxygen' },
      { id: 4, text: 'Colostomy', value: 'colostomy' },
      { id: 5, text: 'Feeding tube', value: 'feeding_tube' },
    ]

    // *===============================================---*
    // *--------- SAVE DATA ------------------------------*
    // *===============================================---*
    const onSaveData = () => {
      // const uData = localStorage.getItem('userData')
      // const uID = JSON.parse(uData)
      const formData = new FormData()
      const data = JSON.stringify({
        // client_id: uID.clientId,
        client_id: clientId,
        tab_name: pageTitle,
        conditions: medConditions.value.conditions,
        hearing: medConditions.value.hearing,
        speech: medConditions.value.speech,
        vision: medConditions.value.vision,
        swallowing: medConditions.value.swallowing,
        other: medConditions.value.other,
      })
      formData.append('data', data)
      store.dispatch('client_assessmentcare/insertData', formData)
        .then(() => {
          Swal.fire({
            icon: 'success',
            title: 'Updated Succesfully',
            showConfirmButton: false,
            timer: 1500,
            customClass: {
              confirmButton: 'btn btn-secondary',
            },
            buttonsStyling: false,
          })
          emit('fetch-my-data')
        })
        .catch(error => {
          console.error(error)
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
            showConfirmButton: false,
            timer: 1500,
          })
          // console.log('error', error)
        })
    }
    const validationForm = () => {
      simpleRules.value.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          // alert('form submitted!')
          onSaveData()
          // console.log('ref.simpleRules', simpleRules.value)
        } else {
          Swal.fire({
            icon: 'warning',
            title: 'Oops...',
            text: 'Make sure to fill up  the required fields',
            showConfirmButton: false,
            timer: 1500,
          })
        }
      })
    }
    return {
      pageTitle,
      medConditions,
      medConditionsBckUp,
      medConditionsOptions,
      otherConditionsOptions,
      localUserRole,
      onClickNextTab,
      validationForm,
      // refs
      simpleRules,
      form,
      row,
      required,
    }
  },
  /* computed: {
    sensesVal: {
      get () {
        if (this.medConditions) {
          return this.valueTrue
        } else {
          return this.valueFalse
        }
      },
      set (val) {
        if (this.selected) {
          this.valueTrue = val
        } else {
          this.valueFalse = val
        }
      }
    },
  }, */
}
</script>
<style scoped>
.toCapitalFirst {
  text-transform: capitalize;
}
</style>

<style>
.btn-reset:focus  {
  border: 1px solid #82868b !important;
  background-color:transparent !important;
  box-shadow: none !important;
}
.btn-reset:hover  {
  box-shadow: 0 8px 25px -8px #9c9fa0 !important;
}
</style>
