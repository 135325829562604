<template>
  <div>
    <b-media vertical-align="top">
      <template #aside>
        <b-avatar
          rounded
          variant="light-secondary"
          size="50"
          style="background-color: white; box-shadow: 1px 1px 1px #aaaaaa"
        >
          <img
            src="@/assets/images/icons/assessment-care/demographics-icon-default.svg"
            width="28px"
            height="28px"
          >
        </b-avatar>
      </template>
      <h4 class="media-heading">
        {{ pageTitle }}
      </h4>
      <b-card-text class="mb-0">
        Client's identifying information.
      </b-card-text>
    </b-media>
    <!-- form -->
    <b-card class="mt-2">
      <validation-observer ref="simpleRulesDemographics">
        <b-form>
          <b-row>
            <b-col md="4">
              <b-form-group
                label="Date of Birth"
                label-for="date-of-birth"
              >
                <!-- <validation-provider
                  #default="{ errors }"
                  name="Date of Birth"
                  rules="required"
                > -->
                <flat-pickr
                  v-model="demographics.date_of_birth"
                  :config="{ altInput: true, altFormat: 'F j, Y', dateFormat: 'Y-m-d',}"
                  :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                />
                <!-- <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider> -->
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group
                label="Weight (lb)"
                label-for="weight"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Weight"
                  rules="required"
                >
                  <b-form-input
                    v-model="demographics.weight"
                    v-mask="['###.##']"
                    :state="errors.length > 0 ? false:null"
                    :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group
                label="Height (cm)"
                label-for="height"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Height"
                  rules="required"
                >
                  <b-form-input
                    v-model="demographics.height"
                    v-mask="['###']"
                    :state="errors.length > 0 ? false : null"
                    :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Marital Status"
                label-for="marital-status"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Marital Status"
                  rules="required"
                >
                  <!-- <b-form-input
                    v-model="demographics.marital_status"
                    :state="errors.length > 0 ? false:null"
                  /> -->
                  <v-select
                    v-model="demographics.marital_status"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :state="errors.length > 0 ? false:null"
                    :options="maritalStatusOptions"
                    :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
              <b-form-group
                label="Spouse Name"
                label-for="spouse-name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Spouse Name"
                  rules="required"
                >
                  <b-form-input
                    v-model="demographics.spouse_name"
                    :state="errors.length > 0 ? false:null"
                    :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group
                label="Live With"
                label-for="live-with"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Live With"
                  rules="required"
                >
                  <b-form-input
                    v-model="demographics.live_with"
                    :state="errors.length > 0 ? false:null"
                    :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-form-group
                label="Religion"
                label-for="religion"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Religion"
                  rules="required"
                >
                  <b-form-input
                    v-model="demographics.religion"
                    :state="errors.length > 0 ? false:null"
                    :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                label="Attend Services"
                label-for="attend-services"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Attend Services"
                  rules="required"
                >
                  <b-form-input
                    v-model="demographics.attend_services"
                    :state="errors.length > 0 ? false:null"
                    :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
              <b-form-group
                label="DNR"
                label-for="dnr"
              >
                <validation-provider
                  #default="{ errors }"
                  name="DNR"
                  rules="required"
                >
                  <b-form-radio-group
                    v-model="demographics.dnr"
                    name="dnr"
                    :state="errors.length > 0 ? false : null"
                  >
                    <b-form-radio
                      v-for="(option, index) in demoShiftTypeOptions"
                      :key="index"
                      :value="option.value"
                      :class="index !== 3 ? 'mr-2' : 'mr-0'"
                      :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                    >
                      {{ option.text }}
                    </b-form-radio>
                    <br><small
                      v-if="localUserRole !== 'client' && localUserRole !== 'agent' "
                      class="text-danger"
                    >{{ errors[0] }}</small>
                  </b-form-radio-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="8">
              <b-form-group
                label="Languages"
                label-for="languages"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Languages"
                  rules="required"
                >
                  <!-- <b-form-input
                    v-model="demographics.languages"
                    :state="errors.length > 0 ? false : null"
                    :disabled="localUserRole === 'admin' ? true : false"
                  /> -->
                  <b-form-tags
                    v-model="demographics.languages"
                    separator=""
                    placeholder=""
                    add-on-change
                    remove-on-delete
                    tag-remove-label="Remove tag"
                    :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col
              v-if="localUserRole !== 'client' && localUserRole !== 'agent' "
              md="10"
            >
              <!-- Action Buttons -->
              <b-button
                variant="secondary"
                class="col-sm-3 mr-1"
                type="submit"
                @click.prevent="validationForm"
              >
                Save Changes
              </b-button>
              <b-button
                variant="outline-dark"
                class="col-sm-2 btn-reset"
                @click="onReset()"
              >
                Reset
              </b-button>
            </b-col>
            <b-col
              v-if="localUserRole === 'client' || localUserRole === 'agent'"
              class="text-right"
            >
              <b-button
                class=""
                variant="secondary"
                @click="onClickNextTab()"
              >
                <span class="align-middle">Next</span>
                <feather-icon
                  icon="ChevronRightIcon"
                  class="ml-50"
                />
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BFormRadioGroup, BFormRadio, BFormTags,
  BMedia, BCard, BCardText, BAvatar,
} from 'bootstrap-vue'
import {
  computed,
  // eslint-disable-next-line no-unused-vars
  watch,
  ref,
} from '@vue/composition-api'
import vSelect from 'vue-select'
import Swal from 'sweetalert2'
import router from '@/router'
import flatPickr from 'vue-flatpickr-component'
import { mask } from 'vue-the-mask'
import store from '@/store'
import { required } from '@validations'
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BFormRadio,
    BFormRadioGroup,
    BFormTags,
    BRow,
    BCol,
    BButton,
    BMedia,
    BCard,
    BCardText,
    BAvatar,
    flatPickr,
    vSelect,
  },
  directives: {
    mask,
  },
  props: {
    userRole: {
      type: String,
      required: true,
    },
  },
  methods: {
    onReset() {
      const bckUpData = this.demographicsBckUp
      if (bckUpData.length !== 0) {
        store.dispatch('client_assessmentcare/filterTab2', bckUpData)
      } else {
        store.state.client_assessmentcare.demographics = {
          date_of_birth: '',
          weight: '',
          height: '',
          marital_status: '',
          spouse_name: '',
          live_with: '',
          religion: '',
          attend_services: '',
          dnr: '',
          languages: '',
        }
      }
    },
  },
  setup(props, { emit }) {
    const pageTitle = 'Demographics'
    const simpleRulesDemographics = ref(null)
    const demographics = ref(computed(() => store.state.client_assessmentcare.demographics))
    const demographicsBckUp = ref(computed(() => store.state.client_assessmentcare.demographicsBckUp))
    const localUserRole = ref(props.userRole)
    const clientId = router.currentRoute.query.id ? router.currentRoute.query.id : ''
    const onClickNextTab = () => {
      emit('next-tab')
    }
    const demoShiftTypeOptions = [
      { text: 'Yes', value: 'Yes' },
      { text: 'No', value: 'No' },
      { text: 'Unknown', value: 'Unknown' },
    ]
    const maritalStatusOptions = ref([
      { label: 'Single', value: 'Single' },
      { label: 'Married', value: 'Married' },
      { label: 'Widowed', value: 'Widowed' },
      { label: 'Divorced', value: 'Divorced' },
      { label: 'Separated', value: 'Separated' },
      { label: 'Registered Partnership', value: 'Registered Partnership' },
    ])

    // *===============================================---*
    // *--------- SAVE DATA ------------------------------*
    // *===============================================---*
    const onSaveData = () => {
      // const uData = localStorage.getItem('userData')
      // const uID = JSON.parse(uData)
      // console.log('user data', )
      const formData = new FormData()
      const data = JSON.stringify({
        // client_id: uID.clientId,
        client_id: clientId,
        tab_name: pageTitle,
        date_of_birth: demographics.value.date_of_birth,
        weight: demographics.value.weight,
        height: demographics.value.height,
        marital_status: demographics.value.marital_status,
        spouse_name: demographics.value.spouse_name,
        live_with: demographics.value.live_with,
        religion: demographics.value.religion,
        attend_services: demographics.value.attend_services,
        dnr: demographics.value.dnr,
        languages: demographics.value.languages,
      })
      formData.append('data', data)
      // console.log('uData.id', formData)
      store.dispatch('client_assessmentcare/insertData', formData)
        .then(() => {
          Swal.fire({
            icon: 'success',
            title: 'Updated Succesfully',
            showConfirmButton: false,
            timer: 1500,
            customClass: {
              confirmButton: 'btn btn-secondary',
            },
            buttonsStyling: false,
          })
          emit('fetch-my-data')
        })
        .catch(error => {
          console.error(error)
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
            showConfirmButton: false,
            timer: 1500,
          })
          // console.log('error', error)
        })
    }
    const validationForm = () => {
      simpleRulesDemographics.value.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          // alert('form submitted!')
          onSaveData()
          // console.log('ref.simpleRules', simpleRules.value)
        } else {
          Swal.fire({
            icon: 'warning',
            title: 'Oops...',
            text: 'Make sure to fill up  the required fields',
            showConfirmButton: false,
            timer: 1500,
          })
        }
      })
    }
    // const onClear = () => {
    //   store.state.client_assessmentcare.demographics = {
    //     date_of_birth: '',
    //     weight: '',
    //     height: '',
    //     marital_status: '',
    //     spouse_name: '',
    //     live_with: '',
    //     religion: '',
    //     attend_services: '',
    //     dnr: '',
    //     languages: '',
    //   }
    // }
    return {
      pageTitle,
      demographics,
      demographicsBckUp,
      demoShiftTypeOptions,
      maritalStatusOptions,
      simpleRulesDemographics,
      required,
      validationForm,
      onClickNextTab,
      // onClear,
      localUserRole,
    }
  },
}
// *===============================================---*
// *--------- CUSTOM VALIDATION ----------------------*
// *===============================================---*
export const validatorHeight = value => {
  /* eslint-disable no-useless-escape */
  const cRegExp = /\d+(\.\d{1,3})?/
  /* eslint-enable no-useless-escape */
  const isValidHeight = cRegExp.test(value)
  return isValidHeight
}
export const validHeight = extend('validHeight', {
  validate: validatorHeight,
  message: 'Please input valid height.',
})
/* End of custom validation */
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style>
.btn-reset:focus  {
  border: 1px solid #82868b !important;
  background-color:transparent !important;
  box-shadow: none !important;
}
.btn-reset:hover  {
  box-shadow: 0 8px 25px -8px #9c9fa0 !important;
}
[dir] .pb-0, [dir] .py-0 {
  padding-bottom: unset !important;
}
</style>
