<template>
  <div>
    <b-media vertical-align="top">
      <template #aside>
        <b-avatar
          rounded
          variant="light-secondary"
          size="50"
          style="background-color: white; box-shadow: 1px 1px 1px #aaaaaa"
        >
          <img
            src="@/assets/images/icons/assessment-care/prescription-icon-default.svg"
            width="28px"
            height="28px"
          >
        </b-avatar>
      </template>
      <h4 class="media-heading">
        {{ pageTitle }}
      </h4>
      <b-card-text class="mb-0">
        Doctor/Physician prescribed medications for client's health conditions. <br>
        <span class="font-weight-bolder">note:</span> For Hospice Patients, please refer to Hospice Binder
      </b-card-text>
    </b-media>
    <!-- form -->
    <b-card class="mt-1">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col md="4">
              <b-form-group
                label="Drug"
                label-for="drug"
                class="h2"
              />
            </b-col>
            <b-col md="2">
              <b-form-group
                label="Dose"
                label-for="dose"
                class="h2"
              />
            </b-col>
            <b-col md="2">
              <b-form-group
                label="Time"
                label-for="time"
                class="h2"
              />
            </b-col>
            <b-col md="4">
              <b-form-group
                label="M.D."
                label-for="m.d."
                class="h2"
              />
            </b-col>
            <b-col md="12">
              <div>
                <div
                  ref="form"
                  :style="{height: trHeight}"
                  class="repeater-form h-auto"
                >

                  <!-- Row Loop -->
                  <b-row
                    v-for="(item, index) in presscriptMed.presscriptMedData"
                    :key="index"
                    ref="row"
                  >
                    <!-- <h6>{{ presscriptMed.presscriptMedData }}</h6> -->
                    <!-- Drug -->
                    <b-col md="4">
                      <b-form-group
                        label=""
                        label-for=""
                      >
                        <b-form-input
                          v-model="item.pre_drug"
                          name="item-name"
                          type="text"
                          :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                        />
                      </b-form-group>
                    </b-col>

                    <!-- Dose -->
                    <b-col md="2">
                      <b-form-group
                        label=""
                        label-for=""
                      >
                        <b-form-input
                          v-model="item.pre_dose"
                          name="dose"
                          :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                        />
                      </b-form-group>
                    </b-col>

                    <!-- Time -->
                    <b-col md="2">
                      <b-form-group
                        label=""
                        label-for=""
                      >
                        <b-form-timepicker
                          v-model="item.pre_time"
                          now-button
                          reset-button
                          locale="en"
                          placeholder=""
                          name="time"
                          :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                        />
                      </b-form-group>
                    </b-col>

                    <!-- MD -->
                    <b-col
                      md="4"
                    >
                      <b-form-group
                        label=""
                        label-for=""
                      >
                        <b-form-input
                          v-model="item.pre_md"
                          name="md"
                          type="text"
                          :disabled="localUserRole === 'client' || localUserRole === 'agent' ? true : false"
                        />
                      </b-form-group>
                    </b-col>

                    <!-- Remove Button -->
                    <!-- <b-col
                      lg="2"
                      md="3"
                      class="mb-50"
                    >
                      <b-button
                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                        variant="outline-danger"
                        class="mt-0 mt-md-2"
                        @click="removeItem(index)"
                      >
                        <feather-icon
                          icon="XIcon"
                          class="mr-25"
                        />
                        <span>Delete</span>
                      </b-button>
                    </b-col> -->
                  </b-row>

                </div>
              </div>
            </b-col>
            <b-col cols="12">
              <hr>
            </b-col>
            <b-col v-if="localUserRole !== 'client' && localUserRole !== 'agent' ? true : false">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="secondary"
                @click="repeateAgain"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>Add New</span>
              </b-button>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col
              v-if="localUserRole !== 'client' && localUserRole !== 'agent' "
              md="10"
            >
              <!-- Action Buttons -->
              <b-button
                variant="secondary"
                class="col-sm-3 mr-1"
                type="submit"
                @click.prevent="validationForm"
              >
                Save Changes
              </b-button>
              <b-button
                variant="outline-dark"
                class="col-sm-2 btn-reset"
                @click="onReset()"
              >
                Reset
              </b-button>
            </b-col>
            <b-col
              v-if="localUserRole === 'client' || localUserRole === 'agent'"
              class="text-right"
            >
              <b-button
                class=""
                variant="secondary"
                @click="onClickNextTab()"
              >
                <span class="align-middle">Next</span>
                <feather-icon
                  icon="ChevronRightIcon"
                  class="ml-50"
                />
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BFormInput, BFormGroup, BForm, BFormTimepicker, BRow, BCol, BButton,
  BMedia, BCard, BCardText, BAvatar,
} from 'bootstrap-vue'
import {
  computed,
  // watch,
  ref,
} from '@vue/composition-api'
import store from '@/store'
import Swal from 'sweetalert2'
import router from '@/router'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BFormTimepicker,
    BForm,
    BRow,
    BCol,
    BButton,
    BMedia,
    BCard,
    BCardText,
    BAvatar,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    userRole: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    repeateAgain() {
      this.form.style.overflow = 'hidden'
      this.presscriptMed.presscriptMedData.push(JSON.parse(JSON.stringify(this.emptyItems)))

      this.$nextTick(() => {
        this.trAddHeight(this.row[0].offsetHeight)
        setTimeout(() => {
          this.form.style.overflow = null
        }, 350)
      })
      // console.log('repeateAgain TrHeight', this.trHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.form.scrollHeight)
      })
      // console.log('initTrHeight trHeight:', this.trHeight)
    },
    onReset() {
      const bckUpData = this.presscriptMedBckUp
      if (bckUpData.length !== 0) {
        store.dispatch('client_assessmentcare/filterTab20', bckUpData)
        // console.log('filterTab20', bckUpData)
      } else {
        store.state.client_assessmentcare.presscriptMed = {
          presscriptMedData: [
            {
              pre_drug: '',
              pre_dose: '',
              pre_time: '',
              pre_md: '',
            },
            {
              pre_drug: '',
              pre_dose: '',
              pre_time: '',
              pre_md: '',
            },
            {
              pre_drug: '',
              pre_dose: '',
              pre_time: '',
              pre_md: '',
            },
            {
              pre_drug: '',
              pre_dose: '',
              pre_time: '',
              pre_md: '',
            },
            {
              pre_drug: '',
              pre_dose: '',
              pre_time: '',
              pre_md: '',
            },
          ],
        }
      }
    },
  },
  setup(props, { emit }) {
    const pageTitle = 'Prescription Medication'
    const simpleRules = ref(null)
    const localUserRole = ref(props.userRole)
    const clientId = router.currentRoute.query.id ? router.currentRoute.query.id : ''
    const presscriptMed = ref(computed(() => store.state.client_assessmentcare.presscriptMed))
    const presscriptMedBckUp = ref(computed(() => store.state.client_assessmentcare.presscriptMedBckUp))
    const form = ref(null)
    const row = ref(null)
    // const nextTodoId = 2
    const emptyItems = {
      pre_drug: '',
      pre_dose: '',
      pre_time: '',
      pre_md: '',
    }
    const onClickNextTab = () => {
      emit('next-tab')
    }

    // *===============================================---*
    // *--------- SAVE DATA FUNCTION ---------------------*
    // *===============================================---*
    const onSaveData = () => {
      // const uData = localStorage.getItem('userData')
      // const uID = JSON.parse(uData)
      const formData = new FormData()
      const data = JSON.stringify({
        // client_id: uID.clientId,
        client_id: clientId,
        tab_name: pageTitle,
        presscriptMedData: presscriptMed.value.presscriptMedData,
      })
      formData.append('data', data)
      // console.log('formData', data)
      store.dispatch('client_assessmentcare/insertData', formData)
        .then(() => {
          Swal.fire({
            icon: 'success',
            title: 'Updated Succesfully',
            showConfirmButton: false,
            timer: 1500,
            customClass: {
              confirmButton: 'btn btn-secondary',
            },
            buttonsStyling: false,
          })
          emit('fetch-my-data')
        })
        .catch(error => {
          console.error(error)
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
            showConfirmButton: false,
            timer: 1500,
          })
          // console.log('error', error)
        })
    }

    // *===============================================---*
    // *--------- VALIDATE THEN SAVE DATA ----------------*
    // *===============================================---*
    const validationForm = () => {
      simpleRules.value.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          // alert('form submitted!')
          onSaveData()
          // console.log('ref.simpleRules', simpleRules.value)
        } else {
          Swal.fire({
            icon: 'warning',
            title: 'Oops...',
            text: 'Make sure to fill up  the required fields',
            showConfirmButton: false,
            timer: 1500,
          })
        }
      })
    }
    // const onClear = () => {
    //   store.state.client_assessmentcare.presscriptMed = {
    //     presscriptMedData: [
    //       {
    //         pre_drug: '',
    //         pre_dose: '',
    //         pre_time: '',
    //         pre_md: '',
    //       },
    //       {
    //         pre_drug: '',
    //         pre_dose: '',
    //         pre_time: '',
    //         pre_md: '',
    //       },
    //       {
    //         pre_drug: '',
    //         pre_dose: '',
    //         pre_time: '',
    //         pre_md: '',
    //       },
    //       {
    //         pre_drug: '',
    //         pre_dose: '',
    //         pre_time: '',
    //         pre_md: '',
    //       },
    //       {
    //         pre_drug: '',
    //         pre_dose: '',
    //         pre_time: '',
    //         pre_md: '',
    //       },
    //     ],
    //   }
    // }
    return {
      pageTitle,
      simpleRules,
      localUserRole,
      presscriptMed,
      presscriptMedBckUp,
      emptyItems,
      // nextTodoId,
      form,
      row,
      onClickNextTab,
      // onClear,
      validationForm,
      required,
    }
  },
}
</script>
<style lang="scss" scoped>
.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}
</style>

<style>
.btn-reset:focus  {
  border: 1px solid #82868b !important;
  background-color:transparent !important;
  box-shadow: none !important;
}
.btn-reset:hover  {
  box-shadow: 0 8px 25px -8px #9c9fa0 !important;
}
</style>
