/* eslint-disable no-return-assign */
import axios from '@axios'
import Vue from 'vue'
import { reactive } from '@vue/composition-api'

export default {
  namespaced: true,
  state: reactive({
    tabIndex: 0,
    id: '',
    uRole: '',
    // data for 1st tab
    careNeeds: {
      initial_contact: '',
      start_date: '',
      // care_goals: '',
      shift_type: '',
      shift_type_input: '',
      selected_days: [],
      sun_selected_hours: [{
        start_time: '',
        end_time: '',
      }],
      mon_selected_hours: [{
        start_time: '',
        end_time: '',
      }],
      tue_selected_hours: [{
        start_time: '',
        end_time: '',
      }],
      wed_selected_hours: [{
        start_time: '',
        end_time: '',
      }],
      th_selected_hours: [{
        start_time: '',
        end_time: '',
      }],
      fri_selected_hours: [{
        start_time: '',
        end_time: '',
      }],
      sat_selected_hours: [{
        start_time: '',
        end_time: '',
      }],
    },
    careNeedsBckUp: {},
    empCareNeeds: {
      initial_contact: '',
      start_date: '',
      // care_goals: '',
      shift_type: '',
      shift_type_input: '',
      selected_days: [],
      sun_selected_hours: [{
        start_time: '',
        end_time: '',
      }],
      mon_selected_hours: [{
        start_time: '',
        end_time: '',
      }],
      tue_selected_hours: [{
        start_time: '',
        end_time: '',
      }],
      wed_selected_hours: [{
        start_time: '',
        end_time: '',
      }],
      th_selected_hours: [{
        start_time: '',
        end_time: '',
      }],
      fri_selected_hours: [{
        start_time: '',
        end_time: '',
      }],
      sat_selected_hours: [{
        start_time: '',
        end_time: '',
      }],
    },
    // data for 2nd tab
    demographics: {
      date_of_birth: '',
      weight: '',
      height: '',
      marital_status: '',
      spouse_name: '',
      live_with: '',
      religion: '',
      attend_services: '',
      dnr: '',
      languages: [],
    },
    demographicsBckUp: {},
    // data for 3rd tab
    briefHistory: {
      current_residence: '',
      where_from: '',
      children: '',
      other_sig_events: '',
      history_of_disabilities: '',
    },
    briefHistoryBckUp: {},
    // data for 4th tab
    actOfDailyLiving: {
      AT_assist_with_transfers_Yn: 'No',
      AT_walk_with_client_Yn: 'No',
      B_assist_in_out_shower_Yn: 'No',
      B_bathe_client_Yn: 'No',
      B_minor_bath_Yn: 'No',
      B_shampoo_hair_Yn: 'No',
      C_change_diapers_Yn: 'No',
      DG_assist_dressing_Yn: 'No',
      DG_brush_teeth_Yn: 'No',
      DG_comb_dress_Yn: 'No',
      T_assist_toileting_Yn: 'No',
      T_clean_bathroom_Yn: 'No',
      T_take_to_toilet_Yn: 'No',
    },
    actOfDailyLivingBckUp: {},
    withValActOfDailyLiving: {
      AT_assist_with_transfers_Yn: 'No',
      AT_walk_with_client_Yn: 'No',
      B_assist_in_out_shower_Yn: 'No',
      B_bathe_client_Yn: 'No',
      B_minor_bath_Yn: 'No',
      B_shampoo_hair_Yn: 'No',
      C_change_diapers_Yn: 'No',
      DG_assist_dressing_Yn: 'No',
      DG_brush_teeth_Yn: 'No',
      DG_comb_dress_Yn: 'No',
      T_assist_toileting_Yn: 'No',
      T_clean_bathroom_Yn: 'No',
      T_take_to_toilet_Yn: 'No',
    },
    // empActOfDailyLiving: {
    //   AT_assist_with_transfers_Yn: '',
    //   AT_walk_with_client_Yn: '',
    //   B_assist_in_out_shower_Yn: '',
    //   B_bathe_client_Yn: '',
    //   B_minor_bath_Yn: '',
    //   B_shampoo_hair_Yn: '',
    //   C_change_diapers_Yn: '',
    //   DG_assist_dressing_Yn: '',
    //   DG_brush_teeth_Yn: '',
    //   DG_comb_dress_Yn: '',
    //   T_assist_toileting_Yn: '',
    //   T_clean_bathroom_Yn: '',
    //   T_take_to_toilet_Yn: '',
    // },
    // data for 5th tab
    inActOfDailyLiving: {
      HW_bed_lines_bathroom_Yn: 'No',
      HW_living_room_bed_Yn: 'No',
      HW_sweeping_mopping_vacuuming_floor_Yn: 'No',
      L_Laundry_Yn: 'No',
      PM_clear_dishes_Yn: 'No',
      PM_prep_meals_Yn: 'No',
      SHP_grocery_shop_Yn: 'No',
      SHP_pick_up_med_Yn: 'No',
      TR_dri_appointment_Yn: 'No',
      TR_dri_errands_Yn: 'No',
    },
    inActOfDailyLivingBckUp: {},
    withValInActOfDailyLiving: {
      HW_bed_lines_bathroom_Yn: 'No',
      HW_living_room_bed_Yn: 'No',
      HW_sweeping_mopping_vacuuming_floor_Yn: 'No',
      L_Laundry_Yn: 'No',
      PM_clear_dishes_Yn: 'No',
      PM_prep_meals_Yn: 'No',
      SHP_grocery_shop_Yn: 'No',
      SHP_pick_up_med_Yn: 'No',
      TR_dri_appointment_Yn: 'No',
      TR_dri_errands_Yn: 'No',
    },
    empInActOfDailyLiving: {
      HW_bed_lines_bathroom_Yn: '',
      HW_living_room_bed_Yn: '',
      HW_sweeping_mopping_vacuuming_floor_Yn: '',
      L_Laundry_Yn: '',
      PM_clear_dishes_Yn: '',
      PM_prep_meals_Yn: '',
      SHP_grocery_shop_Yn: '',
      SHP_pick_up_med_Yn: '',
      TR_dri_appointment_Yn: '',
      TR_dri_errands_Yn: '',
    },
    // data for 6th tab
    preferredAct: {
      fav_act_can_do: '',
      fav_act_cant_do: '',
      interest_hob: '',
      entertainment_pref: '',
      club_org: [],
      club_org_day_set: [],
      club_org_time_set: '',
      church: [],
      church_day_set: [],
      church_time_set: '',
      reg_app_social_eng: '',
      other_social_act: '',
      fam_in_area_Yn: '',
      fam_in_area_visit_how_often: '',
      reg_friends_and_visitors_Yn: '',
      reg_friends_and_visitor_visit_how_often: '',
      pets: [{
        type: '',
        name: '',
        req_for_pets: '',
        veterinarian_name: '',
        veterinarian_number: '',
      }],
    },
    preferredActBckUp: {},
    empPreferredAct: {
      fav_act_can_do: '',
      fav_act_cant_do: '',
      interest_hob: '',
      entertainment_pref: '',
      club_org: [],
      club_org_day_set: [],
      club_org_time_set: '',
      church: [],
      church_day_set: [],
      church_time_set: '',
      reg_app_social_eng: '',
      other_social_act: '',
      fam_in_area_Yn: '',
      fam_in_area_visit_how_often: '',
      reg_friends_and_visitors_Yn: '',
      reg_friends_and_visitor_visit_how_often: '',
      pets: [{
        type: '',
        name: '',
        req_for_pets: '',
        veterinarian_name: '',
        veterinarian_number: '',
      }],
    },
    // data for 7th tab
    meals: {
      dietary_concers: '',
      typically_eats: [],
      typically_eats_breakfast: '',
      typically_eats_lunch: '',
      typically_eats_dinner: '',
      typically_eats_snacks: '',
      person_cooks: [],
      other_food_provider: [],
      fav_food: '',
    },
    mealsBckUp: {},
    empMeals: {
      dietary_concers: '',
      typically_eats: [],
      typically_eats_breakfast: '',
      typically_eats_lunch: '',
      typically_eats_dinner: '',
      typically_eats_snacks: '',
      person_cooks: [],
      other_food_provider: [],
      fav_food: '',
    },
    // data for 8th tab
    dressings: {
      dressings: '',
    },
    dressingsBckUp: {},
    // data for 9th tab
    bathing: {
      bathing_opts: [],
      no_assistance_necessary: '',
      family_will_bathe: '',
      bathing_assistance: '',
      monitoring_only: '',
      compliant: '',
      non_compliant: '',
    },
    bathingBckUp: {},
    empBathing: {
      bathing_opts: [],
      no_assistance_necessary: '',
      family_will_bathe: '',
      bathing_assistance: '',
      monitoring_only: '',
      compliant: '',
      non_compliant: '',
    },
    // data for 10th tab
    laundry: {
      who_does: '',
      how_often: '',
    },
    laundryBckUp: {},
    // data for 11th tab
    housekeeping: {
      who_does: '',
      how_often: '',
      areas: '',
    },
    housekeepingBckUp: {},
    // data for 12th tab
    sleepHabits: {
      bedtime: '',
      arises: '',
      noc_day_opts: [],
      nocturnal: '',
      daytime: '',
    },
    sleepHabitsBckUp: {},
    empSleepHabits: {
      bedtime: '',
      arises: '',
      noc_day_opts: [],
      nocturnal: '',
      daytime: '',
    },
    // data for 13th tab
    futurePlansToGoals: {
      future_plans_to_goals: '',
    },
    futurePlansToGoalsBckUp: {},
    // data for 14th tab
    greatestFears: {
      greatest_fears: '',
    },
    greatestFearsBckUp: {},
    // data for 15th tab
    medConditions: {
      conditions: '',
      hearing: '',
      speech: '',
      vision: '',
      swallowing: '',
      other: [],
    },
    medConditionsBckUp: {},
    // data for 16th tab
    mentalbehaviorConditions: {
      diag_disorders_medications: '',
      can_be_left_alone: '',
      wanderer: '',
      dementia: '',
      symptoms: [],
      triggers: '',
    },
    mentalbehaviorConditionsBckUp: {},
    // data for 17th tab
    allergies: {
      allergies: '',
      notes: '',
    },
    allergiesBckUp: {},
    // data for 18th tab
    elimination: {
      incontinence: [],
      issues: [],
      wear_briefs: '',
      underpads: '',
      notes: '',
    },
    eliminationBckUp: {},
    // data for 19th tab
    ambulation: {
      aids: [],
      fall_risk: [],
      use_of_arm_hands: [],
      notes: '',
    },
    ambulationBckUp: {},
    // empAmbulation: {
    //   aids: [],
    //   fall_risk: ['No History'],
    //   use_of_arm_hands: [],
    //   notes: '',
    // },
    // data for 20th tab
    presscriptMed: {
      presscriptMedData: [
        {
          pre_drug: '',
          pre_dose: '',
          pre_time: '',
          pre_md: '',
        },
        {
          pre_drug: '',
          pre_dose: '',
          pre_time: '',
          pre_md: '',
        },
        {
          pre_drug: '',
          pre_dose: '',
          pre_time: '',
          pre_md: '',
        },
        {
          pre_drug: '',
          pre_dose: '',
          pre_time: '',
          pre_md: '',
        },
        {
          pre_drug: '',
          pre_dose: '',
          pre_time: '',
          pre_md: '',
        },
      ],
    },
    presscriptMedBckUp: {},
    empPresscriptMed: {
      presscriptMedData: [
        {
          pre_drug: '',
          pre_dose: '',
          pre_time: '',
          pre_md: '',
        },
        {
          pre_drug: '',
          pre_dose: '',
          pre_time: '',
          pre_md: '',
        },
        {
          pre_drug: '',
          pre_dose: '',
          pre_time: '',
          pre_md: '',
        },
        {
          pre_drug: '',
          pre_dose: '',
          pre_time: '',
          pre_md: '',
        },
        {
          pre_drug: '',
          pre_dose: '',
          pre_time: '',
          pre_md: '',
        },
      ],
    },
    // data for 21th tab
    otcMedOthers: {
      otcMedOthersData: [
        {
          otc_drug: '',
          otc_dose: '',
          otc_time: '',
          otc_md: '',
        },
        {
          otc_drug: '',
          otc_dose: '',
          otc_time: '',
          otc_md: '',
        },
        {
          otc_drug: '',
          otc_dose: '',
          otc_time: '',
          otc_md: '',
        },
        {
          otc_drug: '',
          otc_dose: '',
          otc_time: '',
          otc_md: '',
        },
        {
          otc_drug: '',
          otc_dose: '',
          otc_time: '',
          otc_md: '',
        },
      ],
    },
    otcMedOthersBckUp: {},
    empOTC: {
      otcMedOthersData: [
        {
          otc_drug: '',
          otc_dose: '',
          otc_time: '',
          otc_md: '',
        },
        {
          otc_drug: '',
          otc_dose: '',
          otc_time: '',
          otc_md: '',
        },
        {
          otc_drug: '',
          otc_dose: '',
          otc_time: '',
          otc_md: '',
        },
        {
          otc_drug: '',
          otc_dose: '',
          otc_time: '',
          otc_md: '',
        },
        {
          otc_drug: '',
          otc_dose: '',
          otc_time: '',
          otc_md: '',
        },
      ],
    },
    // data for 22th tab
    supplements: {
      supplementData: '',
    },
    supplementsBckUp: {},
    // data for 23th tab
    specialMedInstruc: {
      specialMedInstrucData: '',
    },
    specialMedInstrucBckUp: {},
    // data for 24th tab
    pharmacy: {
      location: '',
      phone: '',
    },
    pharmacyBckUp: {},
    // data for 25th tab
    physician: {
      primary_physician_name: '',
      primary_physician_address: '',
      primary_physician_phone: '',
      primary_physician_nurse: '',
      other_physician_name: '',
      other_physician_address: '',
      other_physician_phone: '',
      other_physician_nurse: '',
    },
    physicianBckUp: {},
    // data for 26th tab
    eyeglasses: {
      eye_doc: '',
      phone: '',
    },
    eyeglassesBckUp: {},
    // data for 27th tab
    dental: {
      dentist: '',
      phone: '',
    },
    dentalBckUp: {},
    // data for 28th tab
    legalDoc: {
      legal_doc: [],
      do_not_resuscitate: [],
      instructions: '',
    },
    legalDocBckUp: {},
    // data for 29th tab
    longTermCareCoverage: {
      long_term_coverage: '',
      company: '',
      phone: '',
      policy: '',
      covered: '',
      not_covered: '',
    },
    longTermCareCoverageBckUp: {},
    // data for 30th admin tab
    determination: {
      determinationYN: '',
    },
    determinationBckUp: {},
    empDetermination: {
      determinationYN: '',
    },
  }),
  mutations: {
    SET_USER_ROLE: (state, payload) => (state.uRole = payload),
    SET_CLIENT_ID: (state, payload) => (state.id = payload),
    SET_CARE_NEEDS: (state, payload) => (state.careNeeds = payload),
    SET_DEMOGRAPHICS: (state, payload) => (state.demographics = payload),
    SET_BRIEF_HISTORY: (state, payload) => (state.briefHistory = payload),
    SET_AODL: (state, payload) => (state.actOfDailyLiving = payload),
    SET_IAODL: (state, payload) => (state.inActOfDailyLiving = payload),
    SET_PREFERRED_ACT: (state, payload) => (state.preferredAct = payload),
    SET_MEALS: (state, payload) => (state.meals = payload),
    SET_DRESSINGS: (state, payload) => (state.dressings = payload),
    SET_BATHING: (state, payload) => (state.bathing = payload),
    SET_LAUNDRY: (state, payload) => (state.laundry = payload),
    SET_HOUSEKEEPING: (state, payload) => (state.housekeeping = payload),
    SET_SLEEP_HABITS: (state, payload) => (state.sleepHabits = payload),
    SET_FUTURE_PLANS_TO_GOALS: (state, payload) => (state.futurePlansToGoals = payload),
    SET_GREATEST_FEARS: (state, payload) => (state.greatestFears = payload),
    SET_MED_CONDITIONS: (state, payload) => (state.medConditions = payload),
    SET_MENTAL_BEHAVIOR_CONDITIONS: (state, payload) => (state.mentalbehaviorConditions = payload),
    SET_ALLERGIES: (state, payload) => (state.allergies = payload),
    SET_ELIMINATION: (state, payload) => (state.elimination = payload),
    SET_AMBULATION: (state, payload) => (state.ambulation = payload),
    SET_PRESSCRIPT_MED: (state, payload) => (state.presscriptMed = payload),
    SET_OTC_MED_OTHERS: (state, payload) => (state.otcMedOthers = payload),
    SET_SUPPLEMENTS: (state, payload) => (state.supplements = payload),
    SET_SPECIAL_MED_INSTRUC: (state, payload) => (state.specialMedInstruc = payload),
    SET_PHARMACY: (state, payload) => (state.pharmacy = payload),
    SET_PHYSICIAN: (state, payload) => (state.physician = payload),
    SET_EYEGLASSES: (state, payload) => (state.eyeglasses = payload),
    SET_DENTAL: (state, payload) => (state.dental = payload),
    SET_LEGAL_DOC: (state, payload) => (state.legalDoc = payload),
    SET_LONG_TERM_CARE_COVERAGE: (state, payload) => (state.longTermCareCoverage = payload),
    SET_DETERMINATION: (state, payload) => (state.determination = payload),

    SET_CARE_NEEDS_BCKUP: (state, payload) => (state.careNeedsBckUp = payload),
    SET_DEMOGRAPHICS_BCKUP: (state, payload) => (state.demographicsBckUp = payload),
    SET_BRIEF_HISTORY_BCKUP: (state, payload) => (state.briefHistoryBckUp = payload),
    SET_AODL_BCKUP: (state, payload) => (state.actOfDailyLivingBckUp = payload),
    SET_IAODL_BCKUP: (state, payload) => (state.inActOfDailyLivingBckUp = payload),
    SET_PREFERRED_ACT_BCKUP: (state, payload) => (state.preferredActBckUp = payload),
    SET_MEALS_BCKUP: (state, payload) => (state.mealsBckUp = payload),
    SET_DRESSINGS_BCKUP: (state, payload) => (state.dressingsBckUp = payload),
    SET_BATHING_BCKUP: (state, payload) => (state.bathingBckUp = payload),
    SET_LAUNDRY_BCKUP: (state, payload) => (state.laundryBckUp = payload),
    SET_HOUSEKEEPING_BCKUP: (state, payload) => (state.housekeepingBckUp = payload),
    SET_SLEEP_HABITS_BCKUP: (state, payload) => (state.sleepHabitsBckUp = payload),
    SET_FUTURE_PLANS_TO_GOALS_BCKUP: (state, payload) => (state.futurePlansToGoalsBckUp = payload),
    SET_GREATEST_FEARS_BCKUP: (state, payload) => (state.greatestFearsBckUp = payload),
    SET_MED_CONDITIONS_BCKUP: (state, payload) => (state.medConditionsBckUp = payload),
    SET_MENTAL_BEHAVIOR_CONDITIONS_BCKUP: (state, payload) => (state.mentalbehaviorConditionsBckUp = payload),
    SET_ALLERGIES_BCKUP: (state, payload) => (state.allergiesBckUp = payload),
    SET_ELIMINATION_BCKUP: (state, payload) => (state.eliminationBckUp = payload),
    SET_AMBULATION_BCKUP: (state, payload) => (state.ambulationBckUp = payload),
    SET_PRESSCRIPT_MED_BCKUP: (state, payload) => (state.presscriptMedBckUp = payload),
    SET_OTC_MED_OTHERS_BCKUP: (state, payload) => (state.otcMedOthersBckUp = payload),
    SET_SUPPLEMENTS_BCKUP: (state, payload) => (state.supplementsBckUp = payload),
    SET_SPECIAL_MED_INSTRUC_BCKUP: (state, payload) => (state.specialMedInstrucBckUp = payload),
    SET_PHARMACY_BCKUP: (state, payload) => (state.pharmacyBckUp = payload),
    SET_PHYSICIAN_BCKUP: (state, payload) => (state.physicianBckUp = payload),
    SET_EYEGLASSES_BCKUP: (state, payload) => (state.eyeglassesBckUp = payload),
    SET_DENTAL_BCKUP: (state, payload) => (state.dentalBckUp = payload),
    SET_LEGAL_DOC_BCKUP: (state, payload) => (state.legalDocBckUp = payload),
    SET_LONG_TERM_CARE_COVERAGE_BCKUP: (state, payload) => (state.longTermCareCoverageBckUp = payload),
    SET_DETERMINATION_BCKUP: (state, payload) => (state.determinationBckUp = payload),

    GO_TO_NEXT_TAB: (state, payload) => {
      state.tabIndex = payload
    },
  },
  getters: {
    getPreferredAct: state => state.preferredAct.pets,
    getPresscriptMed: state => state.presscriptMed.presscriptMedData,
    getOTCMedOthersData: state => state.otcMedOthers.otcMedOthersData,
  },
  actions: {
    getTabDATA: async (ctx, clientId) => {
      const uData = JSON.parse(localStorage.getItem('userData'))
      const clientID = { clientId, role: uData.role }
      const uDATA = uData.role === 'admin' ? clientID : uData
      // console.log('uDATA this ', uDATA.clientId)
      const accessToken = localStorage.getItem('accessToken')
      try {
        const response = await axios.post(`${Vue.prototype.$apiUrl2}ClientAssessmentCare/fetchData`, {
          param: {
            userData: uDATA,
            // tab_name: pageTitle,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-type': 'application/json',
          },
        })
        return response
      } catch (error) {
        return error
      }
    },
    fetchFilteredData(state, payload) {
      // const uData = JSON.parse(localStorage.getItem('userData'))
      /* 1st tab */
      const isCareNeeds = payload.filter(f => f.tab_name === 'Care Needs')
      // eslint-disable-next-line no-unused-expressions
      isCareNeeds.length !== 0 ? state.dispatch('filterTab1', isCareNeeds) : state.commit('SET_CARE_NEEDS', state.state.empCareNeeds)
      // console.log('isCareNeeds', state.state.empCareNeeds)

      /* 2nd tab */
      const isDemographics = payload.filter(f => f.tab_name === 'Demographics')
      // eslint-disable-next-line no-unused-expressions
      isDemographics.length !== 0 ? state.dispatch('filterTab2', isDemographics) : state.commit('SET_DEMOGRAPHICS', {})
      // console.log('isDemographics', isDemographics)

      // /* 3rd tab */
      const isBriefHistory = payload.filter(f => f.tab_name === 'Brief History')
      // eslint-disable-next-line no-unused-expressions
      isBriefHistory.length !== 0 ? state.dispatch('filterTab3', isBriefHistory) : state.commit('SET_BRIEF_HISTORY', {})
      // console.log('isBriefHistory', isBriefHistory)

      // /* 4th tab */
      const isActivitiesDailyLiving = payload.filter(f => f.tab_name === 'Activities of Daily Living')
      // eslint-disable-next-line no-unused-expressions
      isActivitiesDailyLiving.length !== 0 ? state.dispatch('filterTab4', isActivitiesDailyLiving) : state.commit('SET_AODL', state.state.withValActOfDailyLiving)
      // isActivitiesDailyLiving.length !== 0 ? state.dispatch('filterTab4', isActivitiesDailyLiving) : state.commit('SET_AODL', uData.role === 'admin' ? state.state.empActOfDailyLiving : state.state.withValActOfDailyLiving)
      // console.log('isActivitiesDailyLiving', isActivitiesDailyLiving)

      // /* 5th tab */
      const isIADLS = payload.filter(f => f.tab_name === 'Instrumental Activities of Daily Living (IADLS)')
      // eslint-disable-next-line no-unused-expressions
      isIADLS.length !== 0 ? state.dispatch('filterTab5', isIADLS) : state.commit('SET_IAODL', state.state.withValInActOfDailyLiving)
      // isIADLS.length !== 0 ? state.dispatch('filterTab5', isIADLS) : state.commit('SET_IAODL', uData.role === 'admin' ? state.state.empInActOfDailyLiving : state.state.withValInActOfDailyLiving)
      // isIADLS.length !== 0 ? state.dispatch('filterTab5', isIADLS) : state.commit('SET_IAODL', state.state.empInActOfDailyLiving)
      // console.log('isIADLS', uData.role)

      // /* 6th tab */
      const isPreferredActivities = payload.filter(f => f.tab_name === 'Preferred Activities')
      // eslint-disable-next-line no-unused-expressions
      isPreferredActivities.length !== 0 ? state.dispatch('filterTab6', isPreferredActivities) : state.commit('SET_PREFERRED_ACT', state.state.empPreferredAct)
      // console.log('isPreferredActivities', isPreferredActivities)

      // /* 7th tab */
      const isMeals = payload.filter(f => f.tab_name === 'Meals')
      // eslint-disable-next-line no-unused-expressions
      isMeals.length !== 0 ? state.dispatch('filterTab7', isMeals) : state.commit('SET_MEALS', state.state.empMeals)
      // console.log('isMeals', isMeals)

      // /* 8th tab */
      const isDressings = payload.filter(f => f.tab_name === 'Dressings')
      // eslint-disable-next-line no-unused-expressions
      isDressings.length !== 0 ? state.dispatch('filterTab8', isDressings) : state.commit('SET_DRESSINGS', {})

      // /* 9th tab */
      const isBathing = payload.filter(f => f.tab_name === 'Bathing')
      // eslint-disable-next-line no-unused-expressions
      isBathing.length !== 0 ? state.dispatch('filterTab9', isBathing) : state.commit('SET_BATHING', state.state.empBathing)

      // /* 10th tab */
      const isLaundry = payload.filter(f => f.tab_name === 'Laundry')
      // eslint-disable-next-line no-unused-expressions
      isLaundry.length !== 0 ? state.dispatch('filterTab10', isLaundry) : state.commit('SET_LAUNDRY', {})

      // /* 11th tab */
      const isHousekeeping = payload.filter(f => f.tab_name === 'Housekeeping')
      // eslint-disable-next-line no-unused-expressions
      isHousekeeping.length !== 0 ? state.dispatch('filterTab11', isHousekeeping) : state.commit('SET_HOUSEKEEPING', {})

      // /* 12th tab */
      const isSleepHabits = payload.filter(f => f.tab_name === 'Sleep Habits')
      // eslint-disable-next-line no-unused-expressions
      isSleepHabits.length !== 0 ? state.dispatch('filterTab12', isSleepHabits) : state.commit('SET_SLEEP_HABITS', state.state.empSleepHabits)

      // /* 13th tab */
      const isFutureGoals = payload.filter(f => f.tab_name === 'Future Plans to Goals')
      // eslint-disable-next-line no-unused-expressions
      isFutureGoals.length !== 0 ? state.dispatch('filterTab13', isFutureGoals) : state.commit('SET_FUTURE_PLANS_TO_GOALS', {})

      // /* 14th tab */
      const isGreatestFears = payload.filter(f => f.tab_name === 'Greatest Fears')
      // eslint-disable-next-line no-unused-expressions
      isGreatestFears.length !== 0 ? state.dispatch('filterTab14', isGreatestFears) : state.commit('SET_GREATEST_FEARS', {})

      // /* 15th tab */
      const isMedConditions = payload.filter(f => f.tab_name === 'Medical Conditions')
      // eslint-disable-next-line no-unused-expressions
      isMedConditions.length !== 0 ? state.dispatch('filterTab15', isMedConditions) : state.commit('SET_MED_CONDITIONS', {})

      // /* 16th tab */
      const isMentalBehaviorConditions = payload.filter(f => f.tab_name === 'Mental/Behavior Conditions')
      // eslint-disable-next-line no-unused-expressions
      isMentalBehaviorConditions.length !== 0 ? state.dispatch('filterTab16', isMentalBehaviorConditions) : state.commit('SET_MENTAL_BEHAVIOR_CONDITIONS', {})
      // console.log('isMentalBehaviorConditions', isMentalBehaviorConditions)

      // /* 17th tab */
      const isAllergies = payload.filter(f => f.tab_name === 'Allergies')
      // eslint-disable-next-line no-unused-expressions
      isAllergies.length !== 0 ? state.dispatch('filterTab17', isAllergies) : state.commit('SET_ALLERGIES', {})
      // console.log('isAllergies', isAllergies)

      // /* 18th tab */
      const isElimination = payload.filter(f => f.tab_name === 'Elimination')
      // eslint-disable-next-line no-unused-expressions
      isElimination.length !== 0 ? state.dispatch('filterTab18', isElimination) : state.commit('SET_ELIMINATION', {})
      // console.log('isElimination', isElimination)

      // /* 19th tab */
      const isAmbulation = payload.filter(f => f.tab_name === 'Ambulation')
      // eslint-disable-next-line no-unused-expressions
      isAmbulation.length !== 0 ? state.dispatch('filterTab19', isAmbulation) : state.commit('SET_AMBULATION', {})
      // isAmbulation.length !== 0 ? state.dispatch('filterTab19', isAmbulation) : state.commit('SET_AMBULATION', uData.role === 'admin' ? {} : state.state.ambulation)
      // console.log('isAmbulation', isAmbulation)

      // /* 20th tab */
      const isPresscriptMed = payload.filter(f => f.tab_name === 'Prescription Medication')
      // eslint-disable-next-line no-unused-expressions
      isPresscriptMed.length !== 0 ? state.dispatch('filterTab20', isPresscriptMed) : state.commit('SET_PRESSCRIPT_MED', state.state.empPresscriptMed)
      // console.log('isPresscriptMed', isPresscriptMed)

      // /* 21st tab */
      const isOTCMedOthers = payload.filter(f => f.tab_name === 'OTC Medications/Others')
      // eslint-disable-next-line no-unused-expressions
      isOTCMedOthers.length !== 0 ? state.dispatch('filterTab21', isOTCMedOthers) : state.commit('SET_OTC_MED_OTHERS', state.state.empOTC)
      // console.log('isOTCMedOthers', isOTCMedOthers)

      // /* 22nd tab */
      const isSupplements = payload.filter(f => f.tab_name === 'Supplements')
      // eslint-disable-next-line no-unused-expressions
      isSupplements.length !== 0 ? state.dispatch('filterTab22', isSupplements) : state.commit('SET_SUPPLEMENTS', {})
      // console.log('isSupplements', isSupplements)

      // /* 23rd tab */
      const isSpecialMedInstruc = payload.filter(f => f.tab_name === 'Special Medication Instructions')
      // eslint-disable-next-line no-unused-expressions
      isSpecialMedInstruc.length !== 0 ? state.dispatch('filterTab23', isSpecialMedInstruc) : state.commit('SET_SPECIAL_MED_INSTRUC', {})
      // console.log('isSpecialMedInstruc', isSpecialMedInstruc)

      // /* 24th tab */
      const isPharmacy = payload.filter(f => f.tab_name === 'Pharmacy')
      // eslint-disable-next-line no-unused-expressions
      isPharmacy.length !== 0 ? state.dispatch('filterTab24', isPharmacy) : state.commit('SET_PHARMACY', {})
      // console.log('isPharmacy', isPharmacy)

      // /* 25th tab */
      const isPhysician = payload.filter(f => f.tab_name === 'Physician')
      // eslint-disable-next-line no-unused-expressions
      isPhysician.length !== 0 ? state.dispatch('filterTab25', isPhysician) : state.commit('SET_PHYSICIAN', {})
      // console.log('isPhysician', isPhysician)

      // /* 26th tab */
      const isEyeglasses = payload.filter(f => f.tab_name === 'Eyeglasses')
      // eslint-disable-next-line no-unused-expressions
      isEyeglasses.length !== 0 ? state.dispatch('filterTab26', isEyeglasses) : state.commit('SET_EYEGLASSES', {})
      // console.log('isEyeglasses', isEyeglasses)

      // /* 27th tab */
      const isDental = payload.filter(f => f.tab_name === 'Dental')
      // eslint-disable-next-line no-unused-expressions
      isDental.length !== 0 ? state.dispatch('filterTab27', isDental) : state.commit('SET_DENTAL', {})
      // console.log('isDental', isDental)

      // /* 28th tab */
      const isLegalDoc = payload.filter(f => f.tab_name === 'Legal Documentation')
      // eslint-disable-next-line no-unused-expressions
      isLegalDoc.length !== 0 ? state.dispatch('filterTab28', isLegalDoc) : state.commit('SET_LEGAL_DOC', {})
      // console.log('isLegalDoc', isLegalDoc)

      // /* 29th tab */
      const isLongTermCareCoverage = payload.filter(f => f.tab_name === 'Long-Term Care Coverage')
      // eslint-disable-next-line no-unused-expressions
      isLongTermCareCoverage.length !== 0 ? state.dispatch('filterTab29', isLongTermCareCoverage) : state.commit('SET_LONG_TERM_CARE_COVERAGE', {})

      // /* 30th tab */
      const isDetermination = payload.filter(f => f.tab_name === 'Determination')
      // eslint-disable-next-line no-unused-expressions
      isDetermination.length !== 0 ? state.dispatch('filterTab30', isDetermination) : state.commit('SET_DETERMINATION', {})
      // console.log('isDetermination', isDetermination)
    },

    fetchFilteredDataBckUp(state, payload) {
      /* 1st tab */
      const isCareNeeds = payload.filter(f => f.tab_name === 'Care Needs')
      // eslint-disable-next-line no-unused-expressions
      isCareNeeds.length !== 0 ? state.commit('SET_CARE_NEEDS_BCKUP', isCareNeeds) : state.commit('SET_CARE_NEEDS_BCKUP', '')
      // console.log('isCareNeeds', isCareNeeds.length !== 0 ? 'has value' : false)

      /* 2nd tab */
      const isDemographics = payload.filter(f => f.tab_name === 'Demographics')
      // eslint-disable-next-line no-unused-expressions
      isDemographics.length !== 0 ? state.commit('SET_DEMOGRAPHICS_BCKUP', isDemographics) : state.commit('SET_DEMOGRAPHICS_BCKUP', '')
      // console.log('isDemographics', isDemographics.length !== 0 ? isDemographics : false)

      // /* 3rd tab */
      const isBriefHistory = payload.filter(f => f.tab_name === 'Brief History')
      // eslint-disable-next-line no-unused-expressions
      isBriefHistory.length !== 0 ? state.commit('SET_BRIEF_HISTORY_BCKUP', isBriefHistory) : state.commit('SET_BRIEF_HISTORY_BCKUP', '')

      // /* 4th tab */
      const isActivitiesDailyLiving = payload.filter(f => f.tab_name === 'Activities of Daily Living')
      // eslint-disable-next-line no-unused-expressions
      isActivitiesDailyLiving.length !== 0 ? state.commit('SET_AODL_BCKUP', isActivitiesDailyLiving) : state.commit('SET_AODL_BCKUP', '')

      // /* 5th tab */
      const isIADLS = payload.filter(f => f.tab_name === 'Instrumental Activities of Daily Living (IADLS)')
      // eslint-disable-next-line no-unused-expressions
      isIADLS.length !== 0 ? state.commit('SET_IAODL_BCKUP', isIADLS) : state.commit('SET_IAODL_BCKUP', '')

      // /* 6th tab */
      const isPreferredActivities = payload.filter(f => f.tab_name === 'Preferred Activities')
      // eslint-disable-next-line no-unused-expressions
      isPreferredActivities.length !== 0 ? state.commit('SET_PREFERRED_ACT_BCKUP', isPreferredActivities) : state.commit('SET_PREFERRED_ACT_BCKUP', '')
      // console.log('isPreferredActivities', isPreferredActivities)

      // /* 7th tab */
      const isMeals = payload.filter(f => f.tab_name === 'Meals')
      // eslint-disable-next-line no-unused-expressions
      isMeals.length !== 0 ? state.commit('SET_MEALS_BCKUP', isMeals) : state.commit('SET_MEALS_BCKUP', '')

      // /* 8th tab */
      const isDressings = payload.filter(f => f.tab_name === 'Dressings')
      // eslint-disable-next-line no-unused-expressions
      isDressings.length !== 0 ? state.commit('SET_DRESSINGS_BCKUP', isDressings) : state.commit('SET_DRESSINGS_BCKUP', '')

      // /* 9th tab */
      const isBathing = payload.filter(f => f.tab_name === 'Bathing')
      // eslint-disable-next-line no-unused-expressions
      isBathing.length !== 0 ? state.commit('SET_BATHING_BCKUP', isBathing) : state.commit('SET_BATHING_BCKUP', '')

      // /* 10th tab */
      const isLaundry = payload.filter(f => f.tab_name === 'Laundry')
      // eslint-disable-next-line no-unused-expressions
      isLaundry.length !== 0 ? state.commit('SET_LAUNDRY_BCKUP', isLaundry) : state.commit('SET_LAUNDRY_BCKUP', '')

      // /* 11th tab */
      const isHousekeeping = payload.filter(f => f.tab_name === 'Housekeeping')
      // eslint-disable-next-line no-unused-expressions
      isHousekeeping.length !== 0 ? state.commit('SET_HOUSEKEEPING_BCKUP', isHousekeeping) : state.commit('SET_HOUSEKEEPING_BCKUP', '')

      // /* 12th tab */
      const isSleepHabits = payload.filter(f => f.tab_name === 'Sleep Habits')
      // eslint-disable-next-line no-unused-expressions
      isSleepHabits.length !== 0 ? state.commit('SET_SLEEP_HABITS_BCKUP', isSleepHabits) : state.commit('SET_SLEEP_HABITS_BCKUP', '')

      // /* 13th tab */
      const isFutureGoals = payload.filter(f => f.tab_name === 'Future Plans to Goals')
      // eslint-disable-next-line no-unused-expressions
      isFutureGoals.length !== 0 ? state.commit('SET_FUTURE_PLANS_TO_GOALS_BCKUP', isFutureGoals) : state.commit('SET_FUTURE_PLANS_TO_GOALS_BCKUP', '')

      // /* 14th tab */
      const isGreatestFears = payload.filter(f => f.tab_name === 'Greatest Fears')
      // eslint-disable-next-line no-unused-expressions
      isGreatestFears.length !== 0 ? state.commit('SET_GREATEST_FEARS_BCKUP', isGreatestFears) : state.commit('SET_GREATEST_FEARS_BCKUP', '')

      // /* 15th tab */
      const isMedConditions = payload.filter(f => f.tab_name === 'Medical Conditions')
      // eslint-disable-next-line no-unused-expressions
      isMedConditions.length !== 0 ? state.commit('SET_MED_CONDITIONS_BCKUP', isMedConditions) : state.commit('SET_MED_CONDITIONS_BCKUP', '')

      // /* 16th tab */
      const isMentalBehaviorConditions = payload.filter(f => f.tab_name === 'Mental/Behavior Conditions')
      // eslint-disable-next-line no-unused-expressions
      isMentalBehaviorConditions.length !== 0 ? state.commit('SET_MENTAL_BEHAVIOR_CONDITIONS_BCKUP', isMentalBehaviorConditions) : state.commit('SET_MENTAL_BEHAVIOR_CONDITIONS_BCKUP', '')

      // /* 17th tab */
      const isAllergies = payload.filter(f => f.tab_name === 'Allergies')
      // eslint-disable-next-line no-unused-expressions
      isAllergies.length !== 0 ? state.commit('SET_ALLERGIES_BCKUP', isAllergies) : state.commit('SET_ALLERGIES_BCKUP', '')

      // /* 18th tab */
      const isElimination = payload.filter(f => f.tab_name === 'Elimination')
      // eslint-disable-next-line no-unused-expressions
      isElimination.length !== 0 ? state.commit('SET_ELIMINATION_BCKUP', isElimination) : state.commit('SET_ELIMINATION_BCKUP', '')

      // /* 19th tab */
      const isAmbulation = payload.filter(f => f.tab_name === 'Ambulation')
      // eslint-disable-next-line no-unused-expressions
      isAmbulation.length !== 0 ? state.commit('SET_AMBULATION_BCKUP', isAmbulation) : state.commit('SET_AMBULATION_BCKUP', '')

      // /* 20th tab */
      const isPresscriptMed = payload.filter(f => f.tab_name === 'Prescription Medication')
      // eslint-disable-next-line no-unused-expressions
      isPresscriptMed.length !== 0 ? state.commit('SET_PRESSCRIPT_MED_BCKUP', isPresscriptMed) : state.commit('SET_PRESSCRIPT_MED_BCKUP', '')
      // console.log('filterTab20', isPresscriptMed)

      // /* 21st tab */
      const isOTCMedOthers = payload.filter(f => f.tab_name === 'OTC Medications/Others')
      // eslint-disable-next-line no-unused-expressions
      isOTCMedOthers.length !== 0 ? state.commit('SET_OTC_MED_OTHERS_BCKUP', isOTCMedOthers) : state.commit('SET_OTC_MED_OTHERS_BCKUP', '')

      // /* 22nd tab */
      const isSupplements = payload.filter(f => f.tab_name === 'Supplements')
      // eslint-disable-next-line no-unused-expressions
      isSupplements.length !== 0 ? state.commit('SET_SUPPLEMENTS_BCKUP', isSupplements) : state.commit('SET_SUPPLEMENTS_BCKUP', '')

      // /* 23rd tab */
      const isSpecialMedInstruc = payload.filter(f => f.tab_name === 'Special Medication Instructions')
      // eslint-disable-next-line no-unused-expressions
      isSpecialMedInstruc.length !== 0 ? state.commit('SET_SPECIAL_MED_INSTRUC_BCKUP', isSpecialMedInstruc) : state.commit('SET_SPECIAL_MED_INSTRUC_BCKUP', '')

      // /* 24th tab */
      const isPharmacy = payload.filter(f => f.tab_name === 'Pharmacy')
      // eslint-disable-next-line no-unused-expressions
      isPharmacy.length !== 0 ? state.commit('SET_PHARMACY_BCKUP', isPharmacy) : state.commit('SET_PHARMACY_BCKUP', '')

      // /* 25th tab */
      const isPhysician = payload.filter(f => f.tab_name === 'Physician')
      // eslint-disable-next-line no-unused-expressions
      isPhysician.length !== 0 ? state.commit('SET_PHYSICIAN_BCKUP', isPhysician) : state.commit('SET_PHYSICIAN_BCKUP', '')

      // /* 26th tab */
      const isEyeglasses = payload.filter(f => f.tab_name === 'Eyeglasses')
      // eslint-disable-next-line no-unused-expressions
      isEyeglasses.length !== 0 ? state.commit('SET_EYEGLASSES_BCKUP', isEyeglasses) : state.commit('SET_EYEGLASSES_BCKUP', '')

      // /* 27th tab */
      const isDental = payload.filter(f => f.tab_name === 'Dental')
      // eslint-disable-next-line no-unused-expressions
      isDental.length !== 0 ? state.commit('SET_DENTAL_BCKUP', isDental) : state.commit('SET_DENTAL_BCKUP', '')

      // /* 28th tab */
      const isLegalDoc = payload.filter(f => f.tab_name === 'Legal Documentation')
      // eslint-disable-next-line no-unused-expressions
      isLegalDoc.length !== 0 ? state.commit('SET_LEGAL_DOC_BCKUP', isLegalDoc) : state.commit('SET_LEGAL_DOC_BCKUP', '')

      // /* 29th tab */
      const isLongTermCareCoverage = payload.filter(f => f.tab_name === 'Long-Term Care Coverage')
      // eslint-disable-next-line no-unused-expressions
      isLongTermCareCoverage.length !== 0 ? state.commit('SET_LONG_TERM_CARE_COVERAGE_BCKUP', isLongTermCareCoverage) : state.commit('SET_LONG_TERM_CARE_COVERAGE_BCKUP', '')

      // /* 30th tab */
      const isDetermination = payload.filter(f => f.tab_name === 'Determination')
      // eslint-disable-next-line no-unused-expressions
      isDetermination.length !== 0 ? state.commit('SET_DETERMINATION_BCKUP', isDetermination) : state.commit('SET_DETERMINATION_BCKUP', '')
    },
    filterTab1(ctx, payload) {
      const tempData = []
      // const filteredShifts = payload.filter(f => (f.id === state.getters.getshiftID))
      // console.log('filteredShifts:', filteredShifts)
      payload.forEach(data => {
        // console.log('data.forms:', data.forms)
        const formData = data.forms
        // console.log('forms', formData)
        tempData
          .push({
            initial_contact: formData.initial_contact,
            start_date: formData.start_date,
            // care_goals: formData.care_goals,
            shift_type: formData.shift_type,
            shift_type_input: formData.shift_type_input,
            selected_days: formData.selected_days,
            sun_selected_hours: formData.sun_selected_hours.map(f => ({ ...f })),
            mon_selected_hours: formData.mon_selected_hours.map(f => ({ ...f })),
            tue_selected_hours: formData.tue_selected_hours.map(f => ({ ...f })),
            wed_selected_hours: formData.wed_selected_hours.map(f => ({ ...f })),
            th_selected_hours: formData.th_selected_hours.map(f => ({ ...f })),
            fri_selected_hours: formData.fri_selected_hours.map(f => ({ ...f })),
            sat_selected_hours: formData.sat_selected_hours.map(f => ({ ...f })),
          })
      })
      // console.log('SET_CARE_NEEDS', tempData[0])
      ctx.commit('SET_CARE_NEEDS', tempData[0])
      // ctx.commit('SET_CARE_NEEDS_BCKUP', tempData[0])
    },
    filterTab2(ctx, payload) {
      const tempData = []
      payload.forEach(data => {
        const formData = data.forms
        tempData
          .push({
            date_of_birth: formData.date_of_birth,
            weight: formData.weight,
            height: formData.height,
            marital_status: formData.marital_status,
            spouse_name: formData.spouse_name,
            live_with: formData.live_with,
            religion: formData.religion,
            attend_services: formData.attend_services,
            dnr: formData.dnr,
            languages: formData.languages,
          })
      })
      ctx.commit('SET_DEMOGRAPHICS', tempData[0])
    },
    filterTab3(ctx, payload) {
      const tempData = []
      payload.forEach(data => {
        const formData = data.forms
        tempData
          .push({
            current_residence: formData.current_residence,
            where_from: formData.where_from,
            children: formData.children !== '' ? formData.children : '0',
            other_sig_events: formData.other_sig_events,
            history_of_disabilities: formData.history_of_disabilities,
          })
      })
      ctx.commit('SET_BRIEF_HISTORY', tempData[0])
    },
    filterTab4(ctx, payload) {
      const tempData = []
      payload.forEach(data => {
        const formData = data.forms
        tempData
          .push({
            AT_assist_with_transfers_Yn: formData.AT_assist_with_transfers_Yn,
            AT_walk_with_client_Yn: formData.AT_walk_with_client_Yn,
            B_assist_in_out_shower_Yn: formData.B_assist_in_out_shower_Yn,
            B_bathe_client_Yn: formData.B_bathe_client_Yn,
            B_minor_bath_Yn: formData.B_minor_bath_Yn,
            B_shampoo_hair_Yn: formData.B_shampoo_hair_Yn,
            C_change_diapers_Yn: formData.C_change_diapers_Yn,
            DG_assist_dressing_Yn: formData.DG_assist_dressing_Yn,
            DG_brush_teeth_Yn: formData.DG_brush_teeth_Yn,
            DG_comb_dress_Yn: formData.DG_comb_dress_Yn,
            T_assist_toileting_Yn: formData.T_assist_toileting_Yn,
            T_clean_bathroom_Yn: formData.T_clean_bathroom_Yn,
            T_take_to_toilet_Yn: formData.T_take_to_toilet_Yn,
          })
      })
      ctx.commit('SET_AODL', tempData[0])
    },
    filterTab5(ctx, payload) {
      const tempData = []
      payload.forEach(data => {
        const formData = data.forms
        tempData
          .push({
            HW_bed_lines_bathroom_Yn: formData.HW_bed_lines_bathroom_Yn,
            HW_living_room_bed_Yn: formData.HW_living_room_bed_Yn,
            HW_sweeping_mopping_vacuuming_floor_Yn: formData.HW_sweeping_mopping_vacuuming_floor_Yn,
            L_Laundry_Yn: formData.L_Laundry_Yn,
            PM_clear_dishes_Yn: formData.PM_clear_dishes_Yn,
            PM_prep_meals_Yn: formData.PM_prep_meals_Yn,
            SHP_grocery_shop_Yn: formData.SHP_grocery_shop_Yn,
            SHP_pick_up_med_Yn: formData.SHP_pick_up_med_Yn,
            TR_dri_appointment_Yn: formData.TR_dri_appointment_Yn,
            TR_dri_errands_Yn: formData.TR_dri_errands_Yn,
          })
      })
      // console.log('SET_IAODL', tempData)
      ctx.commit('SET_IAODL', tempData[0])
    },
    filterTab6(ctx, payload) {
      const tempData = []
      const empPreferredAct = ctx.getters.getPreferredAct
      payload.forEach(data => {
        const formData = data.forms
        tempData
          .push({
            fav_act_can_do: formData.fav_act_can_do,
            fav_act_cant_do: formData.fav_act_cant_do,
            interest_hob: formData.interest_hob,
            entertainment_pref: formData.entertainment_pref,
            club_org: formData.club_org,
            HW_sweeping_mopping_vacuuming_floor_Yn: formData.HW_sweeping_mopping_vacuuming_floor_Yn,
            club_org_day_set: formData.club_org_day_set,
            club_org_time_set: formData.club_org_time_set,
            church: formData.church,
            church_day_set: formData.church_day_set,
            church_time_set: formData.church_time_set,
            reg_app_social_eng: formData.reg_app_social_eng,
            other_social_act: formData.other_social_act,
            fam_in_area_Yn: formData.fam_in_area_Yn,
            fam_in_area_visit_how_often: formData.fam_in_area_visit_how_often,
            SHP_grocery_shop_Yn: formData.SHP_grocery_shop_Yn,
            reg_friends_and_visitors_Yn: formData.reg_friends_and_visitors_Yn,
            reg_friends_and_visitor_visit_how_often: formData.reg_friends_and_visitor_visit_how_often,
            pets: formData.pets.length > 0 ? formData.pets.map(f => ({ ...f })) : empPreferredAct,
          })
        // console.log('formData.pets.type', formData.pets)
      })
      // console.log('SET_PREFERRED_ACT', tempData)
      ctx.commit('SET_PREFERRED_ACT', tempData[0])
    },
    filterTab7(ctx, payload) {
      const tempData = []
      payload.forEach(data => {
        const formData = data.forms
        tempData
          .push({
            dietary_concers: formData.dietary_concers,
            typically_eats: formData.typically_eats,
            typically_eats_breakfast: formData.typically_eats_breakfast,
            typically_eats_lunch: formData.typically_eats_lunch,
            typically_eats_dinner: formData.typically_eats_dinner,
            typically_eats_snacks: formData.typically_eats_snacks,
            person_cooks: formData.person_cooks,
            other_food_provider: formData.other_food_provider,
            fav_food: formData.fav_food,
          })
      })
      // console.log('SET_MEALS', tempData[0])
      ctx.commit('SET_MEALS', tempData[0])
    },
    filterTab8(ctx, payload) {
      const tempData = []
      payload.forEach(data => {
        const formData = data.forms
        tempData
          .push({
            dressings: formData.dressings,
          })
      })
      // console.log('SET_DRESSINGS', tempData[0])
      ctx.commit('SET_DRESSINGS', tempData[0])
    },
    filterTab9(ctx, payload) {
      const tempData = []
      payload.forEach(data => {
        const formData = data.forms
        tempData
          .push({
            bathing_opts: formData.bathing_opts,
            no_assistance_necessary: formData.no_assistance_necessary,
            family_will_bathe: formData.family_will_bathe,
            bathing_assistance: formData.bathing_assistance,
            monitoring_only: formData.monitoring_only,
            compliant: formData.compliant,
            non_compliant: formData.non_compliant,
          })
      })
      // console.log('SET_BATHING', tempData[0])
      ctx.commit('SET_BATHING', tempData[0])
    },
    filterTab10(ctx, payload) {
      const tempData = []
      payload.forEach(data => {
        const formData = data.forms
        tempData
          .push({
            who_does: formData.who_does,
            how_often: formData.how_often,
          })
      })
      // console.log('SET_LAUNDRY', tempData[0])
      ctx.commit('SET_LAUNDRY', tempData[0])
    },
    filterTab11(ctx, payload) {
      const tempData = []
      payload.forEach(data => {
        const formData = data.forms
        tempData
          .push({
            who_does: formData.who_does,
            how_often: formData.how_often,
            areas: formData.areas,
          })
      })
      // console.log('SET_HOUSEKEEPING', tempData[0])
      ctx.commit('SET_HOUSEKEEPING', tempData[0])
    },
    filterTab12(ctx, payload) {
      const tempData = []
      payload.forEach(data => {
        const formData = data.forms
        tempData
          .push({
            bedtime: formData.bedtime,
            arises: formData.arises,
            noc_day_opts: formData.noc_day_opts,
            nocturnal: formData.nocturnal,
            daytime: formData.daytime,
          })
      })
      // console.log('SET_SLEEP_HABITS', tempData[0])
      ctx.commit('SET_SLEEP_HABITS', tempData[0])
    },
    filterTab13(ctx, payload) {
      const tempData = []
      payload.forEach(data => {
        const formData = data.forms
        tempData
          .push({
            future_plans_to_goals: formData.future_plans_to_goals,
          })
      })
      // console.log('SET_FUTURE_PLANS_TO_GOALS', tempData[0])
      ctx.commit('SET_FUTURE_PLANS_TO_GOALS', tempData[0])
    },
    filterTab14(ctx, payload) {
      const tempData = []
      payload.forEach(data => {
        const formData = data.forms
        tempData
          .push({
            greatest_fears: formData.greatest_fears,
          })
      })
      // console.log('SET_GREATEST_FEARS', tempData[0])
      ctx.commit('SET_GREATEST_FEARS', tempData[0])
    },
    filterTab15(ctx, payload) {
      const tempData = []
      payload.forEach(data => {
        const formData = data.forms
        tempData
          .push({
            conditions: formData.conditions,
            hearing: formData.hearing,
            speech: formData.speech,
            vision: formData.vision,
            swallowing: formData.swallowing,
            other: formData.other,
          })
      })
      // console.log('SET_MED_CONDITIONS', tempData[0])
      ctx.commit('SET_MED_CONDITIONS', tempData[0])
    },
    filterTab16(ctx, payload) {
      const tempData = []
      payload.forEach(data => {
        const formData = data.forms
        tempData
          .push({
            diag_disorders_medications: formData.diag_disorders_medications,
            can_be_left_alone: formData.can_be_left_alone,
            wanderer: formData.wanderer,
            dementia: formData.dementia,
            symptoms: formData.symptoms,
            triggers: formData.triggers,
          })
      })
      // console.log('SET_MENTAL_BEHAVIOR_CONDITIONS', tempData[0])
      ctx.commit('SET_MENTAL_BEHAVIOR_CONDITIONS', tempData[0])
    },
    filterTab17(ctx, payload) {
      const tempData = []
      payload.forEach(data => {
        const formData = data.forms
        tempData
          .push({
            allergies: formData.allergies,
            notes: formData.notes,
          })
      })
      // console.log('SET_ALLERGIES', tempData)
      ctx.commit('SET_ALLERGIES', tempData[0])
    },
    filterTab18(ctx, payload) {
      const tempData = []
      payload.forEach(data => {
        const formData = data.forms
        tempData
          .push({
            incontinence: formData.incontinence,
            issues: formData.issues,
            wear_briefs: formData.wear_briefs,
            underpads: formData.underpads,
            notes: formData.notes,
          })
      })
      // console.log('SET_ELIMINATION', tempData)
      ctx.commit('SET_ELIMINATION', tempData[0])
    },
    filterTab19(ctx, payload) {
      const tempData = []
      payload.forEach(data => {
        const formData = data.forms
        tempData
          .push({
            aids: formData.aids,
            fall_risk: formData.fall_risk,
            use_of_arm_hands: formData.use_of_arm_hands,
            notes: formData.notes,
          })
      })
      ctx.commit('SET_AMBULATION', tempData[0])
    },
    filterTab20(ctx, payload) {
      const tempData = []
      const empPresscriptMed = ctx.getters.getPresscriptMed
      payload.forEach(data => {
        const formData = data.forms
        // console.log('SET_PRESSCRIPT_MED', formData.presscriptMed)
        tempData
          .push({
            presscriptMedData: formData.presscriptMedData.length > 0 ? formData.presscriptMedData.map(f => ({ ...f })) : empPresscriptMed,
          })
      })
      // console.log('SET_PRESSCRIPT_MED', tempData[0])
      ctx.commit('SET_PRESSCRIPT_MED', tempData[0])
    },
    filterTab21(ctx, payload) {
      const tempData = []
      const empOTCMedOthers = ctx.getters.getOTCMedOthersData
      payload.forEach(data => {
        const formData = data.forms
        tempData
          .push({
            otcMedOthersData: formData.otcMedOthersData.length > 0 ? formData.otcMedOthersData.map(f => ({ ...f })) : empOTCMedOthers,
          })
      })
      ctx.commit('SET_OTC_MED_OTHERS', tempData[0])
    },
    filterTab22(ctx, payload) {
      const tempData = []
      payload.forEach(data => {
        const formData = data.forms
        tempData
          .push({
            supplementData: formData.supplementData,
          })
      })
      ctx.commit('SET_SUPPLEMENTS', tempData[0])
    },
    filterTab23(ctx, payload) {
      const tempData = []
      payload.forEach(data => {
        const formData = data.forms
        tempData
          .push({
            specialMedInstrucData: formData.specialMedInstrucData,
          })
      })
      ctx.commit('SET_SPECIAL_MED_INSTRUC', tempData[0])
    },
    filterTab24(ctx, payload) {
      const tempData = []
      payload.forEach(data => {
        const formData = data.forms
        tempData
          .push({
            location: formData.location,
            phone: formData.phone,
          })
      })
      ctx.commit('SET_PHARMACY', tempData[0])
    },
    filterTab25(ctx, payload) {
      const tempData = []
      payload.forEach(data => {
        const formData = data.forms
        tempData
          .push({
            primary_physician_name: formData.primary_physician_name,
            primary_physician_address: formData.primary_physician_address,
            primary_physician_phone: formData.primary_physician_phone,
            primary_physician_nurse: formData.primary_physician_nurse,
            other_physician_name: formData.other_physician_name,
            other_physician_address: formData.other_physician_address,
            other_physician_phone: formData.other_physician_phone,
            other_physician_nurse: formData.other_physician_nurse,
          })
      })
      ctx.commit('SET_PHYSICIAN', tempData[0])
    },
    filterTab26(ctx, payload) {
      const tempData = []
      payload.forEach(data => {
        const formData = data.forms
        tempData
          .push({
            eye_doc: formData.eye_doc,
            phone: formData.phone,
          })
      })
      ctx.commit('SET_EYEGLASSES', tempData[0])
    },
    filterTab27(ctx, payload) {
      const tempData = []
      payload.forEach(data => {
        const formData = data.forms
        tempData
          .push({
            dentist: formData.dentist,
            phone: formData.phone,
          })
      })
      ctx.commit('SET_DENTAL', tempData[0])
    },
    filterTab28(ctx, payload) {
      const tempData = []
      payload.forEach(data => {
        const formData = data.forms
        tempData
          .push({
            legal_doc: formData.legal_doc,
            do_not_resuscitate: formData.do_not_resuscitate,
            instructions: formData.instructions,
          })
      })
      ctx.commit('SET_LEGAL_DOC', tempData[0])
    },
    filterTab29(ctx, payload) {
      const tempData = []
      payload.forEach(data => {
        const formData = data.forms
        tempData
          .push({
            long_term_coverage: formData.long_term_coverage,
            company: formData.company,
            phone: formData.phone,
            policy: formData.policy,
            covered: formData.covered,
            not_covered: formData.not_covered,
          })
      })
      ctx.commit('SET_LONG_TERM_CARE_COVERAGE', tempData[0])
    },
    filterTab30(ctx, payload) {
      const tempData = []
      payload.forEach(data => {
        const formData = data.forms
        tempData
          .push({
            determinationYN: formData.determinationYN,
          })
      })
      ctx.commit('SET_DETERMINATION', tempData[0])
    },
    insertData(ctx, formData) {
      const accessToken = localStorage.getItem('accessToken')
      return new Promise((resolve, reject) => {
        axios
          .post(`${Vue.prototype.$apiUrl2}ClientAssessmentCare/action`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-type': 'multipart/form-data',
              },
            })
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
  },
}
